/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useState, useEffect } from 'react';
import { StoreContext } from '../../ mobx stores/RootStore';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
// import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import Spinner from '../ui/Spinner';
import ReCAPTCHA from 'react-google-recaptcha';
import 'react-phone-number-input/style.css';
import Icon from '@mdi/react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import { runInAction } from 'mobx';

const validEmailProviders = [
  'gmail.com',
  'yahoo.com',
  'yahoo.co.uk',
  'outlook.com',
  'hotmail.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'zoho.com',
  'yandex.com',
  'protonmail.com',
  'gmx.com',
  'mail.ru',
  'inbox.com',
  'me.com',
  'live.com',
  'msn.com',
  'ymail.com',
  'rocketmail.com',
  'att.net',
  'verizon.net',
  'comcast.net',
  'sbcglobal.net',
  'bellsouth.net',
  'charter.net',
  'cox.net',
  'earthlink.net',
  'juno.com',
  'frontier.com',
  'windstream.net',
  'netzero.net',
  'tradelenda.com',
  'adewunmi.com',
];

const SignupForm: FC = () => {
  const { authStore } = useContext(StoreContext);

  const { loading } = authStore;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password] = useState('');
  const [confirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
  });

  const validateEmailDomain = (email) => {
    const domain = email.split('@')[1];
    return validEmailProviders.includes(domain);
  };

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    const value =
      element.name === 'email'
        ? element.value.trim().toLowerCase()
        : element.value.trim();
    setUserDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        [element.name]: value,
        // element.name === 'email'
        //   ? element.value.trim().toLowerCase()
        //   : element.value.trim(),
      };
    });
    if (element.name === 'email') {
      const isValidDomain = validateEmailDomain(value);
      if (!isValidDomain) {
        setEmailError(
          'Please enter a valid email address from a supported provider.',
        );
      } else {
        setEmailError('');
      }
    }
  };

  useEffect(() => {
    if (phoneNumber === undefined) {
      setPhoneNumber('');
    } else {
      setPhoneNumber(phoneNumber);
    }
    setUserDetails((prevDetails: any) => {
      return { ...prevDetails, phoneNumber };
    });
  }, [phoneNumber]);

  const recaptchaRef: any = React.useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const recaptchaValue = recaptchaRef.current?.getValue();
  const handleCaptchachange = () => {
    return;
  };

  const navigate = useNavigate();

  const SignUp = (e: any) => {
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const recaptchaValue = recaptchaRef.current?.getValue();
    recaptchaRef.current.reset();
    if (!emailError && userDetails.email && password === confirmPassword) {
      authStore.CreateUser(userDetails);

      runInAction(() => {
        authStore.setSuccess = () => {
          navigate('/email_verification');
        };
      });

      runInAction(() => {
        authStore.setError = (error: any) => {
          if (
            error.message === 'Firebase: Error (auth/email-already-in-use).'
          ) {
            toast.error(
              'Email already in use. Please use a different email then try again!',
            );
          }
          if (
            error.message ===
            'Firebase: Password should be at least 6 characters (auth/weak-password).'
          ) {
            toast.error('Password should be at least 6 characters');
          }
          if (password !== confirmPassword) {
            toast.error('password does not match');
          }
          if (error.message === 'Firebase: Error (auth/invalid-email).') {
            toast.error(
              'Invalid email. Please use a valid email then try again!',
            );
          }
          if (error.message === 'Firebase: Error (auth/weak-password).') {
            toast.error('Weak password.');
          } else {
            toast.error(
              'We are unable to process your form submission at this time. Please try again later!',
            );
          }
        };
      });
    } else {
      if (emailError) {
        toast.error(emailError);
      } else if (password !== confirmPassword) {
        toast.error('Passwords do not match.');
      } else {
        toast.error('Please fill out all required fields correctly.');
      }
    }
  };

  return (
    <form onSubmit={SignUp} className="signUpForm">
      {loading && <Spinner />}
      {/* <Toaster
        position="top-center"
        reverseOrder={true}
        containerStyle={{
          top: 50,
        }}
        toastOptions={{
          duration: 5000,
          style: {
            gap: '10px',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== 'loading' && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{ width: '50px', background: 'white' }}
                  >
                    {icon}
                  </button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster> */}
      <div className="col-12 mb-2">
        <label htmlFor="firstName" className="col-12 mb-0.5 mt-1">
          First Name
        </label>
        <Input
          name="firstName"
          classes="form-control-sm col-12 signUpForm--inputField"
          type="text"
          id="firstName"
          value={userDetails.firstName}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="col-12 mb-2 sign-up-form--inputGroup">
        <label
          htmlFor="lastName"
          className="col-12 mb-1 mt-1 signUpForm--label"
        >
          Last Name
        </label>
        <Input
          name="lastName"
          classes="form-control-sm col-12  signUpForm--inputField"
          type="text"
          id="lastName"
          value={userDetails.lastName}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="col-12 mb-2 sign-up-form--inputGroup">
        <label
          htmlFor="email"
          className="col-12  mb-0.5 mt-1 signUpForm--label"
        >
          Email Address
        </label>
        <Input
          name="email"
          classes="form-control-sm col-12 signUpForm--inputField "
          type="email"
          id="email"
          value={userDetails.email.trim().toLowerCase()}
          onChange={handleInputChange}
          required
        />
        {emailError && <p className="error-message">{emailError}</p>}
      </div>

      <div className="col-12 mb-2 position-relative">
        <label htmlFor="password" className="col-12  mb-0.5 mt-1">
          Password
        </label>
        <Input
          name="password"
          classes="form-control-sm mb-2 col-12 "
          type={showPassword ? 'text' : 'password'}
          id="password"
          value={userDetails.password.trim()}
          onChange={handleInputChange}
          required
        />
        <span
          className="toggle-password"
          onClick={() => setShowPassword(!showPassword)}
        >
          <Icon path={showPassword ? mdiEyeOff : mdiEye} vertical size={1} />
        </span>
      </div>

      <div className="row mb-2 capt">
        <ReCAPTCHA
          sitekey={`${process.env.REACT_APP_CAPTCHA_SITE_KEY}`}
          className="captcha ms-0 mb-2 mt-0"
          size="normal"
          ref={recaptchaRef}
          onClick={() => handleCaptchachange}
        />
      </div>

      <div className=" d-flex form-check mb-3 my-2 ">
        <Input
          name="checkbox"
          classes="form-check-input "
          type="checkbox"
          value=""
          id="flexCheckDefault"
          required
        />
        <label className="form-check-label " htmlFor="flexCheckDefault">
          I agree with the
          <a
            href="https://docs.google.com/document/d/1Gg-uvbCqS0dPo2lZSh1zDnteQphVVAeR/edit?usp=sharing&ouid=106690636404743294862&rtpof=true&sd=true"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#054b99' }}
          >
            {' '}
            Terms and Conditions{' '}
          </a>
          of Trade Lenda
        </label>
      </div>

      <Button
        classes="primary-btn btn-lg mb-2"
        content="Sign Up"
        type="submit"
      />
    </form>
  );
};

export default observer(SignupForm);
