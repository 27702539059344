import { useState } from 'react';
import Header from './sections/Header';
import GetStarted from './sections/GetStarted';
import BlueCard from './sections/BlueCard';
import Footer from './sections/Footer';
import Newsletter from './sections/Newsletter';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import { Helmet } from 'react-helmet-async';
const Facilities = () => {
  const [murabahaReadMore, setMurabahaReadMore] = useState(false);
  const [musharakahReadMore, setMusharakahReadMore] = useState(false);
  const clickMurabahaReadMore = () => {
    setMurabahaReadMore((prev) => !prev);
  };
  const clickMusharakahReadMore = () => {
    setMusharakahReadMore((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>
          Islamic Facility Financing | Trade Lenda Facility Tailored for Your
          Needs!
        </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <section className="features landing-page-hero">
        <div className="container">
          <div>
            <div className="group">
              <div className="murabaha-div">
                <div className="col-lg-6 facilities-img-div">
                  <img
                    className="murabaha-img"
                    src={`${BaseDirectories.IMAGES_DIR}/murbaha_img.png`}
                    alt="Trade Lenda"
                    style={{
                      // height: 'auto',
                      // position: 'absolute',
                      top: '30',
                      // left: '6%',
                      // zIndex: '1',
                      maxHeight: '350px',
                      // maxWidth: '500px',
                      // width: '88%',
                      height: '90%',
                      // paddingBottom: '15px',
                      // borderRadius: '20px',
                    }}
                  />

                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/murabaha_background.svg`}
                    alt="Trade Lenda"
                    className="facilities-img2"
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: 'auto',
                      marginTop: '15%',
                      marginRight: '30%',
                      maxHeight: '350px',
                      maxWidth: '600px',
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="" style={{ maxWidth: '540px' }}>
                    <div className="">
                      <div className="facilities-text-align">
                        <h1 className="mb-3">Murabaha</h1>
                        <p>
                          <span style={{ fontWeight: '700px' }}>Murabaha</span>{' '}
                          also referred to as cost-plus financing, is an Islamic
                          financing structure in which the seller and buyer
                          agrees to the cost and markup of an asset or
                          inventories. Tradelenda acts as a seller, whereby the
                          buyer of an asset or inventory pre-orders it. The
                          customer then pays the the cost plus mark-up in
                          accordance with the pre-established installments.
                        </p>
                        {murabahaReadMore && (
                          <>
                            <div>
                              <h3>How it works</h3>
                            </div>
                            <div className="mt-3">
                              <ul>
                                <li>
                                  <p>
                                    Trade Lenda purchases the asset or inventory
                                    at cost price from the supplier and makes
                                    payment.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    The supplier delivers the purchased asset or
                                    inventory to Trade Lenda.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Trade Lenda sells the asset or inventory to
                                    the customer at a markup (cost + profit)
                                    based on the signed contract.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    The customer pays back the Selling price to
                                    Trade Lenda at an agreed tenure using a
                                    predetermined payment plan.
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-lg btn-outline-primary mt-3"
                          style={{ width: '113px', marginRight: '20px' }}
                          onClick={clickMurabahaReadMore}
                        >
                          {murabahaReadMore ? 'less' : 'Read more'}
                        </button>
                        <Button
                          classes="primary-btn btn-lg mt-3"
                          content="Get Murabaha"
                          route="/dashboard/facility"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="mb-5 group">
              <div className=" pt-5 musharakah-div">
                <div className="col-lg-6 facilities-img-div">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/musharakah_card2.jpg`}
                    alt="Trade Lenda"
                    style={{
                      position: 'absolute',
                      top: '30',
                      left: '6%',
                      zIndex: '1',
                      maxHeight: '370px',
                      maxWidth: '500px',
                      width: '88%',
                      height: '85%',
                      borderRadius: '20px',
                    }}
                  />

                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/musharika_background.svg`}
                    alt="Trade Lenda"
                    style={{
                      position: 'relative',
                      // top: '10px',
                      // right: '25px',
                      width: '100%',
                      height: 'auto',
                      marginTop: '15%',
                      marginRight: '30%',
                      maxHeight: '350px',
                      maxWidth: '600px',
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="hero-text">
                    <div className="">
                      <div className=" facilities-text-align ">
                        <h1 className="mb-3">Musharakah</h1>
                        <p>
                          <span style={{ fontWeight: '700px' }}>
                            Musharakah
                          </span>{' '}
                          represents a collaborative partnership arrangement
                          between the customer and Trade Lenda, wherein Trade
                          Lenda engages in project financing, be it for new
                          initiatives or existing ventures. This collaboration
                          entails shared capital with 30% contribution by the
                          the client and 70% contribution by the financier. The
                          returns and associated risks is also borne
                          by both parties. Furthermore, Trade Lenda has the
                          option to participate in the ownership of designated
                          assets, either on a permanent or temporary basis, with
                          profit-sharing structured in accordance with a
                          mutually agreed upon agreement between Trade Lenda and
                          the customer.
                        </p>
                        {musharakahReadMore && (
                          <>
                            <div>
                              <h3>How it works</h3>
                            </div>
                            <div className="mt-3">
                              <ul>
                                <li>
                                  <p>
                                    The customer and Trade Lenda enter into a
                                    Musharakah contract and become partners in a
                                    Musharakah venture/enterprise.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Both parties contribute their capital to the
                                    Musharakah venture in an agreed ratio.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    The fund would be utilized for the specified
                                    project and business.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Profit is distributed among Trade Lenda and
                                    the customer according to the pre-agreed
                                    ratio. And if any loss occurs, both parties
                                    bear it according to their ratio of capital.
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-lg btn-outline-primary mt-3"
                          style={{ width: '113px', marginRight: '15px' }}
                          onClick={clickMusharakahReadMore}
                        >
                          {musharakahReadMore ? 'less' : 'Read more'}
                        </button>
                        <Button
                          classes="primary-btn btn-lg mt-3"
                          style={{ fontSize: 'small' }}
                          content="Get Musharakah"
                          route="/dashboard/facility"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlueCard />
      <GetStarted />
      <Newsletter />
      <Footer />
    </>
  );
};

export default Facilities;
