/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { FC, useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Input from '../ui/Input';
import { StoreContext } from '../../mobx_stores/RootStore';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import Spinner from '../ui/Spinner';
import Skeleton from 'react-loading-skeleton';
import Button from '../ui/Button';
import data from '../../pages/dashboard/invest/data.json';
import { useDispatch } from 'react-redux';
import { mdiAlert, mdiInformationOutline, mdiSignCaution } from '@mdi/js';
import Icon from '@mdi/react';
import { setLoanDetails } from '../../redux/loanDetail/loan.details.action';

type Props = {
  x: number;
  disabled: boolean;
  setDisabled: Function;
};

const SettingsArmDetailsForm: FC<Props> = ({ x, disabled, setDisabled }) => {
  const { loansStore } = useContext(StoreContext);

  const { loanUserdetails, loading, success, sending, message } = loansStore;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disabledObj, setDisabledObj] = useState<any>({});

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [armDbDetails, setArmDbDetails] = useState(
    toJS(loanUserdetails.armUserBankDetails),
  );

  //profile default state
  const [armDetails, setArmDetails] = useState<any>({
    annualExpectedAnnualIncomeRange: '',
    politicallyExposedPersons: '',
    politicallyExposedPersonsCategory: '',
    employmentStatus: '',
    idType: '',
    cardNumber: '',
    issueDateOfId: '',
    expiryDateOfId: '',
    utilityBillIdType: '',
    reInvestDividends: 'Yes',
    kycLevel: '',
  });

  useEffect(() => {
    loansStore.getLoanUserDetails();
    loansStore.loanUserdetails;
    dispatch(setLoanDetails(toJS(loansStore.loanUserdetails)));
  }, [success, message]);

  React.useEffect(() => {
    setArmDetails({
      annualExpectedAnnualIncomeRange:
        armDbDetails &&
        armDbDetails?.annualExpectedAnnualIncomeRange === undefined
          ? ''
          : armDbDetails?.annualExpectedAnnualIncomeRange,
      politicallyExposedPersons:
        armDbDetails && armDbDetails?.politicallyExposedPersons === undefined
          ? ''
          : armDbDetails?.politicallyExposedPersons,
      politicallyExposedPersonsCategory:
        armDbDetails &&
        armDbDetails?.politicallyExposedPersonsCategory === undefined
          ? ''
          : armDbDetails?.politicallyExposedPersonsCategory,
      employmentStatus:
        armDbDetails && armDbDetails?.employmentStatus === undefined
          ? ''
          : armDbDetails?.employmentStatus,
      idType:
        armDbDetails && armDbDetails?.idType === undefined
          ? ''
          : armDbDetails?.idType,
      cardNumber:
        armDbDetails && armDbDetails?.cardNumber === undefined
          ? ''
          : armDbDetails?.cardNumber,
      issueDateOfId:
        armDbDetails && armDbDetails?.issueDateOfId === undefined
          ? ''
          : armDbDetails?.issueDateOfId?.substr(0, 10),
      expiryDateOfId:
        armDbDetails && armDbDetails?.expiryDateOfId === undefined
          ? ''
          : armDbDetails?.expiryDateOfId?.substr(0, 10),
      utilityBillIdType:
        armDbDetails && armDbDetails?.utilityBillIdType === undefined
          ? ''
          : armDbDetails?.utilityBillIdType,
      reInvestDividends:
        armDbDetails && armDbDetails?.reInvestDividends === undefined
          ? 0
          : armDbDetails?.reInvestDividends,
      kycLevel:
        armDbDetails && armDbDetails?.kycLevel === undefined
          ? 0
          : armDbDetails?.kycLevel,
    });
  }, [loanUserdetails]);

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setArmDetails((prevDetails: any) => {
      if (element.type === 'number') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });
  };

  const handleUpdateArmdetails = () => {
    loansStore.updateArmDetails(armDetails);
  };

  useEffect(() => {
    if (success === 'Profile updated successfully' || success.length > 0) {
      setDisabled(true);
    }
  }, [success]);

  useEffect(() => {
    if (message.type === 'success') {
      setDisabled(true);
    }
  }, [message.type]);

  useEffect(() => {
    if (armDbDetails) {
      Object.keys(armDbDetails).forEach((biz) => {
        if (!disabled) {
          if (armDbDetails[biz] === '') {
            // disabledObj[biz] = false;
            setDisabledObj((prev: any) => {
              return { ...prev, [biz]: false };
            });
          } else {
            // disabledObj[biz] = true;
            setDisabledObj((prev: any) => {
              return { ...prev, [biz]: true };
            });
          }
        } else {
          setDisabledObj((prev: any) => {
            return { ...prev, [biz]: true };
          });
        }
      });
    }
  }, [armDbDetails, disabled]);

  return (
    <>
      {(sending || isLoading) && <Spinner />}
      {loading ? (
        <div className="account-settings-form">
          <motion.div
            initial={{ x: x }}
            transition={{ duration: 0.1 }}
            animate={{ x: 0 }}
            className=""
          >
            <div className="row px-md-5 px-4 justify-content-center">
              <div className="col-lg-11 col-xl-6 col-md-12 left-side pe-lg-3">
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
              </div>

              <div className="col-lg-11 col-xl-6 col-md-12 right-side ps-lg-3 ">
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      ) : (
        <div className="account-settings-form">
          <motion.div
            initial={{ x: x }}
            transition={{ duration: 0.1 }}
            animate={{ x: 0 }}
            className=""
          >
            <div className="row px-md-5 px-4 justify-content-center">
              {armDetails.kycLevel !== '' ? (
                <div className="col-md-12 mb-3">
                  <div
                    className="d-flex flex-wrap justify-content-center align-items-center gap-2 "
                    style={{
                      backgroundColor: '#F9D3D5',
                      padding: '8px 10px',
                      borderRadius: '10px',
                    }}
                  >
                    <Icon
                      path={mdiAlert}
                      size={3}
                      color="#ED2E2E"
                      className="warning-icon"
                    />
                    <div className="warning"></div>
                    <div>
                      <p className="mb-0" style={{ fontSize: '12px' }}>
                        <strong>
                          {armDetails.kycLevel} Investment Conditions
                        </strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: '12px' }}>
                        maximumSingleInvestmentAmount:{' '}
                        {data.kycLevel.map((item) => {
                          if (item.level === armDetails.kycLevel) {
                            return item.maximumSingleInvestmentAmount;
                          }
                        })}
                      </p>
                      <p
                        className="mb-0"
                        style={{ fontSize: '13px', lineHeight: '20px' }}
                      >
                        maximumSingleRedemptionAmount:{' '}
                        {data.kycLevel.map((item) => {
                          if (item.level === armDetails.kycLevel) {
                            return item.maximumSingleRedemptionAmount;
                          }
                        })}
                      </p>
                      <p
                        className="mb-0"
                        style={{ fontSize: '13px', lineHeight: '20px' }}
                      >
                        maximumCumulativeInvestmentAmount:{' '}
                        {data.kycLevel.map((item) => {
                          if (item.level === armDetails.kycLevel) {
                            return item.maximumCumulativeInvestmentAmount;
                          }
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="col-lg-11 col-xl-6 col-md-12 left-side pe-lg-3">
                <div className="col-md-12 input-box">
                  <label htmlFor="kycLevel" className="mb-1 mt-3">
                    KYC Level
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <select
                    name="kycLevel"
                    className="form-control"
                    id="kycLevel"
                    onChange={handleInputChange}
                    disabled={disabled}
                    defaultValue={armDbDetails?.kycLevel}
                    required={true}
                  >
                    <option value="">Select option</option>
                    {data.kycLevel.map((item, key) => (
                      <option value={item.level} key={key}>
                        {item.level}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="employmentStatus" className="mb-1 mt-3">
                    Employment Status
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <select
                    name="employmentStatus"
                    className="form-control"
                    id="employmentStatus"
                    onChange={handleInputChange}
                    required
                    disabled={disabled}
                    defaultValue={armDetails.employmentStatus}
                  >
                    <option value="">Select employment status</option>
                    {data.employmentStatuses.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="idType" className="mb-1 mt-3">
                    Identity Type
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <select
                    name="idType"
                    id="idType"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={armDbDetails?.idType}
                    required
                  >
                    <option value="">Select option</option>
                    {data.idTypes.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="issueDateOfId" className="mb-1 mt-3">
                    Date Of Issue
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <Input
                    name="issueDateOfId"
                    classes="form-control"
                    type="date"
                    id="issueDateOfId"
                    disabled={disabled}
                    onChange={handleInputChange}
                    defaultValue={armDbDetails?.issueDateOfId}
                  />
                </div>

                <div className="col-md-12 input-box">
                  <label htmlFor="expiryDateOfId" className="mb-1 mt-3">
                    Date Of Expiry
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <Input
                    name="expiryDateOfId"
                    classes="form-control"
                    type="date"
                    id="expiryDateOfId"
                    disabled={disabled}
                    defaultValue={armDbDetails?.expiryDateOfId}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="col-lg-11 col-xl-6 col-md-12 right-side ps-lg-3 ">
                <div className="col-md-12 input-box">
                  <label htmlFor="utilityBillIdType" className="mb-1 mt-3">
                    Type Of Utility Bill
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <select
                    name="utilityBillIdType"
                    className="form-control"
                    id="utilityBillIdType"
                    onChange={handleInputChange}
                    disabled={disabled}
                    defaultValue={armDetails.utilityBillIdType}
                    required
                  >
                    <option value="">Select bill type</option>
                    {data.utilityBillTypes.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label
                    htmlFor="annualExpectedAnnualIncomeRange"
                    className="mb-1 mt-3"
                  >
                    Annual Expected Income Range
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <select
                    name="annualExpectedAnnualIncomeRange"
                    className="form-control"
                    id="annualExpectedAnnualIncomeRange"
                    onChange={handleInputChange}
                    required
                    defaultValue={armDetails.annualExpectedAnnualIncomeRange}
                    disabled={disabled}
                  >
                    <option value="">Select option</option>
                    {data.incomeRanges.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label
                    htmlFor="politicallyExposedPersons"
                    className="mb-1 mt-3"
                  >
                    Politically Exposed Person?
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <select
                    name="politicallyExposedPersons"
                    className="form-control"
                    id="politicallyExposedPersons"
                    onChange={handleInputChange}
                    required
                    defaultValue={armDetails.politicallyExposedPersons}
                    disabled={disabled}
                  >
                    <option value="">Select option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div className="col-md-12 input-box">
                  <label
                    htmlFor="politicallyExposedPersonsCategory"
                    className="mb-1 mt-3"
                  >
                    Politically Exposed Persons Category
                  </label>
                  <select
                    name="politicallyExposedPersonsCategory"
                    className="form-control"
                    id="politicallyExposedPersonsCategory"
                    onChange={handleInputChange}
                    defaultValue={
                      armDetails.politicallyExposedPersons != 'no'
                        ? armDetails.politicallyExposedPersonsCategory
                        : ''
                    }
                    disabled={disabled}
                  >
                    <option value="">Select option</option>
                    {data.politicallyExposedPersonsCategory.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </motion.div>
          <div className="row justify-content-center text-center mt-4">
            <div className="col-lg-8 col-md-8">
              <div className="profile-btn d-flex gap-3 align-items-center justify-content-center">
                {!disabled && (
                  <Button
                    classes="secondary-btn btn-lg px-4 cancel"
                    content="Cancel"
                    onClick={() => setDisabled(true)}
                  />
                )}
                <Button
                  classes="primary-btn btn-lg px-4"
                  content={disabled ? 'Edit Profile' : 'Save'}
                  onClick={() => {
                    if (disabled) {
                      setDisabled(false);
                    } else {
                      handleUpdateArmdetails();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(SettingsArmDetailsForm);
