import React from 'react';
import Blank from './sections/Blank';
import Footer from './sections/Footer';
import GetStarted from './sections/GetStarted';
import Header from './sections/Header';
import HeroSection from './sections/HeroSection';
import HowItWorks from './sections/HowItWorks';
import Newsletter from './sections/Newsletter';
import Testimonials from './sections/Testimonials';
import WhatWeDo from './sections/WhatWeDo';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Home | Trade Lenda Landing Page!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <HeroSection />
      <WhatWeDo />
      <HowItWorks />
      <Testimonials />
      <Blank height="40px" />
      <GetStarted />
      <Blank height="40px" />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Home;
