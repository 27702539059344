/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import BaseDirectories from '../../../../base directories/BaseDirectories';
import Button from '../../../../components/ui/Button';
import { useLocation } from 'react-router-dom';

import Spinner from '../../../../components/ui/Spinner';
import { StoreContext } from '../../../../ mobx stores/RootStore';
import { observer } from 'mobx-react-lite';
import InputLendaAmountModal from './InputLendaAmountModal';
const InvestmentLendaFunds = () => {
  const [openInvestmentModal, setOpenInvestmentModal] = useState(false);
  const location = useLocation();
  const [data] = useState<any>(location.state);
  const { investStore } = useContext(StoreContext);
  const { submitting, submittingTradelenda } = investStore;

  return (
    <div className="m-m-fund container">
      {submitting || submittingTradelenda ? <Spinner /> : <></>}

      {openInvestmentModal && (
        <InputLendaAmountModal
          button={true}
          btnText1="invest Now"
          investmentData={data}
          setOpenInvestmentModal={setOpenInvestmentModal}
        />
      )}
      <span
        className="back mt-2"
        role="button"
        onClick={() => window.history.back()}
      >
        <MdArrowBack />
        <p>Back</p>
      </span>

      <div>
        <img
          src={`${BaseDirectories.IMAGES_DIR}/${
            data?.investmentName == 'BASIC LENDA'
              ? 'BasiclendaColour.png'
              : data?.investmentName == 'CLASSIC LENDA'
              ? 'ClassiclendaColour.png'
              : data?.investmentName == 'ELITE LENDA'
              ? 'ElitelendaColour.png'
              : data?.investmentName == 'DIAMOND LENDA'
              ? 'DiamondColour.png'
              : null
          }`}
          alt=""
          style={{ width: '30%' }}
        />
        <h3 className="mt-2">{data?.investmentName}</h3>
        <span className="d-flex y-date mt-1">
          <p>
            Tenor returns{'  '}
            <img
              src={`${BaseDirectories.IMAGES_DIR}/Arrow_Up.png`}
              alt=""
              style={{ width: '15px', height: '15px', marginBottom: '5px' }}
            />
            <span className="text-success"> {data?.interestRate}%</span>
          </p>
        </span>
        <p>
          Amount:{' '}
          <span className="indicator" style={{ color: '#0000FF' }}>
            {' '}
            ₦{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(data?.amountRange?.minAmount)}
            {' - '}
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(data?.amountRange?.maxAmount)}
          </span>
        </p>
      </div>

      <div className="content-cont mt-5 row">
        <div className="left col-md-6">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/LendaChart.png`}
            alt=""
            style={{ width: '70%' }}
          />
        </div>

        <div className="right col-md-3">
          <h6 className="mb-3">
            <span className="indicator" style={{ color: '#0000FF' }}>
              DETAILS
            </span>
          </h6>
          <p>
            The Investment Lenda plan ranges between{' '}
            <strong className="text-primary">
              ₦{' '}
              {new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(data?.amountRange?.minAmount)}
              {' - '}₦{' '}
              {new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(data?.amountRange?.maxAmount)}
            </strong>{' '}
            {'  '}
            at 3 months minimum tenor and{' '}
            <strong className="text-success">
              {' '}
              {data?.interestRate}%
            </strong>{' '}
            interest rate monthly through-out investment duration.
          </p>

          <Button
            classes="primary-btn btn-lg btn-block w-100 px-4"
            content="Lend"
            type="button"
            onClick={() => {
              setOpenInvestmentModal(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default observer(InvestmentLendaFunds);
