/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useContext, useState } from 'react';
import { StoreContext } from '../../../ mobx stores/RootStore';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';

const Ussd = ({ setOpenModal }) => {
  const [showAmountInput, setShowAmountInput] = useState(true);
  const [transactionDetails, setTransactionDetails] = useState({
    bank: '',
    amount: '',
  });

  const handleInputChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    const val =
      element.name === 'amount' ? parseInt(element.value) : element.value;

    setTransactionDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: val };
    });
  };

  const validateAmount = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const { walletStore } = useContext(StoreContext);
  const { banks } = walletStore;

  return (
    <div className="notification-modal">
      <div className="bg-white notification-box">
        <span className="close" onClick={() => setOpenModal(false)}>
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="Close"
          />
        </span>
        <img
          src={`${BaseDirectories.IMAGES_DIR}/arrowFoward.png`}
          alt=""
          style={{ width: '20%' }}
          className="mb-3"
        />

        <h4 className="mb-4">USSD</h4>

        {!showAmountInput && (
          <div className="mb-2 amount">
            <h6>₦{transactionDetails?.amount?.toLocaleString()}.00</h6>
          </div>
        )}

        <form className="w-100">
          {!showAmountInput ? (
            <div>
              <label
                htmlFor="bank"
                className="mb-1 mt-3 fw-normal text-start d-block"
              >
                Bank
              </label>
              <input
                list="banks"
                className="form-control mb-4 py-2"
                onChange={handleInputChange}
                name="bank"
              />
              <datalist id="banks">
                {toJS(banks).length > 0 &&
                  toJS(banks).map(({ bankName, NIPCode }) => (
                    <option key={NIPCode} value={bankName} />
                  ))}
              </datalist>
              <div className="group-btn justify-content-center">
                <Button
                  classes="secondary-btn btn-md"
                  content="Back"
                  onClick={() => setShowAmountInput(true)}
                  type="button"
                />
                <Button
                  classes="primary-btn btn-md"
                  content="Proceed"
                  type="submit"
                  // onClick={() => setSuccess(true)}
                />
              </div>
            </div>
          ) : (
            <div className="row justify-content-center amount-box">
              <div className="col-10">
                <label htmlFor="amount" className="mb-1 text-start d-block">
                  Amount
                </label>
                <Input
                  onKeyPress={validateAmount}
                  onChange={handleInputChange}
                  name="amount"
                  classes="form-control mb-3"
                  type="text"
                  id="amount"
                  value={transactionDetails.amount}
                />
              </div>
              <div className="col-10">
                <Button
                  classes="primary-btn btn-md px-4 mt-2"
                  content="Next"
                  type="button"
                  onClick={() =>
                    transactionDetails.amount && setShowAmountInput(false)
                  }
                />
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default observer(Ussd);
