/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { toJS } from 'mobx';

type Props = {
  route?: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  onClick?: () => void;
  dataObj?: any;
  Data?: any;
  setOpenBillTransactionModal: (state: any) => void;
};

type Params = {
  amount: number;
  otp: number;
  reason: string;
};

const BillTransactionModal: FC<Props> = ({
  route,
  onClick = () => {
    setOpenBillTransactionModal(false);
  },
  Data,
  setOpenBillTransactionModal,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);
  return (
    <div className="notification-modal">
      <div
        className="bg-white notification-box"
        style={{
          maxWidth: '815px',
          textAlign: 'left',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          fontSize: '12px',
          alignContent: 'flex-start',
          display: 'flex',
          padding: '20px',
        }}
      >
        <span
          onClick={() => {
            setOpenBillTransactionModal(false);
          }}
          className="close"
          role="button"
        >
          <FaTimes />
        </span>

        <div className="rq-loan-details">
          <br />
          <div className="d-flex justify-content-between ps-4 pe-4">
            <h4 className="text-primary text-uppercase">
              Bill Payment Summary
            </h4>
          </div>
          <hr />
          <ul
            className="px-4 py-4"
            style={width < 600 ? { columns: '1' } : { columns: '2' }}
          >
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Service Type
                </strong>
                <p
                  className="lead mark text-uppercase"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}
                >
                  {Data.serviceType}
                </p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Debit Status
                </strong>
                <p
                  className="lead mark text-capitalize"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}
                >
                  {Data.debitStatus}
                </p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Phone Number
                </strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}
                >
                  {Data.phone}
                </p>
              </div>
            </li>

            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">Date</strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}
                >
                  {moment(Data.date).format('MMMM Do YYYY, h:mm:ss a')}
                </p>
              </div>
            </li>
            {/* <hr /> */}
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Transaction Status
                </strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}
                >
                  {Data.transactionStatus}
                </p>
              </div>
            </li>
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">
                  Transaction Reference
                </strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}
                >
                  {Data.transactionReference}
                </p>
              </div>
            </li>
            {/* <hr /> */}
            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">Amount</strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}
                >{`₦ ${Number(toJS(Data.amount)).toLocaleString()}`}</p>
              </div>
            </li>

            <li>
              <div className="ld-box">
                <strong className="blockquote text-secondary">Narration</strong>
                <p
                  className="lead mark"
                  style={{
                    backgroundColor: '#eff4ff',
                    borderRadius: '5px',
                  }}
                >
                  {Data.narration}
                </p>
              </div>
            </li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default observer(BillTransactionModal);
