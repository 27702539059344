import React, { FC, useState } from 'react';
import { useStep } from 'react-hooks-helper';
import BaseDirectories from '../../../base directories/BaseDirectories';
import PhoneNumberValidation from './GuarantorFormSteps/PhoneNumberValidation';
import OtpValidation from './GuarantorFormSteps/OtpValidation';
import GuarantorInformation from './GuarantorFormSteps/GuarantorInformation';

interface Step {
  id: string;
}

const GuarantorMultistepForm: FC = () => {
  const [x, setX] = useState(0);
  // const [formData, setForm] = useForm(profileData);
  const { index, step, navigation } = useStep({
    steps: [
      { id: 'personal details' },
      { id: 'business details' },
      { id: 'bank details' },
    ],
  });
  const { id } = step as Step;
  return (
    <div className="profile-multistep guarantor-multistep">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-sm-10">
            <div className="logo text-center">
              <img src={`${BaseDirectories.LOGOS_DIR}/logo1.png`} alt="" />
            </div>
            <div className="step-progress mb-sm-5 mb-4">
              <button
                type="button"
                onClick={() => {
                  if (index > 0) {
                    setX(-1000);
                  } else {
                    setX(1000);
                  }
                  navigation.go?.('personal details');
                }}
                className={`step ${id === 'personal details' ? 'active' : ''}`}
              ></button>
              <button
                type="button"
                onClick={() => {
                  if (index > 1) {
                    setX(-1000);
                  } else if (index < 1) {
                    setX(1000);
                  }
                  navigation.go?.('business details');
                }}
                className={`step ${id === 'business details' ? 'active' : ''}`}
              ></button>
              <button
                type="button"
                onClick={() => {
                  if (index < 2) {
                    setX(1000);
                  } else {
                    setX(-1000);
                  }
                  navigation.go?.('bank details');
                }}
                className={`step ${id === 'bank details' ? 'active' : ''}`}
              ></button>
            </div>

            {id === 'personal details' && (
              <PhoneNumberValidation x={x} setX={setX} next={navigation.next} />
            )}
            {id === 'business details' && (
              <OtpValidation
                x={x}
                setX={setX}
                next={navigation.next}
                previous={navigation.previous}
              />
            )}
            {id === 'bank details' && (
              <GuarantorInformation
                x={x}
                setX={setX}
                next={navigation.next}
                previous={navigation.previous}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuarantorMultistepForm;
