/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { observer } from 'mobx-react-lite';
import * as React from 'react';
// import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import Button from '../../../../components/ui/Button';
import Input from '../../../../components/ui/Input';
import Spinner from '../../../../components/ui/Spinner';
import { motion } from 'framer-motion';
import data from '../../../dashboard/invest/data.json';

interface INextOfKinFormProps {
  next: any;
  previous: any;
  setX: (val: number) => void;
  x: number;
}

const gender = {
  male: 'Male',
  female: 'Female',
  preferNotToSay: 'Prefer Not To Say',
};

const NextOfKinDetails: React.FunctionComponent<INextOfKinFormProps> = ({
  next,
  previous,
  x,
  setX,
}) => {
  const { loansStore } = React.useContext(StoreContext);

  const { sending, success, message } = loansStore;

  const [nOKDetails, setNOKDetails] = React.useState({
    firstName: '',
    lastName: '',
    gender: '',
    relationship: '',
    email: '',
    phoneNumber: '',
    Address: '',
  });

  const [allLoanDetails, setAllLoanDetails] = React.useState<any>({});

  React.useEffect(() => {
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setNOKDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: element.value };
    });

    if (
      element.value !== '' &&
      element.style.border === '2px solid rgb(255, 137, 137)'
    ) {
      element.style.border = '1px solid #ced4da';
    }
  };

  const [phoneNumber, setPhoneNumber] = React.useState('');
  React.useEffect(() => {
    setNOKDetails((prevDetails: any) => {
      return { ...prevDetails, phoneNumber };
    });
  }, [phoneNumber]);

  const handleCreateNOK = (e: React.FormEvent) => {
    e.preventDefault();

    if (Object.values(nOKDetails).filter((d) => d === '').length === 0) {
      loansStore.createNextOfKin(nOKDetails);
      return;
    }

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          nOKDetails[input.name] === '' ||
          nOKDetails[input.name] === 'NULL'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 30000);
        }
      });
    }
  };

  React.useEffect(() => {
    if (success === 'Next of kin details created successfully') {
      next();
      setX(1000);
    }
    if (success === 'Next of kin details updated successfully') {
      next();
      setX(1000);
    }
  }, [next, setX, success]);

  // const handleSubmit = if(Object.values(nOKDetails).filter((d) => d === '').length === 0) handleCreateNOK;

  return (
    <motion.div
      initial={{ x: x }}
      transition={{ duration: 0.5 }}
      animate={{ x: 0 }}
      className=""
    >
      <div>
        <form action="" onSubmit={handleCreateNOK}>
          <div className="py-5 px-4 bg-white rounded mt-4">
            {sending && <Spinner />}
            <>
              <div className="row justify-content-center">
                <div className="col-md-7 col-sm-10 text-center bg-primary-blue mt-4 rounded">
                  <h4>NEXT OF KIN DETAILS</h4>
                  <p>
                    Industry regulation requires us to collect this information
                    to verify your identity.
                  </p>
                </div>
                <div className="col-lg-6 col-md-8 pe-md-3 pe-lg-4">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="firstName" className="mb-1 mt-3">
                        First Name
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <Input
                        name="firstName"
                        classes="form-control"
                        type="text"
                        defaultValue={nOKDetails?.firstName}
                        id="firstName"
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-6">
                      <label htmlFor="lastName" className="mb-1 mt-3">
                        Last Name
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <Input
                        name="lastName"
                        classes="form-control"
                        type="text"
                        defaultValue={nOKDetails?.lastName}
                        id="lastName"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="gender" className="mb-1 mt-3">
                      Gender
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      onChange={handleInputChange}
                      className="form-control"
                      defaultValue={nOKDetails?.gender}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value={gender.female}>{gender.female}</option>
                      <option value={gender.male}>{gender.male}</option>
                      <option value={gender.preferNotToSay}>
                        {gender.preferNotToSay}
                      </option>
                    </select>
                  </div>

                  <div className="col-12">
                    <label htmlFor="relationship" className="mb-1 mt-3">
                      Next of kin’s Relationship
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="relationship"
                      className="form-control"
                      id="relationship"
                      onChange={handleInputChange}
                      required={true}
                      disabled={sending}
                      defaultValue={nOKDetails?.relationship}
                    >
                      <option value="">Select option</option>
                      {data.relationshipTypes.map((item, key) => (
                        <option value={item} key={key}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12">
                    <label htmlFor="relationship" className="mb-1 mt-3">
                      Email Address
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="email"
                      classes="form-control"
                      type="email"
                      id="email"
                      defaultValue={nOKDetails?.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="phoneNumber" className="mb-1 mt-3">
                      Mobile number
                      <span style={{ color: 'red' }}> *</span>
                    </label>

                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="NG"
                      onChange={(e: string) => setPhoneNumber(e)}
                      className="form-control"
                      id="phoneNumber"
                      required
                      value={
                        nOKDetails?.phoneNumber === undefined
                          ? ''
                          : nOKDetails?.phoneNumber
                      }
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="Address" className="mb-1 mt-3">
                      Address
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="Address"
                      classes="form-control"
                      type="text"
                      id="Address"
                      defaultValue={nOKDetails?.Address}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="profile-btn">
                    <Button
                      classes="primary-btn btnn"
                      content="Previous"
                      onClick={() => {
                        previous();
                        setX(-1000);
                      }}
                    />
                    <Button
                      classes="primary-btn btnn"
                      content={
                        allLoanDetails.nextOfKinDetails === undefined
                          ? 'Save and Continue'
                          : 'Update Details'
                      }
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default observer(NextOfKinDetails);
