import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import Footer from './Footer';
import Header from './Header';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
  return (
    <div className="lp-contact-us">
      <Header />
      <section className="lp-cntct-main-sect">
        <h2>Contact Us</h2>
        <div className="main-cont ">
          <div className="left-cont">
            <form className="contct-frm  container">
              <div className="row">
                <div className="col-12 mt-3 mb-6">
                  <label htmlFor="name" className="col-12 mb-2 mt-1">
                    Full Name*
                  </label>
                  <Input classes="form-control" type="text" name="name" />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3 mb-6">
                  <label htmlFor="email" className="col-12 mb-2 mt-1">
                    Email Address*
                  </label>
                  <Input classes="form-control" type="text" name="email" />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3 mb-6">
                  <label htmlFor="message" className="col-12 mb-2 mt-1">
                    Message
                  </label>
                  <textarea className="col-12 text-area form-control" />
                </div>
              </div>
              <div className="row ">
                <div className="sbmt-btn-class">
                  <Button
                    classes="primary-btn btn-lg mt-4 mb-6"
                    content="Submit"
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="right-cont">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/contct-us-img.png`}
              alt="Contact US"
            />
          </div>
        </div>
        <div className="sub-cont">
          <h3>Get In Touch With Us</h3>
          <p className="mt-3" style={{ width: '60%', margin: '0 auto' }}>
            Your satisfaction is our top priority! our support service is
            available 24/7 to assist you with any questions you have about
            Tradelenda and our services; loans, investments, insurance etc,
          </p>
          <p className="mt-3">
            <strong>You can get in touch with us any way you prefer:</strong>
          </p>
          <div className="Sm-cont mt-4 mb-5">
            <div className="lp-sm-icons mb-5">
              <a
                href="https://www.linkedin.com/company/tradelenda"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} className="lp-fi" />
              </a>
              <a
                href="https://instagram.com/tradelenda"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="lp-fi" />
              </a>
              <a
                href="https://twitter.com/tradelenda"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} className="lp-fi" />
              </a>
              <a
                href="https://www.facebook.com/tradelenda"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} className="lp-fi" />
              </a>
            </div>
          </div>
          <div className="pltfrms-cont">
            <div className="mt-3">
              <div className="d-flex reach-us-grp">
                <FontAwesomeIcon icon={faEnvelope} className="lp-fi" />
                <p>Send us a mail</p>
              </div>
              <p>support.tradelenda.examplecom</p>
            </div>
            <div className="mt-5">
              <div className="d-flex reach-us-grp">
                <FontAwesomeIcon icon={faPhone} className="lp-fi" />
                <p>Send us a mail</p>
              </div>
              <p>+23400003345000</p>
            </div>
            <div className="mt-5">
              <div className="d-flex reach-us-grp">
                <FontAwesomeIcon icon={faEnvelope} className="lp-fi" />
                <p>Send us a mail</p>
              </div>
              <p>Common questions people ask us</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
