import React from 'react';
import Button from '../../../components/ui/Button';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Blank from './Blank';

const HackathonHS = () => {
  return (
    <>
      <section className="landing-page-hero">
        <div className="container justify-content-center align-content-center hackathon-hero">
          <div className="d-flex justify-content-between my-5">
            <div className="">
              <img
                src={`${BaseDirectories.IMAGES_DIR}/hero-side.png`}
                alt="slide_image"
              />
            </div>
            <div className="">
              <img
                src={`${BaseDirectories.IMAGES_DIR}/hero-side2.png`}
                alt="slide_image"
              />
            </div>
          </div>
          <div>
            <div className="hero-top d-flex justify-content-center gap-4">
              <div className="first-apply">
                <div>
                  <img
                    src={`${BaseDirectories.LOGOS_DIR}/hackathon_logo.png`}
                    alt="slide_image"
                    style={{ marginBottom: '20px', width: ' 100%' }}
                  />
                </div>

                {/* <h1 className="hero-title mb-5">
                  Trade <br /> HACKA
                  <span style={{ color: '#06A77D' }}>THON</span>
                </h1> */}
                <Button
                  classes="white-btn btn-lg apply"
                  content="Apply now"
                  route="/hackathon/hsign_up"
                  style={{ fontWeight: 'bold' }}
                />
              </div>
              <div className="hero-second">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/hhero-img.png`}
                  alt="slide_image"
                  style={{
                    width: '70%',
                    marginLeft: '150px',
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <img
                src={`${BaseDirectories.IMAGES_DIR}/hero-img2.png`}
                alt="slide_image"
                style={{ width: 'auto' }}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="col-lg-12 about">
        <div className="hero-text">
          <div>
            <h1 className="mb-3 text-center">Why Hackathon?</h1>
            <p>
              <strong>Trade Lenda</strong> is a digital bank that provides
              access to finance for Small and Medium Scale Enterprises (SMEs).
              We are Tech-driven and leverage on Smart and Innovative
              technological solutions to support our SMEs scale their
              businesses.
            </p>
            <p>
              This <strong>Hackathon</strong> is an exciting opportunity for
              innovators and developers to collaborate, create, and solve
              real-world problems. It is a high-energy, time-bound competition
              where participants work build the desired solutions. Beyond the
              prize, we hope that this event will give us the opportunity to
              meet great talent that we can collaborate with in future Tech
              projects.
            </p>
          </div>
        </div>
      </div>
      <Blank height="40px" />
      <section className="project-goal-container d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-column flex-md-row justify-content-center gap-4 project-goal">
          <div className="bg-white p-4 col-12 col-md-3 rounded">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/project.png`}
              alt="slide_image"
              style={{ width: '50%' }}
            />
            <div className="proj-card">
              <div className="title proj col-7">Project</div>{' '}
              {/* <span className="title-sub mt-5 fw-semibold">DESIGNING</span> */}
              <p className="fw-semibold">
                Develop a Smart Solution that supports SMEs to Optimize
                Inventory and Online Orders
              </p>
            </div>
          </div>
          <div className="bg-white p-4 col-12 col-md-3 rounded">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/goal.png`}
              alt="slide_image"
              style={{ width: '50%' }}
            />
            <div className="goal-card ">
              <div className="title goal text-white col-7">Goal</div>{' '}
              <p className="mt-2 fw-semibold">
                The web application should streamline a company’s inventory
                management by using IoT and artificial intelligent to predict
                stock levels, optimize inventory and automate reordering.
              </p>
            </div>
          </div>
        </div>{' '}
        <div className="col-12 text-center my-4">
          <Button
            classes="white-btn btn-lg apply"
            content="Apply now"
            route="/hackathon/hsign_up"
            style={{ fontWeight: 'bold' }}
          />
        </div>
      </section>
    </>
  );
};

export default HackathonHS;
