/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  useContext,
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  FormEvent,
} from 'react';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { toast } from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import InputPinModal from './InputPinModal';
import useOutsideClick from '../../../hooks/useOutsideClick';

interface ModalProps {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
}

const BillModal: FC<ModalProps> = ({ showModal, setShowModal }) => {
  const { walletStore } = useContext(StoreContext);
  const { billCategory, billField, wallet } = walletStore;
  const [showPinModal, setShowPinModal] = useState(false);
  const [item, setItem] = useState({});
  const [inputDetails, setInputDetails] = useState({
    key: '',
    value: '',
  });
  const [paymentDetails, setPaymentDetails] = useState({
    intentId: '',
    inputs: [
      {
        key: '',
        value: '',
      },
    ],
    billerId: '',
    billerName: '',
    customerAccountNo: '',
    transactionPin: '',
    amount: '',
  });

  const modalRef = useRef(null);
  useOutsideClick(modalRef, setShowModal);

  const handleInputChange = (e: ChangeEvent, index: number) => {
    const element = e.target as HTMLInputElement;
    const paymentDetailsInputs = [...paymentDetails.inputs];

    const dd = {
      key: element.name,
      value: element.value,
    };

    const foundObj = paymentDetailsInputs.find((obj) => obj.key === dd.key);

    if (foundObj) {
      paymentDetailsInputs.map((rr: any) => {
        if (rr.key === dd.key) {
          rr.value = element.value;
        }
      });
    } else {
      paymentDetailsInputs.push(dd);
    }

    const tt = toJS(billField)?.inputFields.map((field: any) => {
      const filteredItems = field.items.filter(
        (t: any) => t.id === element.value,
      );
      return filteredItems;
    });

    const ftt = tt.filter((t: any) => t.length > 0)[0];

    if (ftt !== undefined) {
      const itemKeys = Object.keys(ftt[0]);
      if (itemKeys.length > 0) {
        itemKeys.map((key: string) => {
          const it = {
            key: key,
            value: ftt[0][key],
          };
          if (it && it.key !== 'id') {
            if (foundObj) {
              paymentDetailsInputs.map((rr: any) => {
                if (rr.key === it.key) {
                  rr.value = ftt[0][key];
                }
              });
            } else {
              paymentDetailsInputs.push(it);
            }
          }
        });
      }
    }

    const filteredArr = paymentDetailsInputs.filter((obj) =>
      Object.values(obj).some(
        (val) => val !== '' && val !== null && val !== undefined,
      ),
    );

    setPaymentDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        inputs: filteredArr,
      };
    });
  };

  useEffect(() => {
    if (Object.keys(billField).length > 0) {
      const { intentId, billerId, billerName, walletAccountNo } =
        billField.transactionIntent;
      const amount = paymentDetails.inputs.filter(
        (input: any) => input?.key === 'amount',
      )[0]?.value;

      setPaymentDetails((prevDetails: any) => {
        return {
          ...prevDetails,
          amount,
          intentId,
          billerId: billerId.toString(),
          billerName,
          customerAccountNo: walletAccountNo,
        };
      });
    }
  }, [billField, paymentDetails.inputs]);

  const handleShowPinModal = () => {
    setShowPinModal(true);
  };

  const handleGetBillFieldById = (billId: string | number) => {
    if (billId) {
      walletStore.getBillFieldById(billId, wallet.walletIdAccountNumber);
    }
  };

  const handleSubmitPayment = (e: FormEvent) => {
    e.preventDefault();
    if (paymentDetails.transactionPin === '') {
      toast.error('Some fields are empty');
      return;
    }
    walletStore.makeBillPayment(paymentDetails);
  };

  return (
    <div className="bill-modal">
      <div ref={modalRef} className="modal-box">
        <span
          onClick={() => {
            walletStore.setBillField({});
            setItem({});
            setShowModal(false);
          }}
          className="close"
          role="button"
        >
          <FaTimes />
        </span>
        <h2 className="mb-5">Payment</h2>
        <form onSubmit={handleSubmitPayment} className="add-guarantor-form">
          {showPinModal ? (
            <InputPinModal
              setDetails={setPaymentDetails}
              setShowPinModal={setShowPinModal}
            />
          ) : Object.keys(billField).length === 0 ? (
            <div className="paybills-container">
              {toJS(billCategory).length > 0 &&
              toJS(billCategory)?.filter(
                (bill: any) => bill.provider_id === '1',
              ).length > 0 ? (
                billCategory
                  .filter((bill: any) => bill.provider_id === '1')
                  .map((cat: any, i: number) => (
                    <div
                      role="button"
                      key={i}
                      className="bill-card"
                      onClick={() => handleGetBillFieldById(cat.bill_id)}
                    >
                      <p>{cat.name}</p>
                    </div>
                  ))
              ) : (
                <p className="w-100 text-center">No Data</p>
              )}
            </div>
          ) : (
            <div className="bill-payment-form">
              <div className="row justify-content-center">
                {billField?.inputFields.length > 0 &&
                  billField?.inputFields.map((field: any, i: number) =>
                    field.items.length > 0 ? (
                      <div className="col-10" key={i}>
                        <label htmlFor={field.key} className="mb-1 mt-3">
                          {field.name}
                        </label>
                        <select
                          className="form-control"
                          name={field.key}
                          id={field.key}
                          onChange={(e: React.ChangeEvent<Element>) => {
                            handleInputChange(e, i);
                          }}
                        >
                          <option value="">Select option</option>
                          {field.items.map((it: any, ii: number) => (
                            <option key={ii} value={it.id}>
                              {it.name} {it.amount ? `- ₦${it.amount}` : ''}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div className="col-10" key={i}>
                        <label htmlFor={field.key} className="mb-1 mt-3">
                          {field.name}
                        </label>
                        <Input
                          onChange={(e: React.ChangeEvent<Element>) =>
                            handleInputChange(e, i)
                          }
                          name={field.key}
                          classes="form-control"
                          type="text"
                          id={field.key}
                          //   value={paymentDetails?.inputs[i]?.value}
                        />
                      </div>
                    ),
                  )}
                <div className="col-10 mt-4">
                  <div className="d-flex align-items-center gap-3">
                    <Button
                      classes="secondary-btn btn-md"
                      content="Back"
                      onClick={() => {
                        walletStore.setBillField('');
                        paymentDetails.inputs = [];
                      }}
                      type="button"
                      style={{ width: '100px' }}
                    />
                    <Button
                      classes="primary-btn btn-lg btn-block w-100 px-4"
                      content="Next"
                      type="button"
                      onClick={handleShowPinModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default observer(BillModal);
