import React from 'react';
import Blank from './sections/Blank';
import Footer from './sections/Footer';
import GetStarted from './sections/GetStarted';
import Header from './sections/Header';
import Newsletter from './sections/Newsletter';
import { Helmet } from 'react-helmet-async';

const StructuredMarkets = () => {
  return (
    <div className="bg-white market-structure">
      <Helmet>
        <title>Market Structure | Trade Lenda Market Structure!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <section className="hero">
        <div className="container">
          <div className="lp-team">
            <h2>Trade Lenda structured market list</h2>
          </div>
          <table className="table table-striped responsive-table mb-0">
            <thead>
              <tr>
                <th>NO/S</th>
                <th>Name of market</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {marketList.map((market, i) => (
                <tr key={i}>
                  <td>{market.id}</td>
                  <td>{market.name}</td>
                  <td>{market.location}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <GetStarted />
      <Blank height="80px" />
      <Newsletter />
      <Footer />
    </div>
  );
};

const marketList = [
  {
    id: '01',
    name: 'Alaba International Market',
    location: 'Ojo-Alaba, Lagos State',
  },
  {
    id: '02',
    name: 'Trade Fair International Market',
    location: 'Lagos State',
  },
  {
    id: '03',
    name: 'Balogun Market',
    location: 'Lagos Island, Lagos State',
  },
  {
    id: '04',
    name: 'Oshodi Market',
    location: 'Lagos State',
  },
  {
    id: '05',
    name: 'Tejuosho Market',
    location: 'Yaba, Lagos State',
  },
  {
    id: '06',
    name: 'Oyingbo Market',
    location: 'Oyingbo, Lagos State',
  },
  {
    id: '07',
    name: 'Computer Village',
    location: 'Ikeja, Lagos State',
  },
  {
    id: '08',
    name: 'Mile 12 Market',
    location: 'Ketu-Mile 12, Lagos State',
  },
  {
    id: '09',
    name: 'Ikota Shopping Complex',
    location: 'Ikota-VGC, Lagos State',
  },
  {
    id: '10',
    name: 'Ladipo Market ',
    location: 'Mushin, Lagos State',
  },
  {
    id: '11',
    name: 'Daleko Market',
    location: 'Isolo, Lagos State',
  },
  {
    id: '12',
    name: 'Idumota Market',
    location: 'Lagos Island, Lagos State',
  },
  {
    id: '13',
    name: 'Odunade Building Materials Market',
    location: 'Orile Coker, Lagos State',
  },
  {
    id: '14',
    name: 'Mushin Market',
    location: 'Mushin, Lagos State',
  },
  {
    id: '15',
    name: 'Dosunmu Market',
    location: 'Lagos Island, Lagos State',
  },
  {
    id: '16',
    name: 'Iddo Market',
    location: 'Lagos State',
  },
  {
    id: '17',
    name: 'Isheri Retail Market',
    location: 'Isheri, Lagos State',
  },
  {
    id: '18',
    name: 'Jankara Market',
    location: 'Lagos Island, Lagos State',
  },
  {
    id: '19',
    name: 'Apongbo Market ',
    location: 'Lagos Island, Lagos State',
  },
  {
    id: '20',
    name: 'Isale Eko Market',
    location: 'Lagos Island, Lagos State',
  },
  {
    id: '21',
    name: 'The Arena Shopping Complex',
    location: 'Oshodi, Lagos State',
  },
];

export default StructuredMarkets;
