/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { configure, makeAutoObservable, runInAction, toJS } from 'mobx';
import BaseDirectories from '../base directories/BaseDirectories';
import { BrowserLogger } from '../common/logger/Logger';

configure({ enforceActions: 'always' });

export enum InvoiceStatus {
  ALL = 'All',
  DRAFT = 'Draft',
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  PAID = 'Paid',
  OVERDUE = 'Overdue',
}

export type Invoice = {
  _id: string | null;
  userId: string;
  customer: string;
  invoiceNumber: string;
  invoiceDate: string;
  paymentDueDate: string;
  companyName: string;
  companyAddress: string;
  website: string;
  email: string;
  phoneNumber: string;
  items: Item[];
  status: InvoiceStatus[];
  termsAndConditions: string;
};

type Item = {
  id: string;
  description: string;
  quantity: number;
  price: number;
  amount: number;
};

type Message = {
  type: string;
  msg: any;
};

type Profile = {
  [key: string]: any;
};

export class InvoiceStore {
  tempUserID = 'eh89emquy9mxhu8r99r894y7q892';
  loading = false;
  submitting = false;
  token: any = sessionStorage.getItem('accessToken') || '';
  invoices: Invoice[] = [];

  invoiceDetails: Invoice = {
    _id: null,
    userId: '',
    customer: '',
    invoiceNumber: '',
    invoiceDate: '',
    paymentDueDate: '',
    companyName: '',
    companyAddress: '',
    website: '',
    email: '',
    phoneNumber: '',
    items: [],
    status: [InvoiceStatus.PENDING],
    termsAndConditions: '',
  };
  message: Message = {
    type: '',
    msg: '',
  };

  error = {
    type: 'error',
    msg: '',
  };

  success = {
    type: 'success',
    msg: '',
  };

  private logger!: BrowserLogger;
  private userProfile!: Profile;

  constructor() {
    makeAutoObservable(this);
    runInAction(() => {
      this.logger = new BrowserLogger(this.constructor.name);
      this.userProfile = JSON.parse(
        sessionStorage.getItem('user') || '{}',
      ) as Profile;
      this.getInvoices();
    });
  }

  getInvoices() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/invoices/user/${this.tempUserID}`, {
        headers,
      })
      .then((res: any) => {
        runInAction(() => {
          this.setLoading(false);
          this.setInvoices(res.data);
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.setLoading(false);
          this.setError('Error retrieving invoices');
        });
      });
  }

  createInvoice(data: Invoice) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    if (Object.values(data).filter((d: any) => d === '').length === 0) {
      this.setSubmitting(true);
      axios
        .post(`${BaseDirectories.API_BASE_URL}/invoices/create`, data, {
          headers,
        })
        .then((res: any) => {
          runInAction(() => {
            this.logger.info(
              `User | Create Invoice | ${toJS(this.userProfile?.email)}`,
              res.data,
            );
            this.setMessage('success', 'Invoice successfully created');
            setTimeout(() => {
              this.setMessage('', '');
            }, 3000);
            this.setSubmitting(false);
          });
        })
        .catch((err) => {
          runInAction(() => {
            this.logger.error(
              `User | Create Invoice | ${toJS(this.userProfile?.email)}`,
              err,
            );
            this.setMessage('error', err.response.data.message);
            setTimeout(() => {
              this.setMessage('', '');
            }, 3000);
            this.setSubmitting(false);
          });
        });
    } else {
      this.logger.error(
        `User | Create Invoice | ${toJS(this.userProfile?.email)}`,
        data,
      );
      this.setMessage('error', 'Some fields are empty!');
      setTimeout(() => {
        this.setMessage('', '');
      }, 4000);
    }
  }

  updateInvoice(data: Invoice, id: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    if (Object.values(data).filter((d: any) => d === '').length === 0) {
      this.setSubmitting(true);
      axios
        .put(`${BaseDirectories.API_BASE_URL}/invoices/update/${id}`, data, {
          headers,
        })
        .then((res: any) => {
          runInAction(() => {
            this.logger.info(
              `User | Update Invoice | ${toJS(this.userProfile?.email)}`,
              res.data,
            );
            this.setMessage('success', 'Invoice updated successfully!');
            setTimeout(() => {
              this.setMessage('', '');
            }, 4000);
            this.setSubmitting(false);
          });
        })
        .catch((err) => {
          runInAction(() => {
            this.logger.error(
              `User | Update Invoice | ${toJS(this.userProfile?.email)}`,
              err,
            );
            this.setMessage('error', err.response.data.message);
            setTimeout(() => {
              this.setMessage('', '');
            }, 6000);
            this.setSubmitting(false);
          });
        });
    } else {
      this.logger.error(
        `User | Update Invoice | ${toJS(this.userProfile?.email)}`,
        data,
      );
      this.setMessage('error', 'Some fields are empty!');
      setTimeout(() => {
        this.setMessage('', '');
      }, 4000);
    }
  }

  setInvoices = (res: any) => {
    this.invoices = res;
  };

  //   setInvoice = (res: any) => {
  //     this.invoice = res;
  //   };

  setLoading = (val: boolean) => {
    this.submitting = val;
  };

  setSubmitting = (val: boolean) => {
    this.submitting = val;
  };

  setMessage = (type: string, msg: string) => {
    this.message.type = type;
    this.message.msg = msg;
  };

  setError = (err: string) => {
    this.error.msg = err;
  };

  setInvoiceDetails = (invoiceData: Invoice) => {
    this.invoiceDetails = invoiceData;
  };

  SetAccessToken = (token: string) => {
    this.token = token || sessionStorage.getItem('accessToken');
  };
}
