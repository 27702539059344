/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogLogs, Logger } from '@datadog/browser-logs';

export class FuctionBrowserLogger {
  private logger: Logger;
  private context: string;

  constructor(context: string) {
    this.logger = datadogLogs.logger;
    this.context = context;
  }

  info(message: string, metadata: Record<string, any>) {
    metadata['context'] = this?.context;
    metadata['title'] = message;
    this.logger.info(message, metadata);
  }
  warn(message: string, metadata: Record<string, any>) {
    metadata['context'] = this?.context;
    metadata['title'] = message;
    this.logger.warn(message, metadata);
  }
  debug(message: string, metadata: Record<string, any>) {
    metadata['context'] = this?.context;
    metadata['title'] = message;
    this.logger.debug(message, metadata);
  }
  error(message: string, metadata: Record<string, any>) {
    metadata['context'] = this?.context;
    metadata['title'] = message;
    this.logger.error(message, metadata);
  }
}
