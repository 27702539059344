/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import data from './data.json';

type Props = {
  route?: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  onClick?: () => void;
  dataObj?: any;
  investmentData?: any;
  portfolioData?: any;
  setOpenOtpModal: (state: any) => void;
};

type Params = {
  amount: string;
  otp: number;
  reason: string;
};

const RedemptionModal: FC<Props> = ({
  onClick = () => {
    setOpenOtpModal(false);
  },
  investmentData,
  portfolioData,
  setOpenOtpModal,
}) => {
  const [details, setDetails] = useState<Params>({
    amount: '',
    otp: 0,
    reason: '',
  });
  const { investStore } = useContext(StoreContext);
  const { message, submittingInvestment } = investStore;
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const updateData = (e: any) => {
    const element = e.target as HTMLInputElement;
    if (element.name === 'amount') {
      const rawValue = e.target.value.replace(/,/g, '');
      if (/^\d*\.?\d*$/.test(rawValue)) {
        const formattedValue = Number(rawValue).toLocaleString(); // Format with commas
        setErrorMessage('');
        setDetails((prevDetails: any) => {
          return {
            ...prevDetails,
            [element.name]: formattedValue,
          };
        });
      } else {
        setErrorMessage('Enter a valid amount');
      }
    } else {
      setDetails((prevDetails: any) => {
        return {
          ...prevDetails,
          [element.name]: element.value,
        };
      });
    }
  };

  const handleSubmit = () => {
    if (Number(details.amount) <= 0) {
      toast.error('Invalid redemption amount, please check and try again');
    }
    if (Number(details.amount) > Number(investmentData?.investmentAmount)) {
      toast.error('Redemption amount is more than investment amount!');
    }
    if (details.otp.toString().length < 6) {
      toast.error('Invalid OTP number, please check and try again');
    }
    if (
      details.otp.toString().length == 6 &&
      Number(details.amount.replace(',', '')) <=
        Number(investmentData?.investmentAmount) &&
      Number(details.amount.replace(',', '')) > 0
    ) {
      const removeCommaInAmount = details?.amount.replace(/,/g, '');
      const redemptionParams = {
        otp: details?.otp?.toString(),
        amount: removeCommaInAmount,
        reason: details?.reason,
        investmentAmount: investmentData?.investmentAmount.toString(),
        productCode: investmentData?.productCode,
        membershipId: investmentData?.membershipId,
        investmentId: portfolioData?.investmentId,
      };
      investStore.redeemInvestment(redemptionParams);
    }
  };

  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Investment redeemption successful.'
    ) {
      setTimeout(() => {
        onClick();
        navigate('/dashboard/invest');
      }, 2000);
    }
  }, [submittingInvestment, message]);

  const handleOTP = () => {
    investStore.getOTP(investmentData.membershipId);
  };

  return (
    <div className="notification-modal">
      <div
        className="bg-white notification-box"
        style={{
          textAlign: 'left',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          fontSize: '20px',
          alignContent: 'flex-start',
          display: 'flex',
          padding: '40px',
        }}
      >
        <span
          onClick={() => {
            setOpenOtpModal(false);
          }}
          className="close"
          role="button"
        >
          <FaTimes />
        </span>
        <h5
          className="my-2"
          style={{
            fontSize: '20px',
          }}
        >
          Enter Amount
        </h5>
        <div className="input-group">
          <div className="input-group-prepend">
            <span
              className="input-group-text bg-white"
              style={{
                borderRight: '0',
                borderTopRightRadius: '0',
                borderBottomRightRadius: '0',
              }}
            >
              ₦
            </span>
          </div>
          <Input
            style={{ borderLeft: '0' }}
            name="amount"
            classes="form-control "
            type="text"
            id="amount"
            value={details?.amount}
            onChange={updateData}
          />
        </div>
        {errorMessage !== '' ? (
          <small className="text-danger mx-auto" style={{ fontSize: '14px' }}>
            {errorMessage}
          </small>
        ) : null}
        <p className="mx-auto">
          Available Balance = ₦{' '}
          {investmentData?.investmentAmount
            ? new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(investmentData?.investmentAmount)
            : '0.00'}
        </p>
        <h5
          className="my-2"
          style={{
            fontSize: '20px',
          }}
        >
          OTP
        </h5>
        <Input
          name="otp"
          classes="form-control"
          type="number"
          id="otp"
          onChange={updateData}
        />
        <h5
          className="my-2"
          style={{
            fontSize: '20px',
          }}
        >
          Reason
        </h5>
        <select
          name="reason"
          className="form-control"
          id="reason"
          onChange={updateData}
          required={true}
        >
          <option value="">Select option</option>
          {data.redemptionReasons.map((item, key) => (
            <option value={item} key={key}>
              {item}
            </option>
          ))}
        </select>
        <div className="group-btn">
          {details.otp.toString().length >= 6 ? (
            <Button
              classes="primary-btn w-auto btn-lg waves-effect"
              content="Get OTP"
              type="submit"
              disabled={true}
            />
          ) : (
            <Button
              classes="primary-btn w-auto btn-lg waves-effect"
              content="Get OTP"
              type="submit"
              onClick={() => {
                handleOTP();
              }}
            />
          )}

          {details.otp.toString().length === 6 ? (
            <Button
              classes="primary-btn w-auto btn-lg waves-effect"
              content="Redeem Investment"
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
            />
          ) : (
            <Button
              classes="primary-btn w-auto btn-lg waves-effect"
              content="Redeem Investment"
              type="submit"
              disabled
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(RedemptionModal);
