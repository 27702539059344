/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../../ mobx stores/RootStore';
import Button from '../../../components/ui/Button';

const InputPinModal = ({ setDetails, setShowPinModal }) => {
  //   const [bankReceiverFullName, setbankReceiverFullName] = useState('');
  const [pin, setPin] = useState('');
  const [pinToggle, setPinToggle] = useState(false);

  const { walletStore } = useContext(StoreContext);
  const { submitting } = walletStore;

  useEffect(() => {
    setDetails((prevDetails: any) => {
      return { ...prevDetails, transactionPin: pin };
    });
  }, [pin]);

  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  // console.log(passwordToggleValues);

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  return (
    <div className="input-pin">
      <h4 className="mb-3">Input pin to proceed</h4>
      <div className="row">
        <div className="col-md-12 input-box">
          <div>
            <OtpInput
              value={pin}
              onChange={setPin}
              numInputs={4}
              inputStyle="pin-style"
              containerStyle="pin-container-style"
              inputType={pinToggle ? 'tel' : 'password'}
              shouldAutoFocus={true}
              renderInput={(props) => (
                <input onKeyPress={validateNumber} {...props} />
              )}
            />
          </div>
          <div className="d-flex gap-2 justify-content-center align-items-center mb-3">
            <input
              type="checkbox"
              onChange={handlePinToggle}
              name="showPin"
              id="show-pin"
            />
            <label htmlFor="show-pin">Show pin</label>
          </div>
          <p style={{ fontSize: '14px' }}>
            Don't have a pin yet? Create one{' '}
            <Link style={{ color: 'blue' }} to="/dashboard/settings/create-pin">
              here
            </Link>{' '}
          </p>
        </div>
      </div>
      <div className="row justify-content-center mt-2">
        <div className="col-12">
          <div className="group-btn justify-content-center mt-2">
            <Button
              classes="secondary-btn btn-md"
              content="Back"
              onClick={() => setShowPinModal(false)}
              type="button"
            />
            <Button
              classes={`${
                submitting
                  ? 'primary-btn-disabled btn-lg btn-block w-100 px-4'
                  : 'primary-btn btn-lg btn-block w-100 px-4'
              }`}
              content={`${submitting ? 'Processing...' : 'Proceed'}`}
              disabled={submitting}
              type="submit"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(InputPinModal);
