import React from 'react';
import { MdArrowBack, MdArrowDropUp, MdCheck } from 'react-icons/md';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';

const AggressiveGrowthFund = () => {
  return (
    <div className="m-m-fund container">
      <span
        className="back"
        role="button"
        onClick={() => window.history.back()}
      >
        <MdArrowBack />
        <p>Back</p>
      </span>

      <div>
        <h5>Discovery Balance Fund</h5>
        <span className="d-flex y-date">
          <p>Yield on 4th Dec 2022: </p>
          <span className="indicator" style={{ color: '#44ab3b' }}>
            <MdArrowDropUp /> 12.25%
          </span>
        </span>

        <span className="risk">High Risk</span>
      </div>

      <div className=" content-cont  mt-5 row">
        <div className="left col-md-6">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/performanceChart.png`}
            alt=""
            style={{ width: '70%' }}
          />
        </div>

        <div className="right col-md-6">
          <h6 className="mb-3">Fund Factsheet</h6>
          <p>
            Suitable for investors interested in increasing their capital over
            the long term to meet needs such as education funding, property
            acquisition or leaving a legacy for loved ones amongst others.
          </p>
          <div className="list-group">
            <span>
              <MdCheck />
              <p>
                Invests in Equities (80%-100%) and Fixed income securities
                (0%-20%) respectively.
              </p>
            </span>

            <span>
              <MdCheck />
              <p>Earn multiple returns through capital growth and dividends.</p>
            </span>
            <span>
              <MdCheck />
              <p>
                Grow your wealth over the long term with returns above
                inflation.
              </p>
            </span>
            <span>
              <MdCheck />
              <p>
                Minimum initial investment is N50,000; minimum additional
                investment is N10,000. .
              </p>
            </span>
          </div>

          <Button
            classes="primary-btn btn-lg btn-block w-100 px-4"
            content="Invest Now"
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

export default AggressiveGrowthFund;
