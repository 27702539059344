/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react';
import Button from '../ui/Button';
import Input from '../ui/Input';
// import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { toast } from 'react-toastify';
import { StoreContext } from '../../mobx_stores/RootStore';
import Spinner from '../ui/Spinner';
import { useNavigate, useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';

interface IDocumentUploadFormProps {
  previous: any;
  setX: Function;
}

const validIDType = {
  nationalIdentityCard: 'National ID',
  driversLicense: 'Drivers License',
  internationalPassport: 'International Passport',
  votersCard: 'Voters Card',
};

const DocumentUploadForm: React.FunctionComponent<IDocumentUploadFormProps> = ({
  previous,
  setX,
}) => {
  const { loansStore } = React.useContext(StoreContext);
  const prevRoute = useLocation();
  const {
    sending,
    success,
    uploadProgress,
    documentS3Url,
    uploadSuccess,
    uploading,
    loanUserdetails,
  } = loansStore;

  const [formDetails, setFormDetails] = React.useState({
    validIdentificationType: '',
    validIdentification: '',
    utilityBill: '',
    signature: '',
    passport: '',
    bankStatement: '',
    seal: '',
    cac7: '',
    cac2: '',
    cacCertificate: '',
    lpoFile: '',
    proformaFile: '',
    others: '',
    othersName: '',
    MERMAT: '',
    personalPhoto: '',
    identityCard: '',
  });

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setFormDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: element.value };
    });
  };

  // S3 Function
  const [imagesrc] = React.useState('');
  const [file, setFile] = React.useState<any>(null);
  // const [uploading, setUploading] = React.useState(false);
  const [documentName, setDocumentName] = React.useState('');

  const handleFileChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;

    if (element?.files) {
      setFile(element?.files[0]);
      setDocumentName(element?.name);
    }
  };

  const uploadFile = () => {
    if (file) {
      if (file?.size > 3 * 1024 * 1000) {
        toast.error(
          'File size is too large! Size should not be more than 3MB.',
        );
      } else {
        const formData = new FormData();

        formData.append('file', file);
        loansStore.documentUpload(formData, documentName);
      }
    }
  };

  React.useEffect(() => {
    if (uploadSuccess) {
      setFormDetails((deets: any) => {
        return {
          ...deets,
          [documentName]: documentS3Url,
        };
      });
    }
  }, [uploadSuccess]);

  async function urlToFile() {
    const randomString = Math.random().toString(36).substring(2, 15);

    const blob = await fetch(imagesrc).then((it) => it.blob());
    const newFile = new File([blob], `${randomString}.jpg`, {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });
    setFile(newFile);
  }

  React.useEffect(() => {
    if (imagesrc) {
      urlToFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesrc]);

  const navigate = useNavigate();

  const handleCreateDocs = (e: React.FormEvent) => {
    e.preventDefault();

    loansStore.createDocumentsDetails(formDetails);
  };

  React.useEffect(() => {
    if (success === 'Document details created successfully') {
      if (prevRoute?.pathname === '/dashboard/wallet/user_personal_details') {
        navigate('/dashboard/wallet');
      } else {
        loanUserdetails?.organizationDetails?.shariaCom
          ? navigate('/dashboard/facility/request_for_facility')
          : navigate('/dashboard/loans/request_for_loan');
      }
    }
  }, [navigate, success]);

  return (
    <div>
      {sending && <Spinner />}

      <form action="" onSubmit={handleCreateDocs}>
        <div className="py-5 px-4 bg-white rounded mt-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 pe-md-3 pe-lg-4">
              <div className="col-md-12">
                <h3 className="">Valid Identity</h3>
                <p className="my-3">
                  Select the identification document you want to upload
                </p>

                <select
                  name="validIdentificationType"
                  id="validIdentificationType"
                  className="form-control  "
                  onChange={handleInputChange}
                  value={formDetails.validIdentificationType}
                >
                  <option value="">Select ID Type</option>
                  <option value={validIDType.nationalIdentityCard}>
                    {validIDType.nationalIdentityCard}
                  </option>
                  <option value={validIDType.internationalPassport}>
                    {validIDType.internationalPassport}
                  </option>
                  <option value={validIDType.driversLicense}>
                    {validIDType.driversLicense}
                  </option>
                  <option value={validIDType.votersCard}>
                    {validIDType.votersCard}
                  </option>
                </select>

                <label htmlFor="validIdentification" className="mb-1 mt-3">
                  ID No
                </label>
                <Input
                  name="validIdentification"
                  classes="form-control mb-4"
                  type="text"
                  id="validIdentification"
                  value={formDetails.validIdentification}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-12">
                <h3 className="mt-5 mb-3">Proof Of Address (Utility bill)</h3>
                <li>
                  It must not be more than 3 month from the time you registered{' '}
                </li>
                <li>It must carry the address you entered</li>
                <li>It must be clear and legible</li>
                <div className="file-input my-4">
                  <div>
                    <Input
                      name="utilityBill"
                      type="file"
                      classes=""
                      id="utilityBill"
                      accept=".png, .jpg, .jpeg, .pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                  <p className="format">
                    File format JPEG, PNG, PDF Size 3mb max
                  </p>
                  <Button
                    classes="secondary-btn mt-4"
                    content="Upload ID"
                    onClick={uploadFile}
                    type="button"
                    disabled={documentName !== 'utilityBill'}
                  />
                </div>
                {/* {uploading ? ( */}
                <div
                  style={{
                    opacity:
                      uploading && documentName === 'utilityBill' ? '1' : '0',
                    height:
                      uploading && documentName === 'utilityBill'
                        ? '100%'
                        : '0',
                    transition: 'all .2s',
                  }}
                  className="progress-stat"
                >
                  <div className="progress-bar">
                    <div
                      className="progress-bar-blue"
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                  <p style={{ paddingTop: '10px' }}>
                    {uploadProgress}% Uploaded
                  </p>
                </div>
                {/* ) : (
                  <p></p>
                )} */}
              </div>

              <div className="col-md-12">
                <h3 className="mt-5 mb-3">Company/Business Documents</h3>
                <li>It must be clear and legible</li>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-md-5 gap-0">
                  <div>
                    <div className="file-input my-4">
                      <div>
                        <Input
                          name="cacCertificate"
                          type="file"
                          classes=""
                          id="cacCertificate"
                          accept=".png, .jpg, .jpeg, .pdf"
                          onChange={handleFileChange}
                        />
                      </div>
                      <p className="format">
                        File format JPEG, PNG, PDF Size 3mb max
                      </p>
                      <Button
                        classes="secondary-btn mt-4"
                        content="Upload cac certificate"
                        onClick={uploadFile}
                        type="button"
                        disabled={documentName !== 'cacCertificate'}
                      />
                    </div>
                    <div
                      style={{
                        opacity:
                          uploading && documentName === 'cacCertificate'
                            ? '1'
                            : '0',
                        height:
                          uploading && documentName === 'cacCertificate'
                            ? '100%'
                            : '0',
                        transition: 'all .2s',
                      }}
                      className="progress-stat"
                    >
                      <div className="progress-bar">
                        <div
                          className="progress-bar-blue"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <p style={{ paddingTop: '10px' }}>
                        {uploadProgress}% Uploaded
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="file-input my-4">
                      <div>
                        <Input
                          name="seal"
                          type="file"
                          classes=""
                          id="seal"
                          accept=".png, .jpg, .jpeg, .pdf"
                          onChange={handleFileChange}
                        />
                      </div>
                      <p className="format">
                        File format JPEG, PNG, PDF Size 3mb max
                      </p>
                      <Button
                        classes="secondary-btn mt-4"
                        content="Upload company seal"
                        onClick={uploadFile}
                        type="button"
                        disabled={documentName !== 'seal'}
                      />
                    </div>
                    <div
                      style={{
                        opacity:
                          uploading && documentName === 'seal' ? '1' : '0',
                        height:
                          uploading && documentName === 'seal' ? '100%' : '0',
                        transition: 'all .2s',
                      }}
                      className="progress-stat"
                    >
                      <div className="progress-bar">
                        <div
                          className="progress-bar-blue"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <p style={{ paddingTop: '10px' }}>
                        {uploadProgress}% Uploaded
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-md-5 gap-0">
                  <div>
                    <div className="file-input my-4">
                      <div>
                        <Input
                          name="cac2"
                          type="file"
                          classes=""
                          id="cac2"
                          accept=".png, .jpg, .jpeg, .pdf"
                          onChange={handleFileChange}
                        />
                      </div>
                      <p className="format">
                        File format JPEG, PNG, PDF Size 3mb max
                      </p>
                      <Button
                        classes="secondary-btn mt-4"
                        content="Upload cac2 certificate"
                        onClick={uploadFile}
                        type="button"
                        disabled={documentName !== 'cac2'}
                      />
                    </div>
                    <div
                      style={{
                        opacity:
                          uploading && documentName === 'cac2' ? '1' : '0',
                        height:
                          uploading && documentName === 'cac2' ? '100%' : '0',
                        transition: 'all .2s',
                      }}
                      className="progress-stat"
                    >
                      <div className="progress-bar">
                        <div
                          className="progress-bar-blue"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <p style={{ paddingTop: '10px' }}>
                        {uploadProgress}% Uploaded
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="file-input my-4">
                      <div>
                        <Input
                          name="cac7"
                          type="file"
                          classes=""
                          id="cac7"
                          accept=".png, .jpg, .jpeg, .pdf"
                          onChange={handleFileChange}
                        />
                      </div>
                      <p className="format">
                        File format JPEG, PNG, PDF Size 3mb max
                      </p>
                      <Button
                        classes="secondary-btn mt-4"
                        content="Upload cac7 certificate"
                        onClick={uploadFile}
                        type="button"
                        disabled={documentName !== 'cac7'}
                      />
                    </div>
                    <div
                      style={{
                        opacity:
                          uploading && documentName === 'cac7' ? '1' : '0',
                        height:
                          uploading && documentName === 'cac7' ? '100%' : '0',
                        transition: 'all .2s',
                      }}
                      className="progress-stat"
                    >
                      <div className="progress-bar">
                        <div
                          className="progress-bar-blue"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <p style={{ paddingTop: '10px' }}>
                        {uploadProgress}% Uploaded
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="file-input my-4">
                    <div>
                      <Input
                        name="MERMAT"
                        type="file"
                        classes=""
                        id="MERMAT"
                        accept=".png, .jpg, .jpeg, .pdf"
                        onChange={handleFileChange}
                      />
                    </div>
                    <p className="format">
                      File format JPEG, PNG, PDF Size 3mb max
                    </p>
                    <Button
                      classes="secondary-btn mt-4"
                      content="Upload MERMAT"
                      onClick={uploadFile}
                      type="button"
                      disabled={documentName !== 'MERMAT'}
                    />
                  </div>
                  <div
                    style={{
                      opacity:
                        uploading && documentName === 'MERMAT' ? '1' : '0',
                      height:
                        uploading && documentName === 'MERMAT' ? '100%' : '0',
                      transition: 'all .2s',
                    }}
                    className="progress-stat"
                  >
                    <div className="progress-bar">
                      <div
                        className="progress-bar-blue"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <p style={{ paddingTop: '10px' }}>
                      {uploadProgress}% Uploaded
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <h3 className="mt-5 mb-3">Bank Statement Document</h3>
                <li>Statement of the last 12 month of account</li>
                <li>It must be clear and legible</li>
                <div className="file-input my-4">
                  <div>
                    <Input
                      name="bankStatement"
                      type="file"
                      classes=""
                      id="bankStatement"
                      accept=".png, .jpg, .jpeg, .pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                  <p className="format">
                    File format JPEG, PNG, PDF Size 3mb max
                  </p>
                  <Button
                    classes="secondary-btn mt-4"
                    content="Upload bank statement"
                    onClick={uploadFile}
                    type="button"
                    disabled={documentName !== 'bankStatement'}
                  />
                </div>
                <div
                  style={{
                    opacity:
                      uploading && documentName === 'bankStatement' ? '1' : '0',
                    height:
                      uploading && documentName === 'bankStatement'
                        ? '100%'
                        : '0',
                    transition: 'all .2s',
                  }}
                  className="progress-stat"
                >
                  <div className="progress-bar">
                    <div
                      className="progress-bar-blue"
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                  <p style={{ paddingTop: '10px' }}>
                    {uploadProgress}% Uploaded
                  </p>
                </div>
              </div>

              <div className="col-md-12">
                <h3 className="mt-5 mb-3">LPO/Proforma Documents</h3>
                <li>It must be clear and legible</li>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-md-5 gap-0">
                  <div>
                    <div className="file-input my-4">
                      <div>
                        <Input
                          name="lpoFile"
                          type="file"
                          classes=""
                          id="lpoFile"
                          accept=".png, .jpg, .jpeg, .pdf"
                          onChange={handleFileChange}
                        />
                      </div>
                      <p className="format">
                        File format JPEG, PNG, PDF Size 3mb max
                      </p>
                      <Button
                        classes="secondary-btn mt-4"
                        content="Upload LPO"
                        onClick={uploadFile}
                        type="button"
                        disabled={documentName !== 'lpoFile'}
                      />
                    </div>
                    <div
                      style={{
                        opacity:
                          uploading && documentName === 'lpoFile' ? '1' : '0',
                        height:
                          uploading && documentName === 'lpoFile'
                            ? '100%'
                            : '0',
                        transition: 'all .2s',
                      }}
                      className="progress-stat"
                    >
                      <div className="progress-bar">
                        <div
                          className="progress-bar-blue"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <p style={{ paddingTop: '10px' }}>
                        {uploadProgress}% Uploaded
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="file-input my-4">
                      <div>
                        <Input
                          name="proformaFile"
                          type="file"
                          classes=""
                          id="proformaFile"
                          accept=".png, .jpg, .jpeg, .pdf"
                          onChange={handleFileChange}
                        />
                      </div>
                      <p className="format">
                        File format JPEG, PNG, PDF Size 3mb max
                      </p>
                      <Button
                        classes="secondary-btn mt-4"
                        content="Upload Proforma invoice"
                        onClick={uploadFile}
                        type="button"
                        disabled={documentName !== 'proformaFile'}
                      />
                    </div>
                    <div
                      style={{
                        opacity:
                          uploading && documentName === 'proformaFile'
                            ? '1'
                            : '0',
                        height:
                          uploading && documentName === 'proformaFile'
                            ? '100%'
                            : '0',
                        transition: 'all .2s',
                      }}
                      className="progress-stat"
                    >
                      <div className="progress-bar">
                        <div
                          className="progress-bar-blue"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <p style={{ paddingTop: '10px' }}>
                        {uploadProgress}% Uploaded
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <h3 className="mt-5 mb-1">Passport/Selfie & Signature</h3>
                <div className="d-flex flex-column">
                  <div className="file-input my-4">
                    <div className="d-flex align-items-center gap-3 justify-content-center">
                      <h6>
                        <u>Take photo </u>or{' '}
                      </h6>
                      <Input
                        name="passport"
                        type="file"
                        classes=""
                        id="passport"
                        accept=".png, .jpg, .jpeg, .pdf"
                        onChange={handleFileChange}
                      />
                    </div>
                    <p className="format">
                      File format JPEG, PNG, PDF Size 3mb max
                    </p>
                    <Button
                      classes="secondary-btn mt-4"
                      content="Upload photo"
                      onClick={uploadFile}
                      type="button"
                      disabled={documentName !== 'passport'}
                    />
                  </div>
                  <div
                    style={{
                      opacity:
                        uploading && documentName === 'passport' ? '1' : '0',
                      height:
                        uploading && documentName === 'passport' ? '100%' : '0',
                      transition: 'all .2s',
                    }}
                    className="progress-stat"
                  >
                    <div className="progress-bar">
                      <div
                        className="progress-bar-blue"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <p style={{ paddingTop: '10px' }}>
                      {uploadProgress}% Uploaded
                    </p>
                  </div>

                  <div className="file-input my-4">
                    <div>
                      <Input
                        name="signature"
                        type="file"
                        classes=""
                        id="signature"
                        accept=".png, .jpg, .jpeg, .pdf"
                        onChange={handleFileChange}
                      />
                    </div>
                    <p className="format">
                      File format JPEG, PNG, PDF Size 3mb max
                    </p>
                    <Button
                      classes="secondary-btn mt-4"
                      content="Upload signature"
                      onClick={uploadFile}
                      type="button"
                      disabled={documentName !== 'signature'}
                    />
                  </div>
                  <div
                    style={{
                      opacity:
                        uploading && documentName === 'signature' ? '1' : '0',
                      height:
                        uploading && documentName === 'signature'
                          ? '100%'
                          : '0',
                      transition: 'all .2s',
                    }}
                    className="progress-stat"
                  >
                    <div className="progress-bar">
                      <div
                        className="progress-bar-blue"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <p style={{ paddingTop: '10px' }}>
                      {uploadProgress}% Uploaded
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <h3 className="mt-5 mb-3">Other Documents</h3>
                <label htmlFor="others">Document Name</label>
                <Input
                  name="othersName"
                  classes="form-control mb-4"
                  type="text"
                  id="othersName"
                  onChange={handleInputChange}
                  value={formDetails.othersName}
                />
                <li>It must be clear and legible</li>
                <div className="file-input my-4">
                  <div>
                    <Input
                      name="others"
                      type="file"
                      classes=""
                      id="others"
                      accept=".png, .jpg, .jpeg, .pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                  <p className="format">
                    File format JPEG, PNG, PDF Size 3mb max
                  </p>
                  <Button
                    classes="secondary-btn mt-4"
                    content="Upload document"
                    onClick={uploadFile}
                    type="button"
                    disabled={documentName !== 'others'}
                  />
                </div>
                <div
                  style={{
                    opacity: uploading && documentName === 'others' ? '1' : '0',
                    height:
                      uploading && documentName === 'others' ? '100%' : '0',
                    transition: 'all .2s',
                  }}
                  className="progress-stat"
                >
                  <div className="progress-bar">
                    <div
                      className="progress-bar-blue"
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                  <p style={{ paddingTop: '10px' }}>
                    {uploadProgress}% Uploaded
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="profile-btn">
                <Button
                  classes="primary-btn"
                  content="Previous"
                  onClick={() => {
                    previous();
                    setX(-1000);
                  }}
                />
                <Button classes="primary-btn" content="Submit" type="submit" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default observer(DocumentUploadForm);
