/* eslint-disable @typescript-eslint/no-explicit-any */
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../../firebase/Firebase';
import { FuctionBrowserLogger } from '../../common/Func_Logger';

//signin with email and password

export const LogIn = async (details: any) => {
  try {
    const response: any = await signInWithEmailAndPassword(
      auth,
      details.email,
      details.password,
    );
    try {
      FuctionBrowserLogger.prototype.info(
        `User | Log In | ${details.email}`,
        response,
      );
    } catch (err) {}

    return {
      error: false,
      data: response,
    };
  } catch (err: any) {
    try {
      FuctionBrowserLogger.prototype.error(
        `User | Log In | ${details.email}`,
        err,
      );
    } catch (err) {}

    return {
      error: true,
      data: err,
    };
  }
};

//Signout user
export const LogOut = async () => {
  try {
    try {
      FuctionBrowserLogger.prototype.info(
        `User | Log Out | ${auth.currentUser?.email}`,
        {},
      );
    } catch (err) {}
    await signOut(auth);
  } catch (error: any) {
    try {
      FuctionBrowserLogger.prototype.error(
        `User | Log Out | ${auth.currentUser?.email}`,
        {},
      );
    } catch (err) {}
  }
};
