/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiClose, mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import React, { FC, useContext, useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

type Props = {
  image: string;
  imageStyle?: any;
  heading?: string;
  body: string;
  route?: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  btnText3?: string;
  onClick?: () => void;
  DataObj?: any;
  AccountDetails?: any;
};

const strDate = new Date(new Date().setDate(1)).toISOString().substring(0, 10);
const stpDate = new Date().toISOString().substring(0, 10);

const RedirectModal: FC<Props> = ({
  image,
  imageStyle,
  heading,
  body,
  route,
  close,
  button,
  btnText1,
  btnText2,
  btnText3,
  onClick,
  DataObj,
  AccountDetails,
}) => {
  const { walletStore } = useContext(StoreContext);
  const { message } = walletStore;
  const [dateRange, setDateRange] = useState({
    startDate: strDate,
    endDate: stpDate,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setDateRange((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStatementProcess = () => {
    walletStore.generateTransactionStatement(
      dateRange.startDate,
      dateRange.endDate,
    );
  };

  const copyToClipboard = (data: string) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        toast.success('Copied to clipboard!');
      })
      .catch(() => {
        return;
      });
  };

  useEffect(() => {
    if (message.type === 'success') {
      if (close) {
        close();
      }
    }
  }, [message.type, message]);

  return (
    <div className="notification-modal">
      <div className="bg-white notification-box">
        <span className="close" onClick={close}>
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="Close"
          />
        </span>
        <img src={image} style={imageStyle} alt="No guarantor" />
        {heading && <h5 className="my-3">{heading}</h5>}
        <p>{body}</p>
        {DataObj &&
          DataObj?.length > 0 &&
          DataObj.map((data: any) => (
            <div className="w-100 gap-2 mt-4" key={data.walletIdAccountNumber}>
              <h6 className="wallet-deets d-flex align-items-start my-3 color-heading">
                Wallet Details 1
              </h6>
              <div className="d-flex align-items-center justify-content-between mb-3 wallet-deets">
                <h6>Bank</h6>
                <p>{data?.banker}</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3 wallet-deets">
                <h6>Account Name</h6>
                <p style={{ textTransform: 'capitalize' }}>
                  {data?.type === 'Personal'
                    ? AccountDetails?.firstName + ' ' + AccountDetails?.lastName
                    : AccountDetails?.organizationDetails
                    ? AccountDetails?.organizationDetails?.businessName
                    : AccountDetails?.firstName +
                      ' ' +
                      AccountDetails?.lastName}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3 wallet-deets">
                <h6>Account Number</h6>
                <span
                  onClick={() => copyToClipboard(data?.walletIdAccountNumber)}
                  className="copy-button"
                  role="button"
                  title="Copy"
                >
                  <span className="me-2">{data?.walletIdAccountNumber}</span>
                  <Icon path={mdiContentCopy} size={0.7} />
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3 wallet-deets">
                <h6>Account Type</h6>
                <p>{data?.type}</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3 wallet-deets">
                <h6>Daily Limit</h6>
                <p>
                  ₦{' '}
                  {data?.dailyTransactionLimit
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(data?.dailyTransactionLimit)
                    : '0.00'}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3 wallet-deets">
                <h6>Single Limit</h6>
                <p>
                  ₦{' '}
                  {data?.transactionLimit
                    ? new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(data.transactionLimit)
                    : '0.00'}
                </p>
              </div>
            </div>
          ))}
        ;
        {heading === 'E-Statement' && (
          <div className="w-100 gap-2 mt-4">
            <div className="d-flex align-items-center justify-content-between mb-3 wallet-deets">
              <div className="col-md-12">
                <label htmlFor="startDate" className="mb-1 mt-3 float-start">
                  Start date
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <Input
                  name="startDate"
                  classes="form-control"
                  type="date"
                  placeholder="YYYY-MM-DD"
                  max={stpDate}
                  onChange={handleInputChange}
                  defaultValue={dateRange.startDate}
                  required
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3 wallet-deets">
              <div className="col-md-12">
                <label htmlFor="endDate" className="mb-1 mt-3 float-start">
                  End date
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <Input
                  name="endDate"
                  classes="form-control"
                  type="date"
                  placeholder="YYYY-MM-DD"
                  max={new Date().toISOString().substring(0, 10)}
                  onChange={handleInputChange}
                  defaultValue={dateRange.endDate}
                  required
                />
              </div>
            </div>
          </div>
        )}
        {button && (
          <div className="group-btn">
            {btnText1 && (
              <Button
                classes="primary-btn w-auto btn-lg waves-effect"
                content={btnText1}
                route={route}
                onClick={onClick}
              />
            )}
            {btnText3 && (
              <Button
                classes="primary-btn w-auto btn-lg waves-effect"
                content={btnText3}
                onClick={handleStatementProcess}
              />
            )}
            {btnText2 && (
              <Button
                classes="secondary-btn w-auto btn-lg waves-effect"
                content={btnText2}
                onClick={close}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(RedirectModal);
