/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link, useLocation } from 'react-router-dom';
import { StoreContext } from '../../../mobx_stores/RootStore';

const GuarantorDetails = () => {
  const { guarantorStore } = useContext(StoreContext);

  const { loadingData, guarantor } = guarantorStore;

  const location: any = useLocation();

  useEffect(() => {
    guarantorStore.getGuarantor(location.state.id);
  }, [guarantorStore, location.state.id]);

  return (
    <div className="guarantor-details">
      <h4 className="section-head">Guarantors</h4>
      <Link
        className="mb-4 d-inline-block back-link"
        to="/dashboard/guarantors/all_guarantors"
      >
        <Icon path={mdiArrowLeft} size={1} className="search-icon" />
        Back
      </Link>

      <div className="bg-white white-box py-5">
        {loadingData || guarantor.firstName === undefined ? (
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5 pe-lg-5 pe-md-1">
              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>

              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>
              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>
              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>
              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-5 ps-lg-5 ps-md-1">
              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>
              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>
              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>
              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>
              <div className="detail">
                <h6>
                  <Skeleton width={120} height={25} />
                </h6>
                <p>
                  <Skeleton width={190} height={25} />
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5 pe-lg-5 pe-md-1">
              {/* {
                                guarantors.map
                            } */}
              <div className="detail">
                <h6>Full Name</h6>
                <p>{`${guarantor.title} ${guarantor.firstName} ${guarantor.lastName}`}</p>
              </div>

              <div className="detail">
                <h6>Gender</h6>
                <p>{guarantor.gender || 'Nil'}</p>
              </div>
              <div className="detail">
                <h6>Email address</h6>
                <p>{guarantor.email || 'Nil'}</p>
              </div>
              <div className="detail">
                <h6>Mobile number</h6>
                <p>{guarantor.phoneNumber || 'Nil'}</p>
              </div>
              <div className="detail">
                <h6>Address</h6>
                <p>{guarantor.address || 'Nil'}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-5 ps-lg-5 ps-md-1">
              <div className="detail">
                <h6>LGA</h6>
                <p>{guarantor.city || 'Nil'}</p>
              </div>
              <div className="detail">
                <h6>State</h6>
                <p>{guarantor.state || 'Nil'}</p>
              </div>
              <div className="detail">
                <h6>Country</h6>
                <p>{guarantor.country || 'Nil'}</p>
              </div>
              <div className="detail">
                <h6>Occcupation</h6>
                <p>{guarantor.occupation || 'Nil'}</p>
              </div>
              <div className="detail">
                <h6>Role in the company </h6>
                <p>{guarantor.roleInCompany || 'Nil'}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(GuarantorDetails);
