import { createContext } from 'react';
import { AuthStore } from './AuthStore';
import { GuarantorStore } from './GuarantorStore';
import { ProfileStore } from './ProfileStore';
import { LoansStore } from './LoansStore';
import { WalletStore } from './WalletStore';
import { InvestStore } from './InvestStore';
import { BillStore } from './BillStore';
import { BlogStore } from './BlogStore';
import { InvoiceStore } from './InvoiceStore';

interface StoreContextInterface {
  authStore: AuthStore;
  loansStore: LoansStore;
  guarantorStore: GuarantorStore;
  profileStore: ProfileStore;
  walletStore: WalletStore;
  investStore: InvestStore;
  billStore: BillStore;
  blogStore: BlogStore;
  invoiceStore: InvoiceStore;
}

const authStore = new AuthStore();
const loansStore = new LoansStore();
const guarantorStore = new GuarantorStore();
const profileStore = new ProfileStore();
const walletStore = new WalletStore();
const investStore = new InvestStore();
const billStore = new BillStore();
const blogStore = new BlogStore();
const invoiceStore = new InvoiceStore();

export const StoreContext = createContext<StoreContextInterface>({
  authStore,
  loansStore,
  guarantorStore,
  profileStore,
  walletStore,
  investStore,
  billStore,
  blogStore,
  invoiceStore,
});

export const SetAllAccessTokens = (token: string) => {
  loansStore.SetAccessToken(token);
  guarantorStore.SetAccessToken(token);
  authStore.SetAccessToken(token);
  profileStore.SetAccessToken(token);
  walletStore.SetAccessToken(token);
  investStore.SetAccessToken(token);
  billStore.SetAccessToken(token);
  invoiceStore.SetAccessToken(token);
};
