/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { StoreContext } from '../../mobx_stores/RootStore';
// import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { toast } from 'react-toastify';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import Spinner from '../../components/ui/Spinner';
import { useNavigate } from 'react-router';
import { runInAction } from 'mobx';
import { Helmet } from 'react-helmet-async';

const ForgotPassword = () => {
  const { authStore } = useContext(StoreContext);
  const { loading } = authStore;

  const [userDetails, setUserDetails] = useState({
    email: '',
  });

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setUserDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: element.value };
    });
  };

  const navigate = useNavigate();

  const handlePasswordReset = (e: any) => {
    e.preventDefault();
    authStore.ForgotPassword(userDetails);

    runInAction(() => {
      authStore.setSuccess = () => {
        navigate('/reset_password_successful', {
          state: { email: userDetails.email },
        });
      };
    });

    runInAction(() => {
      authStore.setError = (error: any) => {
        if (error.message === 'Firebase: Error (auth/user-not-found).') {
          toast.error('User not found');
          return;
        }
        if (error.message === 'Firebase: Error (auth/too-many-requests).') {
          toast.error('You have exceeded the limit of this request!');
          return;
        } else {
          toast.error(
            'We are unable to process your form submission at this time. Please try again later!',
          );
          return;
        }
      };
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Forgot Password | Request a password reset on Trade Lenda!
        </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <form action="" onSubmit={handlePasswordReset}>
        {loading && <Spinner />}

        <div className="forgot-password ">
          {/* <div className="pattern-group blue">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/blueangle.png`}
              alt=""
              style={{ width: '10%', margin: '0', right: '0' }}
            />
          </div> */}
          <div className="container">
            <div className="logo text-center mb-4">
              <img
                src={`${BaseDirectories.LOGOS_DIR}/Logo2.png`}
                alt=""
                // style={{ width: '15%' }}
                className="w-15"
              />
            </div>
            <div className="card h-100 p-4">
              {/* <img
                src={`${BaseDirectories.IMAGES_DIR}/locked.png`}
                alt=""
                style={{ width: '10%', margin: '10px auto 20px auto' }}
              /> */}
              <h2>Forgot Password?</h2>
              <p>
                Please enter your email address to recieve a password reset link
              </p>
              <hr />
              <div className="col-12 mb-2 email-input">
                <label htmlFor="email" className="col-12  mb-1 mt-1">
                  Email Address
                </label>
                <Input
                  name="email"
                  classes="form-control-sm col-12"
                  type="text"
                  id="email"
                  onChange={handleInputChange}
                  placeholder="Default"
                />

                <Button
                  content={loading ? 'Sending...' : 'Submit'}
                  classes="primary-btn btn-md col-12 mb-5 mt-3"
                  type="submit"
                />
              </div>
            </div>
          </div>
          {/* <div className="pattern-group green">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/greenangle.png`}
              alt=""
              style={{ width: '10%' }}
            />
          </div> */}
        </div>
      </form>
    </>
  );
};

export default ForgotPassword;
