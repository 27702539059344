/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { StoreContext } from '../../../ mobx stores/RootStore';
import Spinner from '../../../components/ui/Spinner';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { MaterialReactTable } from 'material-react-table';
import { MRT_ColumnDef } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button } from '@mui/material';
import moment from 'moment';

interface LocationState {
  [key: string]: any;
}

const TransactionDetails = () => {
  const location: LocationState = useLocation();
  const { investStore } = useContext(StoreContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [dataSet, setDataSet] = useState(null);

  useEffect(() => {
    investStore.getTransactions(location?.state?.MembershipId);
  }, [location]);

  useEffect(() => {
    investStore.setTransactions;
    setTransactionDetails(toJS(investStore.transactionsDetail));
    if (toJS(investStore.transactionsDetail)) {
      const filteredColumns = toJS(investStore.transactionsDetail).map(
        (item: any) => {
          return {
            createdAt: moment(item.createdAt).format('MM-D-YYYY'),
            investmentAmount: item.investmentAmount,
            transactionType: item.transactionType,
            processStatus:
              item.processStatus === 'WEBHOOK_SUCCESSFUL'
                ? 'Successful'
                : 'Pending',
            userDebitTransactionReference: item.userDebitTransactionReference,
          };
        },
      );
      setDataSet(filteredColumns);
    }
  }, [location]);

  useEffect(() => {
    investStore.setTransactions;
    setTransactionDetails(toJS(investStore.transactionsDetail));
    if (toJS(investStore.transactionsDetail)) {
      const filteredColumns = toJS(investStore.transactionsDetail).map(
        (item: any) => {
          return {
            createdAt: moment(item.createdAt).format('MM-D-YYYY'),
            investmentAmount: item.investmentAmount,
            transactionType: item.transactionType,
            processStatus:
              item.processStatus === 'WEBHOOK_SUCCESSFUL'
                ? 'Successful'
                : 'Pending',
            userDebitTransactionReference: item.userDebitTransactionReference,
          };
        },
      );
      setDataSet(filteredColumns);
    }
  }, [investStore.transactionsDetail]);

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: 'createdAt',
        id: 'createdAt',
        header: 'Transaction Date',
        Cell: ({ cell }) => (
          <span>{moment(cell.getValue<number>()).format('MM-D-YYYY')}</span>
        ),
      },
      {
        accessorKey: 'investmentAmount',
        id: 'Amount',
        header: 'Amount',
        Cell: ({ cell }) => (
          <span>
            ₦{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(cell.getValue<number>())}
          </span>
        ),
      },
      {
        accessorKey: 'transactionType',
        id: 'transactionType',
        header: 'Transaction Type',
        Cell: ({ cell }) => (
          <span>{cell.getValue<string>().toUpperCase()}</span>
        ),
      },
      {
        accessorKey: 'processStatus',
        id: 'processStatus',
        header: 'Status',
        Cell: ({ cell }) => (
          <span>
            {cell.getValue() === 'WEBHOOK_SUCCESSFUL'
              ? 'Successful'
              : 'Pending'}
          </span>
        ),
      },
      {
        accessorKey: 'userDebitTransactionReference',
        id: 'TransactionReference',
        header: 'Transaction Reference',
      },
    ],
    [],
  );

  const handleExportData = () => {
    const csvOptions = {
      filename: 'InvestmentSummary',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(dataSet);
  };

  return (
    <div className="loans">
      {investStore.loadingPlans && <Spinner />}

      <div className="section-head">Investment Details</div>
      <div className="loans-container">
        <Link to="" onClick={() => window.history.back()}>
          <h4 className="h3-back">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              style={{ marginRight: '1rem' }}
            />
            Back
          </h4>
        </Link>

        <div className="rq-loan-details">
          <h5>Investment Transaction Details</h5>
          {toJS(investStore.transactionsDetail) !== null && (
            <MaterialReactTable
              columns={columns}
              data={toJS(investStore.transactionsDetail)}
              positionGlobalFilter="right"
              globalFilterFn="contains"
              positionToolbarAlertBanner="bottom"
              enableRowNumbers
              rowNumberMode="static" //default
              initialState={{
                columnVisibility: { required: false, description: false },
                density: 'spacious',
                showGlobalFilter: true,
                // sorting: [
                //   { id: 'required', desc: true },
                //   { id: 'columnOption', desc: false },
                // ],
              }}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    p: '0.5rem',
                    // flexWrap: 'wrap',
                  }}
                >
                  {dataSet !== null ? (
                    <Button
                      color="primary"
                      onClick={handleExportData}
                      startIcon={<FileDownloadIcon />}
                      variant="contained"
                    >
                      Export
                    </Button>
                  ) : (
                    <Button
                      disabled
                      color="primary"
                      onClick={handleExportData}
                      startIcon={<FileDownloadIcon />}
                      variant="contained"
                    >
                      Export
                    </Button>
                  )}
                </Box>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(TransactionDetails);
