/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router';
import { StoreContext } from '../../../../ mobx stores/RootStore';
import Button from '../../../../components/ui/Button';

type Props = {
  next: () => void;
  previous: any;
  x: number;
  setX: Function;
};

const OtpValidation: FC<Props> = ({ next, previous, x, setX }) => {
  const { guarantorStore } = useContext(StoreContext);
  const [otpCode, setOtpCode] = useState('');
  const [otp, setOtp] = useState({
    otpCode,
  });

  const { submitting, resending, message } = guarantorStore;
  const location: any = useLocation();

  useEffect(() => {
    setOtp({
      otpCode: otpCode,
    });
  }, [otpCode]);

  const handleSubmitOtp = (e: FormEvent) => {
    e.preventDefault();

    guarantorStore.verifyOtp(otp, next, location);
  };

  const handleResendOtp = (e: FormEvent) => {
    e.preventDefault();

    guarantorStore.resendOtp({});
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-lg-7 col-xl-5 col-md-8 col-sm-10">
        <div className="bg-white add-card-box p-sm-5 p-4">
          <h4 className="guarantor-form-heading mb-2">OTP</h4>
          <p className="mb-0">
            Enter the 6 digit number that was sent to your Phone
          </p>

          <form onSubmit={handleSubmitOtp}>
            <div className="row">
              <div className="col-md-12 input-box">
                <div>
                  <OtpInput
                    value={otpCode}
                    onChange={setOtpCode}
                    numInputs={6}
                    inputStyle="input-style"
                    containerStyle="container-style"
                    inputType="tel"
                    shouldAutoFocus={true}
                    renderInput={(props) => (
                      <input onKeyPress={validateNumber} {...props} />
                    )}
                  />
                </div>
              </div>
            </div>
            {message.msg !== '' && (
              <div
                className={
                  message.type === 'success'
                    ? ' alert alert-success'
                    : 'alert alert-danger'
                }
                role="alert"
              >
                {message.msg}
              </div>
            )}
            <Button
              classes={`${
                submitting
                  ? 'primary-btn-disabled btn-lg btn-block w-100 px-4'
                  : 'primary-btn btn-lg btn-block w-100 px-4'
              }`}
              content={`${submitting ? 'Validating...' : 'Validate'}`}
              disabled={submitting}
            />
          </form>
          <p className="mt-3 text-center">
            I didn’t recieve an OTP{' '}
            <button className="resend-otp" onClick={handleResendOtp}>{`${
              resending ? 'Resending...' : 'Resend SMS'
            }`}</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(OtpValidation);
