import React from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';

const BlueCard = () => {
  return (
    <section className="position-relative blue-card">
      <div className="ss-bluecard-contained">
        <div
          className="blue-card-container "
          // style={{
          //   background: `url(${BaseDirectories.IMAGES_DIR}/pattern.png), url(${BaseDirectories.IMAGES_DIR}/pattern.png), linear-gradient(114.44deg, #010c4d 0%, #0384ff 100%)`,
          // }}
        >
          <div className="blue-card-cont-inner-right">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/iphone2.png`}
              alt="Trade Lenda"
              className="cmin-sn-img"
            />
          </div>
          <div className="blue-card-cont-inner">
            <h1>
              download our <br /> mobile App <br />
            </h1>
            <p className="mb-3">
              Create an account. Follow the directions on your screen. Access
              funding within 6 hours.
            </p>
            <div className="ss-appstore">
              <a
                href="https://apps.apple.com/us/app/trade-lenda/id6447363171"
                target="blank"
              >
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/appleStoreImage.png`}
                  alt="Trade Lenda"
                  className="ss-appst-img"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.tradelenda.app&pcampaignid=web_share"
                target="blank"
              >
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/googleplay.png`}
                  alt="Trade Lenda"
                  className="ss-appst-img"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlueCard;
