/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react';
import ScrollToTop from '../../../../components/ScrollToTop';
import { motion } from 'framer-motion';
import DocumentUploadForm from '../../../../components/forms/DocumentUploadForm';

interface IDocumentUploadProps {
  previous: any;
  x: number;
  setX: Function;
}

const DocumentUpload: React.FunctionComponent<IDocumentUploadProps> = ({
  previous,
  x,
  setX,
}) => {
  return (
    <ScrollToTop>
      <motion.div
        initial={{ x: x }}
        transition={{ duration: 0.5 }}
        animate={{ x: 0 }}
        className=""
      >
        <div className="bg-white">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 col-md-9 col-sm-10 bg-primary-blue mt-4 rounded">
              <h4>DOCUMENT UPLOAD</h4>
              <p>
                Industry regulation requires us to collect this information to
                verify your identity.
              </p>
            </div>
          </div>
          <DocumentUploadForm previous={previous} setX={setX} />
        </div>
      </motion.div>
    </ScrollToTop>
  );
};

export default DocumentUpload;
