/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../ mobx stores/RootStore';
import ProfileMultistepForm from '../authentication/profile/ProfileMultistepForm';
import Header from './layout/Header';
import Main from './layout/Main';
import Sidenav from './layout/Sidenav';
import Spinner from '../../components/ui/Spinner';
import { LogOut } from '../../utils/firebase/AuthFirestore';
import { toJS } from 'mobx';
import { connect } from 'react-redux';
import { setLoanDetails } from '../../redux/loanDetail/loan.details.action';
import { useDispatch } from 'react-redux';
import { persistor } from '../../redux';
import { logout } from '../../redux/auth/auth.action';
import { clearReduxStateAction } from '../../redux/root-reducer';

const Dashboard = (props: any) => {
  const [isRegistered, setIsRegistered] = useState(
    true || window.sessionStorage.getItem('userStatus') === 'true',
  );

  const [isProfileExist, setIsProfileExist] = useState(null);
  const [isProfileProgress, setIsProfileProgress] = useState('');

  //Profile store context
  const { profileStore, walletStore, loansStore, billStore } =
    useContext(StoreContext);

  const { profile } = profileStore;
  const { loadingWallet, loadingData, submitting, walletError } = walletStore;
  const [loading, setLoading] = useState(true);
  const [orgDeets, setOrgDeets] = useState<any>([]);
  const [userEmail, setUserEmail] = useState({
    username: '',
    domain: '',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const profileData = window.sessionStorage.getItem('user');
      const itemObject = profileData ? JSON.parse(profileData) : null;
      setIsProfileExist(itemObject?.title);
      setIsProfileProgress(itemObject?.profileProgress);
      setUserEmail(itemObject?.email.split('@'));
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (err) {
      setLoading(false);
    }
  }, [isProfileExist]);

  useEffect(() => {
    setTimeout(() => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setIsRegistered(
          JSON.parse(window.sessionStorage.getItem('isRegistered') || ''),
        );
      } catch (err) {}
    }, 3000);
  }, [profileStore]);

  useEffect(() => {
    if (isRegistered && isProfileExist !== 'User | Get Profile | undefined') {
      try {
        walletStore.getWallet();
        walletStore.getAllWalletWithoutLoading();
      } catch (err) {}
    }
  }, [walletStore]);

  useEffect(() => {
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  useEffect(() => {
    dispatch(setLoanDetails(toJS(loansStore.loanUserdetails)));
    setOrgDeets(toJS(loansStore.loanUserdetails));
  }, [loansStore.loanUserdetails]);

  useEffect(() => {
    if (window.location.pathname.includes('sign_up')) {
      navigate('/dashboard/overview');
    }
  }, [window.location.pathname]);

  useEffect(() => {
    let activityTimeout = setTimeout(handleLogOut, 1800000); // log out after 30 minutes of inactivity
    document.addEventListener('mousemove', resetTimeout);
    document.addEventListener('keypress', resetTimeout);
    return () => {
      clearTimeout(activityTimeout);
      document.removeEventListener('mousemove', resetTimeout);
      document.removeEventListener('keypress', resetTimeout);
    };

    function resetTimeout() {
      clearTimeout(activityTimeout);
      activityTimeout = setTimeout(handleLogOut, 1800000); // log out after 30 minutes of inactivity
    }
    async function handleLogOut() {
      setLoading(true);
      await LogOut();
      dispatch(clearReduxStateAction());
      window.location.href = '/login';
      setTimeout(async () => {
        setLoading(false);
        dispatch(logout());
        persistor.pause();
        await persistor.flush().then(async () => {
          return await persistor.purge();
        });
        window.location.reload();
      }, 1000);
    }
  }, []);

  return (
    <div>
      {(loading &&
        isProfileProgress &&
        isProfileExist != null &&
        isProfileProgress != null &&
        isProfileExist !== '') ||
      loadingWallet ||
      loadingData ||
      submitting ||
      billStore.loadingData ? (
        <Spinner />
      ) : null}

      {(isProfileProgress === '' &&
        isProfileExist == 'User | Get Profile | undefined') ||
      (isProfileProgress == null &&
        isProfileExist == 'User | Get Profile | undefined') ? (
        <ProfileMultistepForm />
      ) : (
        <div className="d-flex">
          <Sidenav />
          <div className="right-content">
            {!profile.bvn ||
              (!profile.nin && (
                <div className="banner text-center">
                  <p>
                    Please ensure your NIN and BVN are filled in the personal
                    details form.
                    <Link to="/dashboard/settings/account_settings">
                      Go to account settings
                    </Link>
                    .
                  </p>
                </div>
              ))}

            {toJS(walletError) && toJS(walletError)?.error && (
              <div className="banner text-center">
                <p>
                  {toJS(walletError)?.message}{' '}
                  <Link to="/dashboard/settings/account_settings">
                    Go to account settings
                  </Link>
                  .
                </p>
              </div>
            )}
            <Header />
            <div className="right-content-container">
              <Main />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loanDetails: state.loanDetails,
  };
};

export default connect(mapStateToProps)(observer(Dashboard));
