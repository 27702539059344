/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import Input from '../../../../components/ui/Input';
import { StoreContext } from '../../../../ mobx stores/RootStore';
import Spinner from '../../../../components/ui/Spinner';
import Button from '../../../../components/ui/Button';

type Props = {
  route?: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  onClick?: () => void;
  dataObj?: any;
  investmentData?: any;
  portfolioData?: any;
  setOpenOtpModal: (state: any) => void;
};

type Params = {
  amount: string;
  otp: number;
};

const RedemptionModal: FC<Props> = ({
  onClick = () => {
    setOpenOtpModal(false);
  },
  investmentData,
  setOpenOtpModal,
}) => {
  const [details, setDetails] = useState<Params>({
    amount: '',
    otp: 0,
  });
  const { investStore } = useContext(StoreContext);
  const { message, submittingInvestment, submitting } = investStore;
  const navigate = useNavigate();

  const updateData = (e: any) => {
    const element = e.target as HTMLInputElement;
    setDetails((prevDetails: any) => {
      return {
        ...prevDetails,
        [element.name]: element.value,
      };
    });
  };

  const handleSubmit = () => {
    if (details.otp.toString().length < 6) {
      toast.error('Invalid OTP number, please check and try again');
    }

    const redemptionParams = {
      id: investmentData?._id,
      otp: details?.otp?.toString(),
    };
    investStore.redeemLendaInvestment(redemptionParams);
  };

  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Investment redeemption successful.'
    ) {
      setTimeout(() => {
        onClick();
        navigate('/dashboard/invest');
      }, 2000);
    }
  }, [submittingInvestment, message]);

  const handleOTP = () => {
    const param = {
      id: investmentData._id,
    };
    investStore.getLendaOTP(param);
  };

  return (
    <div className="notification-modal">
      {submitting || submittingInvestment ? <Spinner /> : <></>}
      <div
        className="bg-white notification-box"
        style={{
          textAlign: 'left',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          fontSize: '20px',
          alignContent: 'flex-start',
          display: 'flex',
          padding: '40px',
        }}
      >
        <span
          onClick={() => {
            setOpenOtpModal(false);
          }}
          className="close"
          role="button"
        >
          <FaTimes />
        </span>
        <h5
          className="my-2"
          style={{
            fontSize: '20px',
          }}
        >
          Redeemable Amount
        </h5>
        <div className="input-group">
          <div className="input-group-prepend">
            <span
              className="input-group-text bg-white"
              style={{
                borderRight: '0',
                borderTopRightRadius: '0',
                borderBottomRightRadius: '0',
              }}
            >
              ₦
            </span>
          </div>
          <Input
            style={{ borderLeft: '0' }}
            name="amount"
            classes="form-control "
            type="text"
            id="amount"
            value={
              investmentData?.investmentAmount
                ? new Intl.NumberFormat('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(investmentData?.investmentAmount)
                : '0.00'
            }
            disabled
          />
        </div>
        <p className="mx-auto">
          Available Balance = ₦{' '}
          {investmentData?.totalReturn
            ? new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(investmentData?.totalReturn)
            : '0.00'}
        </p>
        <h5
          className="my-2"
          style={{
            fontSize: '20px',
          }}
        >
          OTP
        </h5>
        <Input
          name="otp"
          classes="form-control"
          type="number"
          id="otp"
          onChange={updateData}
        />
        <div className="group-btn">
          {details.otp.toString().length >= 6 ? (
            <Button
              classes="primary-btn w-auto btn-lg waves-effect"
              content="Get OTP"
              type="submit"
              disabled={true}
            />
          ) : (
            <Button
              classes="primary-btn w-auto btn-lg waves-effect"
              content="Get OTP"
              type="submit"
              onClick={() => {
                handleOTP();
              }}
            />
          )}

          {details.otp.toString().length === 6 ? (
            <Button
              classes="primary-btn w-auto btn-lg waves-effect"
              content="Redeem Investment"
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
            />
          ) : (
            <Button
              classes="primary-btn w-auto btn-lg waves-effect"
              content="Redeem Investment"
              type="submit"
              disabled
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(RedemptionModal);
