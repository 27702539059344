/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import { FC, FormEvent, useContext } from 'react';
import Button from '../../../../components/ui/Button';
import 'react-phone-number-input/style.css';
import { StoreContext } from '../../../../ mobx stores/RootStore';
import { observer } from 'mobx-react-lite';

type Props = {
  next: () => void;
  x: number;
  setX: Function;
};

const PhoneNumberValidation: FC<Props> = ({ next, x, setX }) => {
  const { guarantorStore } = useContext(StoreContext);

  const { submitting, message } = guarantorStore;

  const handleSendOtp = (e: FormEvent) => {
    e.preventDefault();

    guarantorStore.sendOtp({}, next);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-lg-7 col-xl-5 col-md-8 col-sm-10">
        <div className="bg-white add-card-box p-sm-5 p-4">
          <h4 className="guarantor-form-heading mb-2">
            Phone Number Validation
          </h4>
          <p>We would like to validate your Phone Number</p>

          <form onSubmit={handleSendOtp}>
            {message.msg !== '' && (
              <div
                className={
                  message.type === 'success'
                    ? ' alert alert-success'
                    : 'alert alert-danger'
                }
                role="alert"
              >
                {message.msg}
              </div>
            )}
            <Button
              classes={`${
                submitting
                  ? 'primary-btn-disabled btn-lg btn-block w-100 px-4'
                  : 'primary-btn btn-lg btn-block w-100 px-4'
              }`}
              content={`${submitting ? 'Sending OTP...' : 'Get OTP'}`}
              disabled={submitting}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default observer(PhoneNumberValidation);
