/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { configure, makeAutoObservable, runInAction, toJS } from 'mobx';
import BaseDirectories from '../base directories/BaseDirectories';
import { BrowserLogger } from '../common/logger/Logger';

configure({ enforceActions: 'always' });

type GuarantorData = {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender?: string;
  dob?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  occupation?: string;
  roleInCompany?: string;
};

type Message = {
  type: string;
  msg: any;
};

type GuarantorDetails = {
  [key: string]: any;
};

type Profile = {
  [key: string]: any;
};

export class GuarantorStore {
  loadingData = false;
  submitting = false;
  resending = false;
  guarantors = [];
  guarantor: GuarantorDetails = {};
  token: any = sessionStorage.getItem('accessToken') || '';
  urlParams: any = '';
  message: Message = {
    type: '',
    msg: '',
  };
  error = {
    type: 'error',
    msg: '',
  };
  success = {
    type: 'success',
    msg: '',
  };

  private logger!: BrowserLogger;
  private userProfile!: Profile;

  constructor() {
    makeAutoObservable(this);
    runInAction(() => {
      this.logger = new BrowserLogger(this.constructor.name);
      this.userProfile = JSON.parse(
        sessionStorage.getItem('user') || '{}',
      ) as Profile;
    });
  }

  createGuarantor(data: GuarantorData) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };

    if (Object.values(data).filter((d: any) => d === '').length === 0) {
      this.setSubmitting(true);
      axios
        .post(`${BaseDirectories.API_BASE_URL}/sureties/create`, data, {
          headers,
        })
        .then((res: any) => {
          this.logger.info(
            `User | Create Guarantor | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          data = {
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
          };
          this.setMessage('success', 'Guarantor successfully created!');
          setTimeout(() => {
            this.setMessage('', '');
          }, 5000);
          this.setSubmitting(false);
        })
        .catch((err) => {
          this.logger.error(
            `User | Create Guarantor | ${toJS(this.userProfile?.email)}`,
            err,
          );
          data = {
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            gender: '',
            dob: '',
            address: '',
            city: '',
            state: '',
            country: 'Nigeria',
            occupation: '',
            roleInCompany: '',
          };
          this.setMessage('error', err.response.data.message);
          setTimeout(() => {
            this.setMessage('', '');
          }, 6000);
          this.setSubmitting(false);
        });
    } else {
      this.logger.error(
        `User | Create Guarantor | ${toJS(this.userProfile?.email)}`,
        data,
      );
      this.setMessage('error', 'Some fields are empty!');
      setTimeout(() => {
        this.setMessage('', '');
      }, 4000);
    }
  }

  updateGuarantor(data: GuarantorData, id: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${
        window.location.pathname.split('/')[3].split('class')[0]
      }`,
      'Content-Type': 'application/json',
    };

    if (Object.values(data).filter((d: any) => d === '').length === 0) {
      this.setSubmitting(true);
      axios
        .put(
          `${BaseDirectories.API_BASE_URL}/sureties/update-surety/${id}`,
          data,
          { headers },
        )
        .then((res: any) => {
          this.logger.info(
            `User | Update Guarantor | ${toJS(this.userProfile?.email)}`,
            res.data,
          );
          data = {
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            gender: '',
            dob: '',
            address: '',
            city: '',
            state: '',
            country: 'Nigeria',
            occupation: '',
            roleInCompany: '',
          };
          this.setMessage('success', 'Information verified successfully!');
          setTimeout(() => {
            this.setMessage('', '');
          }, 4000);
          this.setSubmitting(false);
        })
        .catch((err) => {
          this.logger.error(
            `User | Update Guarantor | ${toJS(this.userProfile?.email)}`,
            err,
          );
          data = {
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            gender: '',
            dob: '',
            address: '',
            city: '',
            state: '',
            country: 'Nigeria',
            occupation: '',
            roleInCompany: '',
          };
          this.setMessage('error', err.response.data.message);
          setTimeout(() => {
            this.setMessage('', '');
          }, 6000);
          this.setSubmitting(false);
        });
    } else {
      this.logger.error(
        `User | Update Guarantor | ${toJS(this.userProfile?.email)}`,
        data,
      );
      this.setMessage('error', 'Some fields are empty!');
      setTimeout(() => {
        this.setMessage('', '');
      }, 4000);
    }
  }

  getGuarantors() {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/sureties`, { headers })
      .then((res: any) => {
        this.setLoading(false);
        this.setGuarantors(res.data);
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  getGuarantor(id: string) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
    this.setLoading(true);
    axios
      .get(`${BaseDirectories.API_BASE_URL}/sureties/get/${id}`, { headers })
      .then((res: any) => {
        this.setLoading(false);
        this.setGuarantor(res.data);
      })
      .catch((err) => {
        this.setLoading(false);
        this.setError('Error');
      });
  }

  sendOtp(data: any, next: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${
        window.location.pathname.split('/')[3].split('class')[0]
      }`,
      'Content-Type': 'application/json',
    };

    this.setSubmitting(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/sureties/send-otp`,
        {},
        { headers },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Send OTP | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setMessage('success', res.data.message);
        setTimeout(() => {
          this.setMessage('', '');
          next();
        }, 4000);
        this.setSubmitting(false);
      })
      .catch((err) => {
        this.logger.error(
          `User | Send OTP | ${toJS(this.userProfile?.email)}`,
          err,
        );
        this.setMessage('error', err.response?.data.message || err.message);
        setTimeout(() => {
          this.setMessage('', '');
        }, 6000);
        this.setSubmitting(false);
      });
  }

  resendOtp(data: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${
        window.location.pathname.split('/')[3].split('class')[0]
      }`,
      'Content-Type': 'application/json',
    };

    this.setResending(true);
    axios
      .post(
        `${BaseDirectories.API_BASE_URL}/sureties/send-otp`,
        {},
        { headers },
      )
      .then((res: any) => {
        this.logger.info(
          `User | Resend OTP | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setMessage('success', res.data.message);
        setTimeout(() => {
          this.setMessage('', '');
        }, 5000);
        this.setResending(false);
      })
      .catch((err) => {
        this.logger.error(
          `User | Resend OTP | ${toJS(this.userProfile?.email)}`,
          err,
        );
        this.setMessage('error', err.response.data.message);
        setTimeout(() => {
          this.setMessage('', '');
        }, 6000);
        this.setResending(false);
      });
  }

  verifyOtp(data: any, next: any, location: any) {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${
        window.location.pathname.split('/')[3].split('class')[0]
      }`,
      'Content-Type': 'application/json',
    };

    this.setSubmitting(true);
    axios
      .post(`${BaseDirectories.API_BASE_URL}/sureties/verify-otp`, data, {
        headers,
      })
      .then((res: any) => {
        this.logger.info(
          `User | Verify OTP | ${toJS(this.userProfile?.email)}`,
          res.data,
        );
        this.setMessage('success', 'OTP validated successfully.');
        setTimeout(() => {
          this.setMessage('', '');
          next();
        }, 4000);
        location.state = res.data;
        const urlParams = new URLSearchParams(window.location.search);

        urlParams.set('order', res.data);
        this.urlParams = urlParams;
        // window.location.search = urlParams;
        this.setSubmitting(false);
      })
      .catch((err) => {
        this.logger.error(
          `User | Resend OTP | ${toJS(this.userProfile?.email)}`,
          err,
        );
        this.setMessage('error', err.response.data.message);
        setTimeout(() => {
          this.setMessage('', '');
        }, 6000);
        this.setSubmitting(false);
      });
  }

  setGuarantors = (res: any) => {
    this.guarantors = res;
  };

  setGuarantor = (res: any) => {
    this.guarantor = res;
  };

  setLoading = (val: boolean) => {
    this.loadingData = val;
  };

  setSubmitting = (val: boolean) => {
    this.submitting = val;
  };

  setResending = (val: boolean) => {
    this.resending = val;
  };

  setMessage = (type: string, msg: string) => {
    this.message.type = type;
    this.message.msg = msg;
  };

  setError = (err: string) => {
    this.error.msg = err;
  };

  setSuccess = (msg: string) => {
    this.success.msg = msg;
  };

  SetAccessToken = (token: string) => {
    this.token = token || sessionStorage.getItem('accessToken');
  };

  setToLocalStorage = (key: string, value: any) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  };

  clearFromLocalStorage = () => {
    window.sessionStorage.clear();
  };
}
