/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import { FaAsterisk } from 'react-icons/fa';
import Icon from '@mdi/react';
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiEyeSettingsOutline,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js';
import 'react-tabs/style/react-tabs.scss';
import UpdateARMDetails from './UpdateARMDetails';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import SaveWithArm from './SaveWithArm';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import InvestWithTradelenda from './lenda/InvestWithTradelenda';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InvestOverview = (props: any) => {
  const [showBalance, setShowBalance] = useState(false);
  const [showArmModal, setShowArmModal] = useState(false);
  const [showArmProfileModal, setShowArmProfileModal] = useState(false);
  const [showInvestWithTradelendaModal, setShowInvestWithTradelendaModal] =
    useState(false);
  const { investStore, profileStore } = useContext(StoreContext);
  const { userLendaInvestments, userInvestments } = investStore;
  const { profile } = profileStore;

  const { loanDetails } = props;
  const { armUserBankDetails, loanDocumentDetails, nextOfKinDetails } =
    loanDetails.currentLoanDetails;

  const [isOpen, setIsOpen] = React.useState(false);
  const [headerText, setHeaderText] = React.useState('');

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  function activeHeader(head: any) {
    setHeaderText(head.target.innerHTML);
    setIsOpen(!isOpen);
  }

  const [screen, setScreen] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(screen > 767 ? false : true);

  useEffect(() => {
    function handleResize() {
      setScreen(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    if (screen > 767) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [screen]);

  useEffect(() => {
    investStore.getInvestments();
    investStore.setArmInvestments;
  }, []);

  useEffect(() => {
    investStore.getUsersInvestment();
  }, []);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  useEffect(() => {
    investStore.getUserLenderInvestments();
    investStore.setUserLendaInvestments;
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      investStore.getUsersInvestmentWithoutReload();
      investStore.setUserLendaInvestments;
    }, 100000);
    return () => clearInterval(interval);
  }, []);
  const handleArm = () => {
    if (toJS(profile)?._id != null) {
      const isReadyToInvest =
        armUserBankDetails &&
        nextOfKinDetails &&
        loanDocumentDetails &&
        armUserBankDetails != null &&
        loanDocumentDetails != null &&
        nextOfKinDetails != null &&
        armUserBankDetails?.annualExpectedAnnualIncomeRange != undefined &&
        armUserBankDetails?.annualExpectedAnnualIncomeRange != '' &&
        armUserBankDetails?.bankAccountName != undefined &&
        armUserBankDetails?.bankAccountName != '' &&
        armUserBankDetails?.bankAccountNumber != undefined &&
        armUserBankDetails?.bankAccountNumber != '' &&
        armUserBankDetails?.bankCode != undefined &&
        armUserBankDetails?.bankCode != '' &&
        armUserBankDetails?.bankName != undefined &&
        armUserBankDetails?.bankName != '' &&
        armUserBankDetails?.branchCode != undefined &&
        armUserBankDetails?.branchCode != '' &&
        armUserBankDetails?.employmentStatus != undefined &&
        armUserBankDetails?.employmentStatus != '' &&
        armUserBankDetails?.expiryDateOfId != undefined &&
        armUserBankDetails?.expiryDateOfId != '' &&
        armUserBankDetails?.idType != undefined &&
        armUserBankDetails?.idType != '' &&
        armUserBankDetails?.kycLevel != undefined &&
        armUserBankDetails?.kycLevel != '' &&
        armUserBankDetails?.maximumSingleInvestmentAmount != undefined &&
        armUserBankDetails?.maximumSingleInvestmentAmount != '' &&
        armUserBankDetails?.maximumSingleRedemptionAmount != undefined &&
        armUserBankDetails?.maximumSingleRedemptionAmount != '' &&
        armUserBankDetails?.reInvestDividends != undefined &&
        armUserBankDetails?.reInvestDividends != '' &&
        armUserBankDetails?.utilityBillIdType != undefined &&
        armUserBankDetails?.utilityBillIdType != null &&
        armUserBankDetails?.utilityBillIdType != '' &&
        loanDocumentDetails?.identityCard != undefined &&
        loanDocumentDetails?.identityCard != null &&
        loanDocumentDetails?.identityCard != '' &&
        loanDocumentDetails?.utilityBill != undefined &&
        loanDocumentDetails?.utilityBill != null &&
        loanDocumentDetails?.utilityBill != '' &&
        loanDocumentDetails?.personalPhoto != undefined &&
        loanDocumentDetails?.personalPhoto != null &&
        loanDocumentDetails?.personalPhoto != '';
      if (isReadyToInvest === true) {
        setShowArmModal(true);
      } else {
        setShowArmProfileModal(true);
      }
    }
  };

  useEffect(() => {
    investStore.getLendaInvestmentsPlans();
    investStore.setTradelendaInvestments;
  }, []);

  const handleLendaInvest = () => {
    if (toJS(profile)?._id != null) {
      setShowInvestWithTradelendaModal(true);
    }
  };

  const activeLendaInvestments: any = toJS(userLendaInvestments)
    ? toJS(userLendaInvestments).filter(
        (investment: any) => investment?.investmentStatus == 'ACTIVE',
      )
    : 0.0;

  const activeARMinvestment: any = toJS(userInvestments)
    ? toJS(userInvestments).filter(
        (investment: any) => investment?.processStatus == 'WEBHOOK_SUCCESSFUL',
      )
    : 0.0;
  return (
    <div className="invest">
      <div className="section-head">Invest</div>
      {/* Monitor */}
      {showArmProfileModal && (
        <UpdateARMDetails setShowProfileModal={setShowArmProfileModal} />
      )}
      {showArmModal && <SaveWithArm setShowArmModal={setShowArmModal} />}
      {showInvestWithTradelendaModal && (
        <InvestWithTradelenda
          setShowInvestWithTradelendaModal={setShowInvestWithTradelendaModal}
        />
      )}

      <div className="upprSection mb-4 container">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={pagination}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            450: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
              autoplay: {
                delay: 500,
                disableOnInteraction: false,
              },
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1184: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          }}
          modules={[Pagination]}
          className="lp-career-container mySwiper"
        >
          <SwiperSlide>
            <div
              className="create-new-plan-Container "
              style={{
                // backgroundColor: '#06A77D',
                backgroundImage: `url("${BaseDirectories.IMAGES_DIR}/walletballance1.png")`,
              }}
            >
              <div className="right  p-2 ">
                <div className=" title-cont d-flex my-2">
                  <div className="d-flex  card-title">
                    <div>
                      <img
                        src={`${BaseDirectories.IMAGES_DIR}/totalInvestmentImage.png`}
                        alt=""
                        style={{ width: '25px', height: '25px' }}
                      />
                    </div>
                    <div>
                      <p className="ms-3 text-white">Lend with TradeLenda</p>
                    </div>
                  </div>
                </div>

                <div className=" d-flex   bal-visibility">
                  <div className="balance p-0 mb-3">
                    {mdiEyeOffOutline && showBalance ? (
                      <h4>
                        ₦{' '}
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                          activeLendaInvestments &&
                            activeLendaInvestments?.reduce(
                              (accumulator: any, currentValue: any) =>
                                accumulator + currentValue.totalReturn,
                              0,
                            ),
                        ) || '0.00'}
                      </h4>
                    ) : (
                      <h4>
                        <FaAsterisk className="no-show-balance p-0 me-1 text-white" />
                        <FaAsterisk className="no-show-balance p-0 me-1 text-white" />
                        <FaAsterisk className="no-show-balance p-0 me-1 text-white" />
                        <FaAsterisk className="no-show-balance p-0 me-1 text-white" />
                        <FaAsterisk className="no-show-balance p-0 me-1 text-white" />
                        <FaAsterisk className="no-show-balance p-0 me-1 text-white" />
                      </h4>
                    )}
                  </div>
                  <div className=" hide-show-icon">
                    {showBalance ? (
                      <button
                        className="show-icon"
                        onClick={() => setShowBalance(false)}
                      >
                        <Icon
                          path={mdiEyeOffOutline}
                          size={1}
                          className="no-show-bal-icon mt-0"
                        />
                      </button>
                    ) : (
                      <button
                        className="show-icon"
                        onClick={() => setShowBalance(true)}
                      >
                        <Icon
                          path={mdiEyeOutline}
                          size={1}
                          style={{ background: 'none' }}
                        />
                      </button>
                    )}
                  </div>
                </div>
                <div className="mt-4 button-cont ">
                  <div>
                    {/* Lenda investment */}
                    <Button
                      classes="primary-btn longer-btn"
                      content="Select Plan"
                      type="submit"
                      route=""
                      onClick={() => handleLendaInvest()}
                    />
                  </div>
                  <div className="trdlImage">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/tradeLendaCoin.png`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className="create-new-plan-Container "
              style={{
                backgroundImage: `url("${BaseDirectories.IMAGES_DIR}/walletballance.png")`,
              }}
            >
              <div className="right  p-2 ">
                <div className=" title-cont d-flex my-2">
                  <div className="d-flex  card-title">
                    <div>
                      <img
                        src={`${BaseDirectories.IMAGES_DIR}/totalInvestmentImage.png`}
                        alt=""
                        style={{ width: '25px', height: '25px' }}
                      />
                    </div>
                    <div>
                      <p className="ms-3">Save with ARM</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/armLogo.png`}
                      alt=""
                      style={{ width: '50px' }}
                    />
                  </div>
                </div>

                <div className=" d-flex   bal-visibility">
                  <div className="balance p-0 mb-3">
                    {mdiEyeOutline && showBalance ? (
                      <h4>
                        ₦{' '}
                        {(activeARMinvestment &&
                          new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                            activeARMinvestment?.reduce(
                              (accumulator: any, currentValue: any) =>
                                accumulator +
                                (currentValue.investmentAmount -
                                  currentValue?.redemptionAmount),
                              0,
                            ),
                          )) ||
                          '0.00'}
                      </h4>
                    ) : (
                      <h4>
                        <FaAsterisk className="no-show-balance p-0 me-1" />
                        <FaAsterisk className="no-show-balance p-0 me-1" />
                        <FaAsterisk className="no-show-balance p-0 me-1" />
                        <FaAsterisk className="no-show-balance p-0 me-1" />
                        <FaAsterisk className="no-show-balance p-0 me-1" />
                        <FaAsterisk className="no-show-balance p-0 me-1" />
                      </h4>
                    )}
                  </div>
                  <div className=" hide-show-icon">
                    {showBalance ? (
                      <button
                        className="show-icon"
                        onClick={() => setShowBalance(false)}
                      >
                        <Icon
                          path={mdiEyeOffOutline}
                          size={1}
                          className="no-show-bal-icon mt-0"
                        />
                      </button>
                    ) : (
                      <button
                        className="show-icon"
                        onClick={() => setShowBalance(true)}
                      >
                        <Icon
                          path={mdiEyeOutline}
                          size={1}
                          style={{ background: 'none' }}
                        />
                      </button>
                    )}
                  </div>
                </div>
                <div className="mt-4 button-cont">
                  <div>
                    {/* ARM TESTING CONDITION TO PREVENT SENDING TO PRODUCTION */}
                    <Button
                      classes="primary-btn longer-btn"
                      content="Select Plan"
                      type="submit"
                      route=""
                      onClick={() => handleArm()}
                    />
                  </div>
                  <div>
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/armCoin.png`}
                      alt=""
                      style={{ width: '120px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="loans-container">
        <div className="guarantor-list">
          {mobile &&
            (!isOpen ? (
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={toggleMenu}
                className="mobile-menu-icon"
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronUp}
                onClick={toggleMenu}
                className="mobile-menu-icon"
              />
            ))}

          <Tabs selectedTabClassName="selected">
            {/* Mobile  */}
            {mobile ? (
              <>
                <TabList
                  className={
                    'd-flex flex-column flex-md-row m-0 p-0 guarantor-heade automatic'
                  }
                >
                  {!isOpen && (
                    <div className="guarantors-header-link">
                      <h5>
                        {headerText === '' ? 'Lenda Investment' : headerText}
                      </h5>
                    </div>
                  )}

                  <div className={isOpen ? '' : 'hidden-header'}>
                    <Tab
                      className="guarantors-header-link"
                      onClick={(e) => activeHeader(e)}
                    >
                      <h5>Lenda Investment</h5>
                    </Tab>
                    <Tab
                      className="guarantors-header-link"
                      onClick={(e) => activeHeader(e)}
                    >
                      <h5>ARM Investment</h5>
                    </Tab>
                  </div>
                </TabList>
              </>
            ) : (
              <TabList className={'d-flex m-0 p-0 guarantor-heade automatic'}>
                <Tab className="guarantors-header-link">
                  <h5>Lenda Investment</h5>
                </Tab>
                <Tab className="guarantors-header-link">
                  <h5>ARM Investment</h5>
                </Tab>
              </TabList>
            )}
            <TabPanel>
              {toJS(userLendaInvestments).length === 0 ? (
                <div className="bg-white no-guarantor">
                  <div className="empty-state-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/LendingImage.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                  <p>Start lending in verified opportunities!</p>
                </div>
              ) : (
                <div
                  className="bg-white table-responsive"
                  style={{ overflowX: 'auto' }}
                >
                  <table className="table table-striped responsive-table mb-0">
                    <thead className="thead">
                      <tr>
                        <th scope="col">Investment Plan</th>
                        <th scope="col">Total Invested Amount</th>
                        <th scope="col">Process Status</th>
                        <th scope="col"></th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {toJS(userLendaInvestments) &&
                        toJS(userLendaInvestments).map(
                          (invest: any, i: number) => (
                            <tr
                              className="mt-2"
                              key={i}
                              style={{
                                alignContent: 'center',
                              }}
                            >
                              <td className="align-middle">
                                {invest?.investmentType}
                              </td>
                              <td className="align-middle">
                                ₦{' '}
                                {new Intl.NumberFormat('en-US', {
                                  style: 'decimal',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(
                                  invest?.totalReturn
                                    ? invest?.totalReturn
                                    : invest?.investmentAmount,
                                )}
                              </td>
                              <td className="align-middle">
                                {invest?.investmentStatus}
                              </td>
                              <td className="mobile-tr align-middle">
                                <Link
                                  style={
                                    mobile
                                      ? { fontSize: '10px' }
                                      : { whiteSpace: 'nowrap' }
                                  }
                                  to="/dashboard/invest/lenda_details"
                                  state={{
                                    InvestmentId: invest?._id,
                                  }}
                                >
                                  <Button
                                    content="View More"
                                    classes="secondary-btn btn-lg bg-white"
                                  />
                                </Link>
                              </td>
                              <td className="mobile-th">
                                <Link
                                  style={
                                    mobile
                                      ? { fontSize: '10px' }
                                      : { whiteSpace: 'nowrap' }
                                  }
                                  to="/dashboard/invest/lenda_details"
                                  state={{
                                    InvestmentId: invest?._id,
                                  }}
                                >
                                  <Icon
                                    path={mdiEyeSettingsOutline}
                                    className="approved"
                                    style={{ width: '1.5rem' }}
                                  />
                                </Link>
                              </td>
                            </tr>
                          ),
                        )}
                    </tbody>
                  </table>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              {investStore?.userInvestments?.length === 0 ? (
                <div className="bg-white no-guarantor">
                  <div className="empty-state-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/LendingImage.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                  <p>Start Investing with ARM!</p>
                </div>
              ) : (
                <div
                  className="bg-white table-responsive"
                  style={{ overflowX: 'auto' }}
                >
                  <table className="table table-striped responsive-table mb-0">
                    <thead className="thead">
                      <tr>
                        <th scope="col">Investment Plan</th>
                        <th scope="col">Total Invested Amount</th>
                        <th scope="col">Process Status</th>
                        <th scope="col"></th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {investStore?.userInvestments?.map(
                        (invest: any, i: number) => (
                          <tr
                            className="mt-2"
                            key={i}
                            style={{
                              alignContent: 'center',
                            }}
                          >
                            <td className="align-middle">
                              {invest?.productCode}
                            </td>

                            <td className="align-middle">
                              ₦{' '}
                              {new Intl.NumberFormat('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                invest?.investmentAmount -
                                  invest?.redemptionAmount,
                              )}
                            </td>
                            <td className="align-middle">
                              {invest?.processStatus === 'PENDING_NIP_DEBIT' &&
                              invest?.redemptionStatus !==
                                'REDEMPTION_SUCCESSFUL'
                                ? 'Processing Payment'
                                : invest?.processStatus ===
                                    'NIP_DEBIT_FAILED' &&
                                  invest?.redemptionStatus !==
                                    'REDEMPTION_SUCCESSFUL'
                                ? 'Re-processing Payment'
                                : invest?.processStatus ===
                                    'NIP_DEBIT_SUCCESSFUL' &&
                                  invest?.redemptionStatus !==
                                    'REDEMPTION_SUCCESSFUL'
                                ? 'Payment Successful'
                                : invest?.processStatus === 'WEBHOOK_FAILED' &&
                                  invest?.redemptionStatus !==
                                    'REDEMPTION_SUCCESSFUL'
                                ? 'Waiting for Confirmation'
                                : invest?.membershipId !== null &&
                                  invest?.processStatus ===
                                    'WEBHOOK_SUCCESSFUL' &&
                                  invest?.redemptionStatus !==
                                    'REDEMPTION_SUCCESSFUL'
                                ? 'Investment Active'
                                : invest?.processStatus ===
                                    'WEBHOOK_SUCCESSFUL' &&
                                  invest?.redemptionStatus ===
                                    'REDEMPTION_SUCCESSFUL'
                                ? 'Investment Closed'
                                : 'Processng Investment'}
                            </td>
                            <td className="mobile-tr align-middle">
                              {invest?.membershipId ? (
                                <Link
                                  style={
                                    mobile
                                      ? { fontSize: '10px' }
                                      : { whiteSpace: 'nowrap' }
                                  }
                                  to="/dashboard/invest/details"
                                  state={{
                                    MembershipId: invest?.membershipId,
                                    ProductCode: invest?.productCode,
                                  }}
                                >
                                  <Button
                                    content="View More"
                                    classes="secondary-btn btn-lg bg-white"
                                  />
                                </Link>
                              ) : (
                                <em>Retrieving...</em>
                              )}
                            </td>
                            <td className="mobile-th">
                              {invest?.membershipId ? (
                                <Link
                                  style={
                                    mobile
                                      ? { fontSize: '10px' }
                                      : { whiteSpace: 'nowrap' }
                                  }
                                  to="/dashboard/invest/details"
                                  state={{
                                    MembershipId: invest?.membershipId,
                                    ProductCode: invest?.productCode,
                                  }}
                                >
                                  <Icon
                                    path={mdiEyeSettingsOutline}
                                    className="approved"
                                    style={{ width: '1.5rem' }}
                                  />
                                </Link>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </TabPanel>
          </Tabs>
          <div className="navigation">
            <button
              className="btn prev disabled navigation-btn"
              title="Previous"
            >
              <Icon path={mdiChevronLeft} size={1} />
            </button>
            <Button
              content="1"
              title="Page 1"
              classes="active prev navigation-btn"
            />
            <Button
              content="2"
              title="Page 2"
              classes="active prev navigation-btn"
            />
            <button className="btn next navigation-btn" title="Next">
              <Icon path={mdiChevronRight} size={1} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToPropd = (state: any) => {
  return {
    loanDetails: state.loanDetails,
  };
};

export default connect(mapStateToPropd)(observer(InvestOverview));
