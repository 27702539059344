/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Input from '../ui/Input';
import { StoreContext } from '../../mobx_stores/RootStore';
import { toJS } from 'mobx';
import Button from '../ui/Button';
import { observer } from 'mobx-react-lite';
import Spinner from '../ui/Spinner';
// import toast, { Toaster, ToastBar } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';

type Props = {
  x: number;
  disabled: boolean;
  setDisabled: Function;
  profile: { [key: string]: any };
};

const gender = {
  male: 'Male',
  female: 'Female',
  preferNotToSay: 'Prefer Not To Say',
};

const EducationLevel = {
  Primary: 'Primary School',
  Secondary: 'Secondary School',
  OND: 'Ordinary National Diploma (OND)',
  HND: 'Higher National Diploma (HND)',
  Bachelors: 'Bachelors',
  Masters: 'Masters',
  PHD: 'PHD',
  PGD: 'Post Graduate Diploma',
};

const SettingsPersonalDetailsForm: FC<Props> = ({
  x,
  disabled,
  profile,
  setDisabled,
}) => {
  const { profileStore } = React.useContext(StoreContext);

  const { submitting, loadingData, message } = profileStore;

  const [disabledObj, setDisabledObj] = useState<any>({});

  const [personalDetails, setPersonalDetails] = useState<any>({
    title: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    gender: '',
    bvn: '',
    nin: '',
    dob: '',
    address: '',
    country: 'Nigeria',
    state: '',
    city: '',
    maritalStatus: '',
    NoOfDependents: '0',
    wdymtta: '',
    eduLevel: '',
  });

  const profileDetails = profile;

  useEffect(() => {
    setPersonalDetails({
      title:
        profile && profileDetails.title === undefined
          ? ''
          : profileDetails.title,
      email:
        profile && profileDetails.email === undefined
          ? ''
          : profileDetails.email,
      firstName:
        profile && profileDetails.firstName === undefined
          ? ''
          : profileDetails.firstName,
      lastName:
        profile && profileDetails.lastName === undefined
          ? ''
          : profileDetails.lastName,
      phoneNumber:
        profile && profileDetails.phoneNumber === undefined
          ? ''
          : profileDetails.phoneNumber,
      gender:
        profile && profileDetails.gender === undefined
          ? ''
          : profileDetails.gender,
      bvn:
        profile && profileDetails.bvn === undefined ? '' : profileDetails.bvn,
      nin:
        profile && profileDetails.nin === undefined ? '' : profileDetails.nin,
      dob:
        profile && profileDetails.dob === undefined
          ? ''
          : profileDetails.dob?.substr(0, 10),
      address:
        profile && profileDetails.address === undefined
          ? ''
          : profileDetails.address,
      country:
        profile && profileDetails.country === undefined
          ? 'Nigeria'
          : profileDetails.country,
      state:
        profile && profileDetails.state === undefined
          ? ''
          : profileDetails.state,
      city:
        profile && profileDetails.city === undefined ? '' : profileDetails.city,
      maritalStatus:
        profile && profileDetails.maritalStatus === undefined
          ? ''
          : profileDetails.maritalStatus,
      NoOfDependents:
        profile && profileDetails.NoOfDependents === undefined
          ? 0
          : profileDetails.NoOfDependents,
      wdymtta:
        profile && profileDetails.wdymtta === undefined
          ? ''
          : profileDetails.wdymtta,
      eduLevel:
        profile && profileDetails.eduLevel === undefined
          ? ''
          : profileDetails.eduLevel,
      // yearYouMovedToCurrentAddress:
      //   profile && profileDetails.yearYouMovedToCurrentAddress === undefined
      //     ? ''
      //     : profileDetails.yearYouMovedToCurrentAddress,
    });
  }, [profile, profileDetails]);

  // useEffect(() => {
  //   profileStore.getProfile();
  // }, [loansStore]);

  const [state, setState] = useState([]);
  const [cityByState, setCitybyState] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    setState(toJS(profileStore.state));
    setCity(toJS(profileStore.city));
  }, [profileStore.state, profileStore.city]);

  useEffect(() => {
    if (personalDetails.state !== '') {
      setCitybyState(
        state.filter((state: string) => state === personalDetails.state),
      );
    }
  }, [personalDetails.state, state]);

  const stateCity = cityByState[0];

  useEffect(() => {
    profileStore.getState();
    profileStore.getCity(stateCity);
  }, [profileStore, stateCity]);

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setPersonalDetails((prevDetails: any) => {
      if (element.type === 'number') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });

    if (
      element.value !== '' &&
      element.style.border === '2px solid rgb(255, 137, 137)'
    ) {
      element.style.border = '1px solid #ced4da';
    }
  };

  const handleCreatePersonalProfile = () => {
    profileStore.createProfile(personalDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          personalDetails[input.name] === '' ||
          personalDetails[input.name] === 'NULL'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 50000);
        }
      });
    }
  };

  const handleUpdatePersonalProfile = () => {
    profileStore.updatePersonalDetails(personalDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          personalDetails[input.name] === '' ||
          personalDetails[input.name] === 'NULL'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 50000);
        }
      });
    }
  };

  const submit = () => {
    profileDetails.firstName === undefined
      ? handleCreatePersonalProfile()
      : handleUpdatePersonalProfile();
  };

  useEffect(() => {
    if (message.type === 'success') {
      setDisabled(true);
    }
  }, [message.type]);

  useEffect(() => {
    if (personalDetails) {
      Object.keys(personalDetails).forEach((p) => {
        if (!disabled) {
          if (personalDetails[p] === '') {
            // disabledObj[biz] = false;
            setDisabledObj((prev: any) => {
              return { ...prev, [p]: false };
            });
          } else {
            // disabledObj[biz] = true;
            setDisabledObj((prev: any) => {
              return { ...prev, [p]: true };
            });
          }
        } else {
          setDisabledObj((prev: any) => {
            return { ...prev, [p]: true };
          });
        }
      });
    }
  }, [personalDetails, disabled]);

  return (
    <>
      {submitting && <Spinner />}
      {/* <Toaster
        position="top-right"
        reverseOrder={true}
        containerStyle={{
          top: 120,
        }}
        toastOptions={{
          success: {
            duration: 5000,
          },
          error: {
            duration: 5000,
          },
          style: {
            gap: '10px',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== 'loading' && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{ width: '50px', background: 'white' }}
                  >
                    {icon}
                  </button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster> */}
      {loadingData ? (
        <div className="account-settings-form">
          <motion.div
            initial={{ x: x }}
            transition={{ duration: 0.5 }}
            animate={{ x: 0 }}
            className=""
          >
            <div className="row px-md-5 px-4 justify-content-center">
              <div className="col-lg-11 col-xl-6 col-md-12 left-side pe-lg-3">
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
              </div>

              <div className="col-lg-11 col-xl-6 col-md-12 right-side ps-lg-3 ">
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    <Skeleton width={80} height={22} />
                  </label>
                  <span className="form-control" style={{ border: 'none' }}>
                    <Skeleton width={180} height={22} />
                  </span>
                </div>
              </div>
            </div>
          </motion.div>
          <div className="row justify-content-center text-center mt-4">
            <div className="col-lg-8 col-md-8">
              <div className="profile-btn d-flex gap-3 align-items-center justify-content-center">
                <span className="btn-lg px-4" style={{ border: 'none' }}>
                  <Skeleton width={150} height={44} />
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="account-settings-form">
          <motion.div
            initial={{ x: x }}
            transition={{ duration: 0.5 }}
            animate={{ x: 0 }}
            className=""
          >
            <div className="row px-md-5 px-4 justify-content-center">
              <div className="col-lg-11 col-xl-6 col-md-12 left-side pe-lg-3">
                <div className="col-md-12 input-box">
                  <label htmlFor="title" className="mb-1 mt-3">
                    Title
                  </label>
                  <select
                    name="title"
                    disabled={disabled}
                    className="form-control"
                    id="title"
                    value={personalDetails.title}
                    onChange={handleInputChange}
                  >
                    <option value="">Select title</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Dr">Dr</option>
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="firstName" className="mb-1 mt-3">
                    First name
                  </label>
                  <Input
                    name="firstName"
                    disabled={true}
                    classes="form-control"
                    type="text"
                    id="firstName"
                    value={personalDetails.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="lastName" className="mb-1 mt-3">
                    Last name
                  </label>
                  <Input
                    name="lastName"
                    disabled={true}
                    classes="form-control"
                    type="text"
                    id="lastName"
                    value={personalDetails.lastName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="email" className="mb-1 mt-3">
                    Email
                  </label>
                  <Input
                    name="email"
                    disabled={true}
                    classes="form-control"
                    type="text"
                    id="email"
                    value={personalDetails.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="dob" className="mb-1 mt-3">
                    Date of birth
                  </label>
                  <Input
                    name="dob"
                    disabled={disabled}
                    classes="form-control"
                    type="date"
                    id="dob"
                    value={personalDetails.dob}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="gender" className="mb-1 mt-3">
                    Gender
                  </label>
                  <select
                    name="gender"
                    disabled={disabled}
                    id="gender"
                    className="form-control"
                    value={personalDetails.gender}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Gender</option>
                    <option value={gender.male}>{gender.male}</option>
                    <option value={gender.female}>{gender.female}</option>
                    <option value={gender.preferNotToSay}>
                      {gender.preferNotToSay}
                    </option>
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="maritalStatus" className="mb-1 mt-3">
                    Marital status
                  </label>
                  <select
                    name="maritalStatus"
                    disabled={disabled}
                    id="maritalStatus"
                    className="form-control"
                    value={personalDetails.maritalStatus}
                    onChange={handleInputChange}
                  >
                    <option value="">Select marital status</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="educationalLevel" className="mb-1 mt-3">
                    Educational level
                  </label>
                  <select
                    name="eduLevel"
                    disabled={disabled}
                    id="educationalLevel"
                    className="form-control"
                    value={personalDetails.eduLevel}
                    onChange={handleInputChange}
                  >
                    <option value="">Select educational level</option>
                    {Object.keys(EducationLevel).map((edu: any, i: number) => (
                      <option key={i} value={EducationLevel[edu]}>
                        {edu}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-11 col-xl-6 col-md-12 right-side ps-lg-3 ">
                <div className="col-md-12 input-box">
                  <label htmlFor="address" className="mb-1 mt-3">
                    Address
                  </label>
                  <Input
                    name="address"
                    disabled={true}
                    classes="form-control"
                    type="text"
                    id="address"
                    value={personalDetails.address}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="country" className="mb-1 mt-3">
                    Country
                  </label>
                  <select
                    name="country"
                    disabled={true}
                    id="country"
                    className="form-control"
                    value={personalDetails.country}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Country</option>
                    <option value="Nigeria">Nigeria</option>
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="state" className="mb-1 mt-3">
                    State
                  </label>
                  <select
                    name="state"
                    disabled={true}
                    id="state"
                    className="form-control"
                    value={personalDetails.state}
                    onChange={handleInputChange}
                  >
                    <option value="">Select State</option>
                    {state.map((state: string, i: number) => (
                      <option value={state} key={i}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="city" className="mb-1 mt-3">
                    City
                  </label>
                  <select
                    name="city"
                    disabled={true}
                    id="city"
                    className="form-control"
                    value={personalDetails.city}
                    onChange={handleInputChange}
                  >
                    <option value="">Select City</option>
                    {city.map((city: string, i: number) => (
                      <option value={city} key={i}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="wdymtta" className="mb-1 mt-3">
                    When did you move to this address?
                    <span style={{ color: 'red' }}> *</span>
                  </label>
                  <select
                    name="wdymtta"
                    id="wdymtta"
                    className="form-control"
                    disabled={disabled}
                    onChange={handleInputChange}
                    value={personalDetails.wdymtta}
                  >
                    <option value="">Select option</option>
                    <option value="0-1 year">0-1 year</option>
                    <option value="1-3 years">1-3 years</option>
                    <option value="3-5 years">3-5 years</option>
                    <option value="5-10 years">5-10 years</option>
                    <option value="10+ years">10+ years</option>
                  </select>
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="NoOfDependents" className="mb-1 mt-3">
                    Number of dependents
                  </label>
                  <Input
                    name="NoOfDependents"
                    disabled={disabled}
                    classes="form-control"
                    type="number"
                    id="NoOfDependents"
                    min={0}
                    value={personalDetails.NoOfDependents}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="bvn" className="mb-1 mt-3">
                    BVN (Bank Verification Number)
                  </label>
                  <Input
                    name="bvn"
                    disabled={true}
                    classes="form-control"
                    type="text"
                    id="bvn"
                    value={personalDetails.bvn}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12 input-box">
                  <label htmlFor="bvn" className="mb-1 mt-3">
                    NIN (National Identification Number)
                  </label>
                  <Input
                    name="nin"
                    disabled={true}
                    classes="form-control"
                    type="text"
                    id="nin"
                    value={personalDetails.nin}
                    onChange={handleInputChange}
                  />
                </div>
                {/* <div className="col-md-12 input-box">
                  <label htmlFor="identificationType" className="mb-1 mt-3">
                    Identification type
                  </label>
                  <select
                    name="identificationType"
                    disabled={disabled}
                    id="identificationType"
                    className="form-control"
                    value={personalDetails.identificationType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select identification type</option>
                    <option value="">ID card</option>
                    <option value="">Utility bill</option>
                  </select>
                </div> */}
              </div>
            </div>
          </motion.div>
          <div className="row justify-content-center text-center mt-4">
            <div className="col-lg-8 col-md-8">
              <div className="profile-btn d-flex gap-3 align-items-center justify-content-center">
                {!disabled && (
                  <Button
                    classes="secondary-btn btn-lg px-4 cancel"
                    content="Cancel"
                    onClick={() => setDisabled(true)}
                  />
                )}
                <Button
                  classes="primary-btn btn-lg px-4"
                  content={disabled ? 'Edit Profile' : 'Save'}
                  onClick={() => {
                    if (disabled) {
                      setDisabled(false);
                    } else {
                      submit();
                      // handleUpdatePersonalProfile(personalDetails);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(SettingsPersonalDetailsForm);
