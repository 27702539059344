/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';

type Props = {
  close?: () => void;
  // onClick?: () => void;
  handleFileChange: any;
  handleUpdateDocs: any;
  documentName: string;
  uploading: any;
  uploadProgress: number;
  uploadFile: any;
  done: boolean;
};

const UpdateDocumentsModal: FC<Props> = ({
  handleFileChange,
  handleUpdateDocs,
  documentName,
  uploading,
  uploadFile,
  uploadProgress,
  close,
  done,
}) => {
  return (
    <div className="notification-modal">
      <div className="bg-white notification-box">
        <span className="close" onClick={close}>
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="Close"
          />
        </span>
        <h5>
          {documentName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, function (str) {
              return str.toUpperCase();
            })}
        </h5>

        <div className="col-md-12">
          <div className="file-input my-4">
            <div>
              <Input
                name={documentName}
                type="file"
                classes=""
                id={documentName}
                accept=".png, .jpg, .jpeg, .pdf"
                onChange={handleFileChange}
              />
            </div>
            <p className="format">File format JPEG, PNG, PDF Size 3mb max</p>
            {!done && (
              <Button
                classes="secondary-btn mt-4"
                content={`Upload ${documentName
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  })}`}
                onClick={uploadFile}
                type="button"
              />
            )}
          </div>
          <div
            style={{
              opacity: uploading ? '1' : '0',
              height: uploading ? '100%' : '0',
              transition: 'all .2s',
            }}
            className="progress-stat"
          >
            <div className="progress-bar">
              <div
                className="progress-bar-blue"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p style={{ paddingTop: '10px' }}>{uploadProgress}% Uploaded</p>
          </div>
        </div>

        <Button
          classes="primary-btn btn-lg waves-effect"
          content="Done"
          type="button"
          disabled={!done}
          onClick={handleUpdateDocs}
        />
      </div>
    </div>
  );
};

export default UpdateDocumentsModal;
