/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { toJS } from 'mobx';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
// import InputPinModal from '../InputPinModal';
import Button from '../../../../components/ui/Button';
import Input from '../../../../components/ui/Input';
import { toast } from 'react-toastify';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import { observer } from 'mobx-react-lite';
// import { BillStore } from '../../../../ mobx stores/BillStore';

const defaultData = [
  {
    serviceType: 'benin_electric_postpaid',
    name: 'benin postpaid',
    billerId: 1,
    productId: 1,
  },
  {
    serviceType: 'benin_electric_prepaid',
    name: 'benin prepaid',
    billerId: 2,
    productId: 2,
  },
  {
    serviceType: 'ibadan_electric_prepaid',
    name: 'ibadan prepaid',
    billerId: 3,
    productId: 3,
  },
  {
    serviceType: 'ikeja_electric_prepaid',
    name: 'ikeja prepaid',
    billerId: 4,
    productId: 4,
  },
  {
    serviceType: 'jos_electric_postpaid',
    name: 'jos postpaid',
    billerId: 5,
    productId: 5,
  },
  {
    serviceType: 'jos_electric_prepaid',
    name: 'jos prepaid',
    billerId: 6,
    productId: 6,
  },
  {
    serviceType: 'ikeja_electric_postpaid',
    name: 'ikeja postpaid',
    billerId: 7,
    productId: 7,
  },
  {
    serviceType: 'eko_electric_postpaid',
    name: 'eko postpaid',
    billerId: 8,
    productId: 8,
  },
  {
    serviceType: 'eko_electric_prepaid',
    name: 'eko prepaid',
    billerId: 9,
    productId: 9,
  },
  {
    serviceType: 'enugu_electric_postpaid',
    name: 'enugu postpaid',
    billerId: 10,
    productId: 10,
  },
  {
    serviceType: 'enugu_electric_prepaid',
    name: 'enugu prepaid',
    billerId: 11,
    productId: 11,
  },
  {
    serviceType: 'abuja_electric_postpaid',
    name: 'abuja postpaid',
    billerId: 12,
    productId: 12,
  },
  {
    serviceType: 'abuja_electric_prepaid',
    name: 'abuja prepaid',
    billerId: 13,
    productId: 13,
  },
  {
    serviceType: 'portharcourt_electric_prepaid',
    name: 'portharcourt prepaid',
    billerId: 14,
    productId: 14,
  },
  {
    serviceType: 'portharcourt_electric_postpaid',
    name: 'portharcourt postpaid',
    billerId: 15,
    productId: 15,
  },
  {
    serviceType: 'ibadan_electric_postpaid',
    name: 'ibadan postpaid',
    billerId: 16,
    productId: 16,
  },
  {
    serviceType: 'kedco_electric_prepaid',
    name: 'kedco prepaid',
    billerId: 17,
    productId: 17,
  },
  {
    serviceType: 'kedco_electric_postpaid',
    name: 'kedco postpaid',
    billerId: 18,
    productId: 18,
  },
  {
    serviceType: 'kaduna_electric_prepaid',
    name: 'kaduna prepaid',
    billerId: 19,
    productId: 19,
  },
  {
    serviceType: 'aba_electric_postpaid',
    name: 'aba postpaid',
    billerId: 20,
    productId: 20,
  },
  {
    serviceType: 'aba_electric_prepaid',
    name: 'aba prepaid',
    billerId: 21,
    productId: 21,
  },
  {
    serviceType: 'kaduna_electric_postpaid',
    name: 'kaduna postpaid',
    billerId: 22,
    productId: 22,
  },
];

const defaultPlan = [
  {
    name: 'MTN N100 100MB - (24 Hours)',
    allowance: null,
    validity: null,
    price: 100,
    datacode: 'mtn-10mb-100',
  },
  {
    name: 'MTN N200 200MB - 3 days',
    allowance: null,
    validity: null,
    price: 200,
    datacode: 'mtn-50mb-200',
  },
  {
    name: 'MTN N1,000 1.2GB + Youtube Data   - 30 days',
    allowance: null,
    validity: null,
    price: 1000,
    datacode: 'mtn-1200mb-1000',
  },
  {
    name: 'MTN N2,000 4GB  - 30 days',
    allowance: null,
    validity: null,
    price: 2000,
    datacode: 'mtn-4gb-2000',
  },
  {
    name: 'MTN N350 350MB - 7 days',
    allowance: null,
    validity: null,
    price: 350,
    datacode: 'mtn-350mb-350',
  },
  {
    name: 'MTN N1,500 5GB  - 7 days',
    allowance: null,
    validity: null,
    price: 1500,
    datacode: 'mtn-5gb-1500',
  },
  {
    name: 'MTN N3,000 8GB + Youtube  - 30 days',
    allowance: null,
    validity: null,
    price: 3000,
    datacode: 'mtn-8gb-3000',
  },
  {
    name: 'MTN N3,500 10GB + Youtube Data - 30 days',
    allowance: null,
    validity: null,
    price: 3500,
    datacode: 'mtn-data-3500',
  },
  {
    name: 'MTN N4,000 12GB + Youtube  - 30 days',
    allowance: null,
    validity: null,
    price: 4000,
    datacode: 'mtn-12gb-4000',
  },
  {
    name: 'MTN N5,500 20GB + Youtube  - 30 days',
    allowance: null,
    validity: null,
    price: 5500,
    datacode: 'mtn-20gb-5500',
  },
  {
    name: 'MTN N6,500 25GB + Youtube  - 30 days',
    allowance: null,
    validity: null,
    price: 6500,
    datacode: 'mtn-25gb-6500',
  },
  {
    name: 'MTN N11,000 40GB  - 30 days',
    allowance: null,
    validity: null,
    price: 11000,
    datacode: 'mtn-40gb-11000',
  },
  {
    name: 'MTN N16,000 75GB  - 30 days',
    allowance: null,
    validity: null,
    price: 16000,
    datacode: 'mtn-75gb-16000',
  },
  {
    name: 'MTN N1600 3GB  - 30 days',
    allowance: null,
    validity: null,
    price: 1600,
    datacode: 'mtn-3gb-1600',
  },
  {
    name: 'MTN N1,200 1.5GB + Youtube  - 30 days',
    allowance: null,
    validity: null,
    price: 1200,
    datacode: 'mtn-1500mb-1200',
  },
  {
    name: 'MTN N350,000 1TB SME Data - 365 days',
    allowance: null,
    validity: null,
    price: 350000,
    datacode: 'mtn-1tb-350000',
  },
  {
    name: 'MTN N350 1GB - 1 day',
    allowance: null,
    validity: null,
    price: 350,
    datacode: 'mtn-1gb-350',
  },
  {
    name: 'MTN N300 Xtradata',
    allowance: null,
    validity: null,
    price: 300,
    datacode: 'mtn-xtra-300',
  },
  {
    name: 'MTN N600 1GB + Youtube Data  - 7 days',
    allowance: null,
    validity: null,
    price: 600,
    datacode: 'mtn-1gb-600',
  },
  {
    name: 'MTN N500 Xtradata',
    allowance: null,
    validity: null,
    price: 500,
    datacode: 'mtn-xtra-500',
  },
  {
    name: 'MTN N1,000 Xtradata',
    allowance: null,
    validity: null,
    price: 1000,
    datacode: 'mtn-xtra-1000',
  },
  {
    name: 'MTN N2,000 Xtradata',
    allowance: null,
    validity: null,
    price: 2000,
    datacode: 'mtn-xtra-2000',
  },
  {
    name: 'MTN N5,000 Xtradata',
    allowance: null,
    validity: null,
    price: 5000,
    datacode: 'mtn-xtra-5000',
  },
  {
    name: 'MTN N10,000 Xtradata',
    allowance: null,
    validity: null,
    price: 10000,
    datacode: 'mtn-xtra-10000',
  },
  {
    name: 'MTN N15,000 Xtradata',
    allowance: null,
    validity: null,
    price: 15000,
    datacode: 'mtn-xtra-15000',
  },
  {
    name: 'MTN N20,000 Xtradata',
    allowance: null,
    validity: null,
    price: 20000,
    datacode: 'mtn-xtra-20000',
  },
  {
    name: 'MTN N10,000 25GB SME Mobile Data ( 1 Month)',
    allowance: null,
    validity: null,
    price: 10000,
    datacode: 'mtn-25gb-sme-10000',
  },
  {
    name: 'MTN N50,000 165GB SME Mobile Data (2-Months)',
    allowance: null,
    validity: null,
    price: 50000,
    datacode: 'mtn-165gb-sme-50000',
  },
  {
    name: 'MTN N100,000 360GB SME Mobile Data (3 Months)',
    allowance: null,
    validity: null,
    price: 100000,
    datacode: 'mtn-360gb-sme-100000',
  },
  {
    name: 'MTN N450,000 1.5TB Mobile Data (1 Year)',
    allowance: null,
    validity: null,
    price: 450000,
    datacode: 'mtn-1-5tb-450000',
  },
  {
    name: 'MTN N100,000 1TB Mobile Data (1 Year)',
    allowance: null,
    validity: null,
    price: 100000,
    datacode: 'mtn-1tb-110000',
  },
  {
    name: 'MTN N600 2.5GB - 2 days',
    allowance: null,
    validity: null,
    price: 600,
    datacode: 'mtn-2-5gb-600',
  },
  {
    name: 'MTN N22000 120GB Monthly Plan',
    allowance: null,
    validity: null,
    price: 22000,
    datacode: 'mtn-120gb-22000',
  },
  {
    name: 'MTN 100GB 2-Month Plan',
    allowance: null,
    validity: null,
    price: 20000,
    datacode: 'mtn-100gb-20000',
  },
  {
    name: 'MTN N30,000 160GB 2-Month Plan',
    allowance: null,
    validity: null,
    price: 30000,
    datacode: 'mtn-160gb-30000',
  },
  {
    name: 'MTN N50,000 400GB 3-Month Plan',
    allowance: null,
    validity: null,
    price: 50000,
    datacode: 'mtn-400gb-50000',
  },
  {
    name: 'MTN N75,000 600GB 3-Months Plan',
    allowance: null,
    validity: null,
    price: 75000,
    datacode: 'mtn-600gb-75000',
  },
  {
    name: 'MTN N300 Xtratalk Weekly Bundle',
    allowance: null,
    validity: null,
    price: 300,
    datacode: 'mtn-xtratalk-300',
  },
  {
    name: 'MTN N500 Xtratalk Weekly Bundle',
    allowance: null,
    validity: null,
    price: 500,
    datacode: 'mtn-xtratalk-500',
  },
  {
    name: 'MTN N1000 Xtratalk Monthly Bundle',
    allowance: null,
    validity: null,
    price: 1000,
    datacode: 'mtn-xtratalk-1000',
  },
  {
    name: 'MTN N2000 Xtratalk Monthly Bundle',
    allowance: null,
    validity: null,
    price: 2000,
    datacode: 'mtn-xtratalk-2000',
  },
  {
    name: 'MTN N5000 Xtratalk Monthly Bundle',
    allowance: null,
    validity: null,
    price: 5000,
    datacode: 'mtn-xtratalk-5000',
  },
  {
    name: 'MTN N10000 Xtratalk Monthly Bundle',
    allowance: null,
    validity: null,
    price: 10000,
    datacode: 'mtn-xtratalk-10000',
  },
  {
    name: 'MTN N15000 Xtratalk Monthly Bundle',
    allowance: null,
    validity: null,
    price: 15000,
    datacode: 'mtn-xtratalk-15000',
  },
  {
    name: 'MTN N20000 Xtratalk Monthly Bundle',
    allowance: null,
    validity: null,
    price: 20000,
    datacode: 'mtn-xtratalk-20000',
  },
];

type FullDetails = {
  customerName: string;
};

const NewElectricityPurchase = ({ setOpenPowerPurchaseModal }) => {
  const [showBankSelection, setShowBankSelection] = useState(true);
  const [showAmountInput, setShowAmountInput] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({
    fromWalletIdAccountNumber: '',
    serviceType: '',
    billType: '',
    amount: '',
    msisdn: '',
    transactionPin: '',
    accountId: '',
    productId: '',
  });
  const { billStore, walletStore } = useContext(StoreContext);
  const { submitting, message } = billStore;
  const { multipleWallet, seerbitWalletBallance, wallet } = walletStore;
  const [showTransactionStatus, setShowTransactionStatus] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [pin, setPin] = useState('');
  const [pinToggle, setPinToggle] = useState(false);
  const [serviceTypes, setServiceTypes] = useState<any>();
  const [billTypes, setBillTypes] = useState<any>();
  const [fullDetails, setFullDetails] = useState<FullDetails>({
    customerName: '',
  });

  useEffect(() => {
    billStore.getElectricityProviders();
  }, [billStore]);

  useEffect(() => {
    if (billStore.electricityProviders) {
      const providerDetails = Object.values(billStore.electricityProviders)
        .filter((provider) => typeof provider === 'object' && provider !== null)
        .map((provider: any) => ({
          id: provider.id,
          name: provider.name,
        }));
      setServiceTypes(toJS(providerDetails));
    }
  }, [billStore.electricityProviders]);

  useEffect(() => {
    if (billStore.billsPlan) {
      const billsPlan = Object.values(billStore.billsPlan)
        .filter((plan) => typeof plan === 'object' && plan !== null)
        .map((plan: any) => ({
          id: plan.id,
          name: plan.name,
        }));
      setBillTypes(billsPlan);
    }
  }, [billStore.billsPlan]);

  useEffect(() => {
    if (billStore.meterDetails) {
      const customerName = billStore.meterDetails[3];
      setFullDetails({ customerName });
    }
  }, [billStore.meterDetails]);

  const handleInputChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    const val =
      element.name === 'amount'
        ? element.value.length === 0
          ? element.value
          : parseInt(element.value)
        : element.value;

    setTransactionDetails((prevDetails: any) => {
      const updatedDetails = { ...prevDetails, [element.name]: val };

      if (element.name === 'serviceType') {
        // Find the corresponding operator ID based on selected serviceType
        const selectedService = serviceTypes.find(
          (service: any) => service.id === val,
        );
        if (selectedService) {
          updatedDetails.operator = selectedService.id;
          // Fetch data plans for the selected operator
          billStore.getBIllsByProvider(selectedService.id);
        }
      }

      if (element.name === 'billType') {
        // Update amount based on selected DataType
        const selectedOption = billTypes.find(
          (option: any) => option.name === element.value,
        );
        if (selectedOption) {
          // updatedDetails.amount = selectedOption.amount;
          updatedDetails.productId = selectedOption.id;
          updatedDetails.billType = selectedOption.name;
        }
      }
      return updatedDetails;
    });
  };

  useEffect(() => {
    setTransactionDetails((prevDetails) => ({
      ...prevDetails,
      transactionPin: pin,
    }));
  }, [pin]);

  const handlePinToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setPinToggle(e.target.checked);
  };

  const handleTransaction = (e: any) => {
    e.preventDefault();
    if (
      transactionDetails.msisdn &&
      isValidPhoneNumber(transactionDetails.msisdn)
    ) {
      const {
        serviceType,
        billType,
        accountId,
        amount,
        msisdn,
        transactionPin,
      } = transactionDetails;
      if (
        !serviceType ||
        !amount ||
        !msisdn ||
        !accountId ||
        !transactionPin ||
        !billType
      ) {
        toast.error('All input fields are required!');
        return;
      }
      if (Number(amount) < 500) {
        toast.error('Minimum Recharge Amount is ₦500');
        return;
      }

      if (toJS(multipleWallet) && toJS(multipleWallet).length > 0) {
        toJS(multipleWallet).forEach((walletData: any) => {
          if (
            walletData.walletIdAccountNumber ===
            transactionDetails?.fromWalletIdAccountNumber
          ) {
            if (
              Number(walletData.availableBalance) <
              Number(transactionDetails.amount)
              // Number(walletData.availableBalance) ===
              // Number(transactionDetails.amount)
            ) {
              toast.error(
                'You don’t have enough balance to process this transaction!',
              );
              return;
            }
          }
          billStore.purchaseElectricity(transactionDetails);
        });
      }
    } else {
      toast.error('Invalid phone number, check and try again.');
    }
  };

  const validateNumber = (evt: any) => {
    const key = String.fromCharCode(evt.keyCode || evt.which);
    const regex = /[0-9]/;
    if (!regex.test(key)) {
      evt.preventDefault();
    }
  };

  useEffect(() => {
    if (message.type === 'success') {
      setTransactionStatus(message.msg);
      setShowTransactionStatus(true);
      setTimeout(() => {
        setOpenPowerPurchaseModal(false);
        walletStore.getAllTransactions();
      }, 6000);
    } else {
      setShowTransactionStatus(false);
    }
  }, [message.msg, message.type, walletStore, setOpenPowerPurchaseModal]);

  const validateAmount = (evt: any) => {
    const key = String.fromCharCode(evt.keyCode || evt.which);
    const regex = /[0-9\.]|Backspace/;
    if (!regex.test(key)) {
      evt.preventDefault();
    }
  };

  const handleMeterVerification = async () => {
    if (!transactionDetails.accountId) {
      toast.error('Please input Meter Number to view account details');
      return;
    }
    await billStore.lookupPrepaidAccount(transactionDetails);

    setShowAmountInput(true);
  };

  return (
    <div className="bill-modal">
      <div className="bg-white modal-box">
        <span
          className="close"
          onClick={() => setOpenPowerPurchaseModal(false)}
        >
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="Close"
          />
        </span>
        <h4 className="mb-2">Buy Electricity Unit</h4>
        <hr
          className="bg-secondary"
          style={{ borderWidth: '2px', borderColor: 'gray' }}
        />

        {!showTransactionStatus ? (
          <form className="bill-payment-form" onSubmit={handleTransaction}>
            <div className="row justify-content-center">
              <div className="row justify-content-center account-box">
                {/* // BANK SELECTION */}
                {showBankSelection && (
                  <motion.div
                    initial={{ x: -200, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -200, opacity: 0 }}
                    className="sliding-input"
                  >
                    <div className="row justify-content-center banks-box">
                      <p className="mb-4 color-heading">Select a wallet</p>

                      <div className="col-12">
                        {toJS(multipleWallet) &&
                        toJS(multipleWallet)?.length > 0
                          ? toJS(multipleWallet)?.map(
                              (walletData: any, index: number) => (
                                <div className="w-100 gap-2 mt-2" key={index}>
                                  <h6 className="wallet-deets d-flex align-items-start my-3 color-heading">
                                    Wallet {index + 1}
                                  </h6>
                                  <div
                                    className="d-flex flex-wrap gap-20 align-items-center justify-content-between mb-3 wallet-deets bank-selection"
                                    onClick={() => {
                                      if (walletData?.banker === 'Providus') {
                                        if (
                                          walletData?.availableBalance < 100
                                          // walletData?.availableBalance === 0
                                        ) {
                                          toast.error(
                                            'Amount must be greater than ₦100',
                                          );
                                        } else {
                                          setTransactionDetails(
                                            (prevDetails) => {
                                              return {
                                                ...prevDetails,
                                                fromWalletIdAccountNumber:
                                                  walletData?.walletIdAccountNumber,
                                              };
                                            },
                                          );
                                          setShowBankSelection(false);
                                          setShowAmountInput(true);
                                        }
                                      } else if (
                                        walletData.banker ===
                                        '9 Payment Service Bank'
                                      ) {
                                        if (
                                          Number(toJS(seerbitWalletBallance)) <
                                          100
                                          // Number(
                                          //   toJS(seerbitWalletBallance),
                                          // ) === 0
                                        ) {
                                          toast.error(
                                            'Amount must be greater than ₦100',
                                          );
                                        } else {
                                          setTransactionDetails(
                                            (prevDetails) => {
                                              return {
                                                ...prevDetails,
                                                fromWalletIdAccountNumber:
                                                  walletData?.walletIdAccountNumber,
                                              };
                                            },
                                          );
                                          setShowBankSelection(false);
                                          setShowAmountInput(true);
                                        }
                                      }
                                    }}
                                  >
                                    <h6>{walletData?.banker}</h6>
                                    <p>
                                      ₦{' '}
                                      {walletData?.banker === 'Providus'
                                        ? walletData?.availableBalance !=
                                            'NaN' ||
                                          walletData?.availableBalance !=
                                            null ||
                                          walletData?.availableBalance !=
                                            undefined
                                          ? walletData?.availableBalance
                                            ? new Intl.NumberFormat('en-US', {
                                                style: 'decimal',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }).format(
                                                walletData?.availableBalance,
                                              )
                                            : '0.00'
                                          : '0.00'
                                        : walletData?.banker ===
                                          '9 Payment Service Bank'
                                        ? toJS(seerbitWalletBallance) !=
                                            'NaN' ||
                                          toJS(seerbitWalletBallance) != null ||
                                          toJS(seerbitWalletBallance) !=
                                            undefined
                                          ? wallet?.availableBalance
                                            ? new Intl.NumberFormat('en-US', {
                                                style: 'decimal',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }).format(
                                                Number(
                                                  toJS(seerbitWalletBallance),
                                                ),
                                              )
                                            : '0.00'
                                          : '0.00'
                                        : '0.00'}
                                    </p>
                                  </div>
                                </div>
                              ),
                            )
                          : null}
                      </div>
                    </div>
                  </motion.div>
                )}

                {!showBankSelection && (
                  <motion.div
                    initial={{ x: -200, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -200, opacity: 0 }}
                    className="sliding-input"
                  >
                    <div className="col-12 styled-phone-input">
                      <label
                        htmlFor="phone"
                        className="mb-1 mt-2 text-start d-block"
                      >
                        Mobile Number
                      </label>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="NG"
                        onChange={(e: string) =>
                          setTransactionDetails((prevDetails) => ({
                            ...prevDetails,
                            msisdn: e,
                          }))
                        }
                        className="form-control p-3"
                        id="phone"
                        disabled={submitting}
                        value={transactionDetails.msisdn}
                        rules={{ required: true }}
                        autoComplete="off"
                      />
                    </div>
                  </motion.div>
                )}

                {!showBankSelection &&
                  transactionDetails.msisdn !== undefined &&
                  isValidPhoneNumber(transactionDetails?.msisdn) && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input"
                    >
                      <div className="col-12">
                        <label htmlFor="serviceType" className="mb-1 mt-3">
                          Service Provider
                        </label>
                        <select
                          name="serviceType"
                          id="serviceType"
                          className="form-control p-3"
                          onChange={handleInputChange}
                          value={transactionDetails.serviceType}
                          disabled={submitting ? true : false}
                          required
                        >
                          <option value="">Select Provider</option>
                          {serviceTypes && serviceTypes.length > 0
                            ? serviceTypes.map(
                                (serviceType: any, index: number) => (
                                  <option key={index} value={serviceType.id}>
                                    {serviceType.name}
                                  </option>
                                ),
                              )
                            : defaultData.map(
                                (serviceType: any, index: number) => (
                                  <option
                                    key={index}
                                    value={serviceType.serviceType}
                                  >
                                    {serviceType.name}
                                  </option>
                                ),
                              )}
                        </select>
                      </div>
                    </motion.div>
                  )}

                {!showBankSelection &&
                  transactionDetails.serviceType !== undefined &&
                  transactionDetails.serviceType !== '' && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input"
                    >
                      <div className="col-12">
                        <label htmlFor="billType" className="mb-1 mt-3">
                          Bill Payment For
                        </label>
                        <select
                          name="billType"
                          id="billType"
                          className="form-control p-3"
                          onChange={handleInputChange}
                          value={transactionDetails.billType}
                          required
                          disabled={submitting ? true : false}
                        >
                          <option value="">Select plan</option>
                          {billTypes &&
                          billTypes !== null &&
                          billTypes !== undefined &&
                          billTypes &&
                          billTypes.length > 0
                            ? billTypes.map((billType: any, index: number) => (
                                <option
                                  key={index}
                                  value={billType.name}
                                  accessKey={billType.amount}
                                >
                                  {billType.name}
                                </option>
                              ))
                            : defaultPlan.map(
                                (dataTypes: any, index: number) => (
                                  <option
                                    key={index}
                                    value={dataTypes.datacode}
                                    accessKey={dataTypes.price}
                                  >
                                    {dataTypes.name}
                                  </option>
                                ),
                              )}
                        </select>
                      </div>
                    </motion.div>
                  )}

                {!showBankSelection &&
                  transactionDetails.serviceType !== undefined &&
                  transactionDetails.serviceType !== '' &&
                  transactionDetails.billType !== '' &&
                  transactionDetails.accountId !== '' &&
                  billStore.meterDetails && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input"
                    >
                      <div className="col-12 mt-3">
                        <h6
                          className=""
                          style={{ color: '#064b99', textAlign: 'center' }}
                        >
                          {fullDetails.customerName}
                        </h6>
                      </div>
                    </motion.div>
                  )}

                {!showBankSelection &&
                  transactionDetails.serviceType !== undefined &&
                  transactionDetails.serviceType !== '' &&
                  transactionDetails.billType !== '' && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input"
                    >
                      <div className="col-12">
                        <label htmlFor="accountId" className="mb-1 mt-3">
                          Meter Number
                        </label>
                        <Input
                          onKeyPress={validateNumber}
                          onChange={handleInputChange}
                          name="accountId"
                          classes="form-control p-3"
                          type="text"
                          id="accountId"
                          value={transactionDetails.accountId}
                          autoComplete="off"
                          disabled={submitting}
                          required
                        />
                        {/* Conditional rendering based on the presence of customerName */}
                        {!fullDetails.customerName && (
                          <div className="text-center">
                            <button
                              type="button"
                              className="btn mt-3"
                              onClick={handleMeterVerification}
                              disabled={submitting}
                              style={{
                                backgroundColor: '#064b99',
                                color: 'white',
                                textAlign: 'center',
                              }}
                            >
                              Validate AccountID
                            </button>
                          </div>
                        )}
                      </div>
                    </motion.div>
                  )}

                {!showBankSelection &&
                  transactionDetails.serviceType !== undefined &&
                  transactionDetails.serviceType !== '' &&
                  transactionDetails.billType !== '' &&
                  transactionDetails.accountId !== '' &&
                  billStore.meterDetails &&
                  fullDetails.customerName && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input"
                    >
                      <div className="col-12 mt-3">
                        <label
                          htmlFor="amount"
                          className="mb-1 text-start d-block"
                        >
                          Amount
                        </label>
                        <Input
                          onKeyPress={validateAmount}
                          onChange={handleInputChange}
                          name="amount"
                          classes="form-control mb-3 p-3"
                          type="number"
                          id="amount"
                          value={transactionDetails.amount}
                        />
                      </div>
                    </motion.div>
                  )}

                {!showBankSelection &&
                  showAmountInput &&
                  !showTransactionStatus &&
                  transactionDetails.amount !== undefined &&
                  transactionDetails.amount !== '' &&
                  transactionDetails.amount.toString().length >= 3 && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input"
                    >
                      <div className="col-12 input-box">
                        <label htmlFor="showPin" className="mb-1 mt-3">
                          Transaction Pin
                        </label>
                        <div className="">
                          <OtpInput
                            value={pin}
                            onChange={setPin}
                            numInputs={4}
                            inputStyle="pin-style"
                            containerStyle="pin-container-style"
                            inputType={pinToggle ? 'tel' : 'password'}
                            shouldAutoFocus={true}
                            renderInput={(props) => (
                              <input
                                onKeyPress={validateNumber}
                                {...props}
                                disabled={submitting ? true : false}
                              />
                            )}
                          />
                        </div>
                        <div className="d-flex gap-2 justify-content-start align-items-center mb-3">
                          <input
                            type="checkbox"
                            onChange={handlePinToggle}
                            name="showPin"
                            id="show-pin"
                          />
                          <label htmlFor="show-pin">Show pin</label>
                        </div>
                        <p style={{ fontSize: '14px', textAlign: 'left' }}>
                          Don't have a pin yet? Create one{' '}
                          <Link
                            style={{ color: 'blue' }}
                            to="/dashboard/settings/create-pin"
                          >
                            here
                          </Link>{' '}
                        </p>
                      </div>
                    </motion.div>
                  )}
              </div>

              <div className="row justify-content-start mt-2">
                <div className="col-11">
                  <div className="group-btn justify-content-start">
                    {!showBankSelection &&
                      transactionDetails.transactionPin !== '' &&
                      transactionDetails.transactionPin.length === 4 && (
                        <motion.div
                          initial={{ x: -200, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          exit={{ x: -200, opacity: 0 }}
                          className="sliding-input"
                        >
                          <Button
                            classes="primary-btn btn-md"
                            disabled={submitting}
                            content={`${
                              submitting ? 'Processing...' : 'Proceed'
                            }`}
                            type="submit"
                          />
                        </motion.div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -200, opacity: 0 }}
            className="sliding-input"
          >
            <div>
              <h6
                className={
                  message.type === 'success'
                    ? ' alert alert-success mb-0 text-center m-3'
                    : 'alert alert-danger mb-0 text-center m-3'
                }
                role="alert"
              >
                {transactionStatus}
              </h6>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default observer(NewElectricityPurchase);
