import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import Icon from '@mdi/react';
import {
  mdiChartBox,
  mdiCircleSmall,
  mdiDotsVertical,
  mdiWalletOutline,
} from '@mdi/js';
import BaseDirectories from '../../../base directories/BaseDirectories';

const CashBackCard = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={pagination}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          450: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
            autoplay: {
              delay: 500,
              disableOnInteraction: false,
            },
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          1184: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        }}
        modules={[Pagination]}
        className="lp-career-container mySwiper"
      >
        <SwiperSlide>
          <div className="bg-white pt-2 ps-4">
            <p className="my-3 text-2xl">Transaction</p>
            <div className="d-flex gap-3 mt-3 py-0 mb-lg-4">
              <div className="">
                <p>OUT</p>
                <h4>N300,000</h4>
              </div>
              <div className="border-end" style={{ height: '50px' }}></div>
              <div className="">
                <p>IN</p>
                <h4 className="">N300,000</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-white pt-2 ps-4">
            <p className="my-3">
              <Icon path={mdiChartBox} size={1} />
              Total Balance
            </p>
            <div className="mt-3 py-0 mb-lg-4">
              <h4 className="text-success">N300,400</h4>
              <p className="text-uppercase mt-3">Increase 12% vs last month</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-white pt-2 ps-4">
            <p className="my-3">
              <Icon path={mdiWalletOutline} size={1} />
              Today's Balance
            </p>
            <div className="mt-3 py-0 mb-lg-4">
              <h4 className="text-primary">N300,400</h4>
              <p className="text-uppercase mt-3">16% Increase</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="bg-white table-responsive">
        <table className="table table-striped head-color responsive-table mb-0">
          <thead
            style={{
              borderBottom: '1px solid black',
            }}
          >
            <tr>
              <th>
                <input type="checkbox" />
              </th>
              <th>Product</th>
              <th>Customer</th>
              <th>Purchase Date</th>
              <th>Status</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="d-flex align-content-center">
                <input type="checkbox" />
                <Icon path={mdiDotsVertical} size={0.8} className="view-icon" />
              </td>
              <td>Fruit Package</td>
              <td>
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/user1.png`}
                  alt=""
                  style={{
                    width: '12%',
                    borderRadius: '50px',
                    // paddingRight: '10px',
                  }}
                  className="mx-2"
                />
                <span>John Doe</span>
              </td>
              <td>07 Aug 4:32pm</td>
              <td>
                <Icon path={mdiCircleSmall} size={1} /> Paid
              </td>
              <td>N20,000.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CashBackCard;
