/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StoreContext } from '../../mobx_stores/RootStore';
import Button from '../ui/Button';
import Input from '../ui/Input';
import Spinner from '../ui/Spinner';
import Skeleton from 'react-loading-skeleton';
import PhoneInput from 'react-phone-number-input';

interface INextOfKinFormProps {
  next: () => void;
  previous: any;
  setX: Function;
}

const gender = {
  male: 'Male',
  female: 'Female',
  preferNotToSay: 'Prefer Not To Say',
};

const NextOfKinForm: React.FunctionComponent<INextOfKinFormProps> = ({
  next,
  previous,
  setX,
}) => {
  const { loansStore } = React.useContext(StoreContext);

  const { sending, loading, success, message } = loansStore;

  const [nOKDetails, setNOKDetails] = React.useState({
    firstName: '',
    lastName: '',
    gender: '',
    relationship: '',
    email: '',
    phoneNumber: '',
    Address: '',
  });

  const [allLoanDetails, setAllLoanDetails] = React.useState<any>({});

  React.useEffect(() => {
    setAllLoanDetails(toJS(loansStore.loanUserdetails));
  }, [loansStore.loanUserdetails]);

  React.useEffect(() => {
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  const nokDeets = allLoanDetails.nextOfKinDetails;

  React.useEffect(() => {
    setNOKDetails({
      firstName:
        allLoanDetails && nokDeets?.firstName === undefined
          ? ''
          : nokDeets?.firstName,
      lastName:
        allLoanDetails && nokDeets?.lastName === undefined
          ? ''
          : nokDeets?.lastName,
      gender:
        allLoanDetails && nokDeets?.gender === undefined
          ? ''
          : nokDeets?.gender,
      relationship:
        allLoanDetails && nokDeets?.relationship === undefined
          ? ''
          : nokDeets?.relationship,
      email:
        allLoanDetails && nokDeets?.email === undefined ? '' : nokDeets?.email,
      phoneNumber:
        allLoanDetails && nokDeets?.phoneNumber === undefined
          ? ''
          : nokDeets?.phoneNumber,
      Address:
        allLoanDetails && nokDeets?.Address === undefined
          ? ''
          : nokDeets?.Address,
    });
  }, [allLoanDetails, nokDeets]);

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setNOKDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: element.value };
    });

    if (
      element.value !== '' &&
      element.style.border === '2px solid rgb(255, 137, 137)'
    ) {
      element.style.border = '1px solid #ced4da';
    }
  };

  const [phoneNumber, setPhoneNumber] = React.useState('');
  React.useEffect(() => {
    setNOKDetails((prevDetails: any) => {
      return { ...prevDetails, phoneNumber };
    });
  }, [phoneNumber]);

  const handleCreateNOK = (e: React.FormEvent) => {
    e.preventDefault();

    loansStore.createNextOfKin(nOKDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          nOKDetails[input.name] === '' ||
          nOKDetails[input.name] === 'NULL'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 30000);
        }
      });
    }
  };

  const handleUpdateNOK = (e: React.FormEvent) => {
    e.preventDefault();

    loansStore.updateNokDetails(nOKDetails);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          nOKDetails[input.name] === '' ||
          nOKDetails[input.name] === 'NULL'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 30000);
        }
      });
    }
  };

  React.useEffect(() => {
    if (success === 'Next of kin details created successfully') {
      next();
      setX(1000);
    }
    if (success === 'Next of kin details updated successfully') {
      next();
      setX(1000);
    }
  }, [next, setX, success]);

  const handleSubmit =
    allLoanDetails.nextOfKinDetails === undefined
      ? handleCreateNOK
      : handleUpdateNOK;

  return (
    <div>
      {/* <Toaster
        position="top-right"
        reverseOrder={true}
        containerStyle={{
          top: 120,
        }}
        toastOptions={{
          success: {
            duration: 5000,
          },
          error: {
            duration: 5000,
          },
          style: {
            gap: '10px',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== 'loading' && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{ width: '50px', background: 'white' }}
                  >
                    {icon}
                  </button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster> */}

      <form action="" onSubmit={handleSubmit}>
        <div className="py-5 px-4 bg-white rounded mt-4">
          {sending && <Spinner />}

          {loading ? (
            <div>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 pe-md-3 pe-lg-4">
                  <div className="col-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="profile-btn">
                    <Skeleton baseColor="#FAFAFA" width={200} height={45} />
                    <Skeleton baseColor="#FAFAFA" width={200} height={45} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 pe-md-3 pe-lg-4">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="firstName" className="mb-1 mt-3">
                        First Name
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <Input
                        name="firstName"
                        classes="form-control"
                        type="text"
                        defaultValue={nokDeets?.firstName}
                        id="firstName"
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-6">
                      <label htmlFor="lastName" className="mb-1 mt-3">
                        Last Name
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <Input
                        name="lastName"
                        classes="form-control"
                        type="text"
                        defaultValue={nokDeets?.lastName}
                        id="lastName"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="gender" className="mb-1 mt-3">
                      Gender
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      onChange={handleInputChange}
                      className="form-control"
                      defaultValue={nokDeets?.gender}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value={gender.female}>{gender.female}</option>
                      <option value={gender.male}>{gender.male}</option>
                      <option value={gender.preferNotToSay}>
                        {gender.preferNotToSay}
                      </option>
                    </select>
                  </div>

                  <div className="col-12">
                    <label htmlFor="relationship" className="mb-1 mt-3">
                      Next of kin’s Relationship
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="relationship"
                      classes="form-control"
                      type="text"
                      id="relationship"
                      defaultValue={nokDeets?.relationship}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="relationship" className="mb-1 mt-3">
                      Email Address
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="email"
                      classes="form-control"
                      type="email"
                      id="email"
                      defaultValue={nokDeets?.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="phoneNumber" className="mb-1 mt-3">
                      Mobile number
                      <span style={{ color: 'red' }}> *</span>
                    </label>

                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="NG"
                      onChange={(e: string) => setPhoneNumber(e)}
                      className="form-control"
                      id="phoneNumber"
                      required
                      value={
                        nokDeets?.phoneNumber === undefined
                          ? ''
                          : nokDeets?.phoneNumber
                      }
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="Address" className="mb-1 mt-3">
                      Address
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="Address"
                      classes="form-control"
                      type="text"
                      id="Address"
                      defaultValue={nokDeets?.Address}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="profile-btn">
                    <Button
                      classes="primary-btn btnn"
                      content="Previous"
                      onClick={() => {
                        previous();
                        setX(-1000);
                      }}
                    />
                    <Button
                      classes="primary-btn btnn"
                      content={
                        allLoanDetails.nextOfKinDetails === undefined
                          ? 'Save and Continue'
                          : 'Update Details'
                      }
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default observer(NextOfKinForm);
