import LoansCard from './LoansCard';
import LoansAmountCard from './LoansAmountCard';
import { useContext } from 'react';
import { StoreContext } from '../../../ mobx stores/RootStore';

const Loans = () => {
  const { loansStore } = useContext(StoreContext);
  const { loanUserdetails } = loansStore;

  return (
    <div className="loans">
      <div className="section-head">
        {' '}
        {loanUserdetails?.organizationDetails?.shariaCom ? 'Facility' : 'Loans'}
      </div>

      <div className="loans-container">
        <LoansAmountCard />

        <LoansCard />
      </div>
    </div>
  );
};

export default Loans;
