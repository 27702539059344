/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { FaTimes } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import { auth } from '../../../firebase/Firebase';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { toast } from 'react-toastify';

type Props = {
  userEmail?: string;
  setOpenPinModal: (state: any) => void;
};

const ResetPinModal: FC<Props> = ({ userEmail, setOpenPinModal }) => {
  const [password, setPassword] = useState(' ');
  const { profileStore } = useContext(StoreContext);
  const { submitting, message } = profileStore;

  const verifyUser = async () => {
    const user: any = auth.currentUser;

    if (user) {
      const credentials = EmailAuthProvider.credential(user.email, password);

      await reauthenticateWithCredential(user, credentials)
        .then(() => {
          profileStore.resetPin();
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((error) => {
          toast.error('Invalid Password!');
        });
    }
  };

  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Pin reset successfully!'
    ) {
      setOpenPinModal(false);
    }
  }, [message.type, message.msg]);

  return (
    <div className="notification-modal">
      <div
        className="bg-white notification-box"
        style={{ width: '90%', maxWidth: '500px' }}
      >
        <span
          onClick={() => {
            setOpenPinModal(false);
          }}
          className="close"
          role="button"
        >
          <FaTimes />
        </span>
        <h5 className="" style={{ fontSize: '22px', alignSelf: 'start' }}>
          Email Address
        </h5>
        <div className="input-group">
          <Input
            name="email"
            classes="form-control"
            type="text"
            id="email"
            defaultValue={userEmail}
            readonly
          />
        </div>

        <h5
          className="text-align-left"
          style={{ fontSize: '22px', alignSelf: 'start' }}
        >
          Password
        </h5>
        <div className="input-group">
          <Input
            name="password"
            classes="form-control"
            type="password"
            id="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>

        <div className="group-btn">
          <Button
            onClick={() => verifyUser()}
            classes="primary-btn w-auto btn-lg waves-effect"
            content={`${submitting ? 'Resetting...' : 'Verify Account'}`}
            disabled={submitting}
            type="submit"
          />
        </div>
      </div>
    </div>
  );
};

export default observer(ResetPinModal);
