/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import GuarantorNotification from './GuarantorNotification';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

type Guarantors = {
  [key: string]: any;
};

const AllGuarantors = () => {
  const [guarantors, setGuarantors] = useState<Guarantors>([]);
  // const [guarantorName] = useState("Fola Brown");
  const [remove, setRemove] = useState(false);

  const { guarantorStore } = useContext(StoreContext);

  useEffect(() => {
    guarantorStore.getGuarantors();
  }, [guarantorStore]);

  useEffect(() => {
    setGuarantors(toJS(guarantorStore.guarantors));
  }, [guarantorStore.guarantors]);

  const [screen, setScreen] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(screen > 767 ? false : true);

  useEffect(() => {
    function handleResize() {
      setScreen(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    if (screen > 767) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [screen]);

  return (
    <>
      <h4 className="section-head">Guarantors</h4>
      {guarantorStore.loadingData ? (
        <div>
          <div className="search-group">
            <div className="search-box">
              <Skeleton baseColor="#FAFAFA" width={370} height={45} />
            </div>
            <Skeleton baseColor="#FAFAFA" width={160} height={45} />
          </div>
          <div className="bg-white guarantor-list">
            <h5>
              <Skeleton width={170} height={25} />
            </h5>

            <div className="bg-white table-responsive">
              <table className="table table-striped responsive-table mb-0">
                <thead>
                  <tr>
                    <th>
                      <Skeleton baseColor="#FCFCFC" width={150} height={25} />
                    </th>
                    <th>
                      <Skeleton baseColor="#FCFCFC" width={220} height={25} />
                    </th>
                    <th>
                      <Skeleton baseColor="#FCFCFC" width={120} height={25} />
                    </th>
                    <th>
                      <Skeleton baseColor="#FCFCFC" width={120} height={25} />
                    </th>
                    <th>&nbsp;</th>
                    {/* <th>&nbsp;</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Skeleton width={150} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={120} height={25} />
                    </td>
                    <td>
                      <Skeleton width={120} height={25} />
                    </td>
                    <td>
                      <span className="d-flex align-items-center">
                        <span className="">
                          <Skeleton width={70} height={35} />
                        </span>
                        {/* <Skeleton width={25} height={25} /> */}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={150} height={25} />
                    </td>
                    <td>
                      <Skeleton width={220} height={25} />
                    </td>
                    <td>
                      <Skeleton width={120} height={25} />
                    </td>
                    <td>
                      <Skeleton width={120} height={25} />
                    </td>
                    <td>
                      <span className="d-flex align-items-center">
                        <span className="">
                          <Skeleton width={70} height={35} />
                        </span>
                        {/* <Skeleton width={25} height={25} /> */}
                      </span>
                    </td>
                  </tr>
                  <tr className="d-none">
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="navigation">
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
              <Skeleton width={40} height={40} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          {remove && (
            <GuarantorNotification
              image={`${BaseDirectories.IMAGES_DIR}/cancel.png`}
              body="Are you sure you want to remove this Guarantor?"
              button={true}
              btnText1="Remove"
              btnText2="No"
              close={() => setRemove(false)}
            />
          )}
          <h4 className="section-head">Guarantors</h4>
          <div className="search-group">
            <Button
              content="Add Guarantor"
              title="Add Guarantor"
              classes="primary-btn btn-lg add-btn"
              route="/dashboard/guarantors/add_guarantor"
            />
            {!mobile ? (
              <div className="search-box">
                <Icon path={mdiMagnify} size={1} className="search-icon" />
                <input type="text" placeholder="Search" />
              </div>
            ) : (
              <h4 className="section-head2">Guarantors</h4>
            )}
          </div>
          {mobile && (
            <div className="search-group">
              <div className="search-box">
                <Icon path={mdiMagnify} size={1} className="search-icon" />
                <input type="text" placeholder="Search" />
              </div>
            </div>
          )}
          {guarantors && guarantors.length === 0 ? (
            <div className="bg-white no-guarantor">
              <div className="empty-state-img">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/emptystate.png`}
                  alt="No guarantor"
                />
              </div>
              <p>Oops!</p>
              <p>You do not have a Guarantor yet! </p>
              <Button
                content="Add Guarantor"
                title="Add Guarantor"
                classes="primary-btn btn-lg add-btn add-btn2"
                route="/dashboard/guarantors/add_guarantor"
              />
            </div>
          ) : (
            <div className="bg-white guarantor-list">
              <h5>My Guarantors</h5>

              <div className="bg-white table-responsive">
                <table className="table table-striped head-color responsive-table mb-0">
                  <thead
                    className="text-white"
                    style={{ backgroundColor: '#054b99' }}
                  >
                    <tr>
                      <th>Full name</th>
                      {!mobile && (
                        <>
                          <th>Email</th>
                          <th>Phone number</th>
                        </>
                      )}
                      <th>Status</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {guarantors.map((guarantor: any, i: number) => (
                      <tr key={i}>
                        <td>
                          {guarantor.firstName} {guarantor.lastName}
                        </td>
                        {!mobile && (
                          <>
                            <td className="hide">{guarantor.email}</td>
                            <td className="hide">{guarantor.phoneNumber}</td>
                          </>
                        )}
                        <td
                          className={`${guarantor.status}`}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {guarantor.status}
                        </td>
                        <td>
                          {mobile ? (
                            <Link
                              className="btn"
                              title="View more"
                              to={`/dashboard/guarantors/${guarantor.firstName}_${guarantor.lastName}`}
                              state={{ id: guarantor._id }}
                            >
                              Open
                            </Link>
                          ) : (
                            <Link
                              className="btn primary-btn"
                              title="View more"
                              to={`/dashboard/guarantors/${guarantor.firstName}_${guarantor.lastName}`}
                              state={{ id: guarantor._id }}
                            >
                              Open
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr className="d-none">
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default observer(AllGuarantors);
