/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import { observer } from 'mobx-react-lite';
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { StoreContext } from '../../../mobx_stores/RootStore';
import Button from '../../../components/ui/Button';
import Spinner from '../../../components/ui/Spinner';
import Icon from '@mdi/react';
import { mdiEyeOff, mdiEye, mdiLockOpenCheck } from '@mdi/js';
import { toJS } from 'mobx';
import ResetPinModal from './ResetPinModal';
import axios from 'axios';
import BaseDirectories from '../../../base directories/BaseDirectories';

const Pin: FC = () => {
  const { profileStore } = useContext(StoreContext);
  const { profile, isPinExist } = profileStore;
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [pinToggle, setPinToggle] = useState(false);
  // const [pinExist, setPinExist] = useState(false);
  const [pin, setPin] = useState({
    newPin: 'string',
    confirmPin: 'string',
  });
  const [oldPin, setOldPin] = useState('');
  const [changeNewPin, setChangeNewPin] = useState('');
  const [confirmChangeNewPin, setConfirmChangeNewPin] = useState('');
  const [updatePin, setUpdatePin] = useState({
    oldPin: 'string',
    newPin: 'string',
    confirmPin: 'string',
  });

  const { submitting, message } = profileStore;
  const location: any = useLocation();
  const [showResetPinModal, setShowResetPinModal] = useState(false);

  useEffect(() => {
    setPin({
      newPin,
      confirmPin,
    });
  }, [newPin, confirmPin]);

  useEffect(() => {
    profileStore.checkPin();
    profileStore.setPin;
  }, []);

  const pinExist = isPinExist;

  const handleCreatePin = () => {
    if (
      pin.newPin !== pin.confirmPin ||
      pin.newPin === '' ||
      pin.confirmPin === ''
    ) {
      toast.error('Pins do not match');
      return;
    }
    profileStore.createPin(pin);
  };

  useEffect(() => {
    setUpdatePin({
      oldPin,
      newPin: changeNewPin,
      confirmPin: confirmChangeNewPin,
    });
  }, [oldPin, changeNewPin, confirmChangeNewPin]);

  const handleChangePin = () => {
    if (updatePin.oldPin === '') {
      toast.error('Invalid old pin');
      return;
    }
    if (
      updatePin.newPin !== updatePin.confirmPin ||
      updatePin.newPin === '' ||
      updatePin.confirmPin === ''
    ) {
      toast.error('Pin do not match');
      return;
    }
    profileStore.updatePin(updatePin);
  };
  const handleShowVerificationModal = () => {
    setShowResetPinModal(true);
    // in the property of the modal sent email prop to modal as string type
  };

  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Pin Update is successfully!'
    ) {
      setOldPin('');
      setChangeNewPin('');
      setConfirmChangeNewPin('');
    }
  }, [message.type, message.msg]);

  return (
    <div className="pin">
      {submitting && <Spinner />}
      <div className="blue-line"></div>
      {showResetPinModal && (
        <ResetPinModal
          setOpenPinModal={setShowResetPinModal}
          userEmail={toJS(profile)?.email}
        />
      )}

      <div className="form-box px-4 text-center">
        <div className="row justify-content-center mt-4">
          {!submitting && pinExist ? (
            <div className="col-lg-5 col-md-10">
              <div className="col-md-12 input-box mb-3">
                <div className="text-center">
                  <Icon
                    path={mdiLockOpenCheck}
                    size={3}
                    color="#06A77D"
                    className=""
                  />
                  <h3>PIN</h3>
                </div>

                <div>
                  <label className="mb-2">
                    Enter Old Pin
                    <span style={{ color: 'red' }}> *</span>
                  </label>
                  <OtpInput
                    value={oldPin}
                    onChange={setOldPin}
                    numInputs={4}
                    inputStyle="pin-style"
                    containerStyle="pin-container-style"
                    inputType={pinToggle ? 'tel' : 'password'}
                    shouldAutoFocus={true}
                    renderInput={(props) => (
                      <input onKeyPress={validateNumber} {...props} />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-12 input-box mb-3">
                <div>
                  <label className="mb-2">
                    Enter New Pin
                    <span style={{ color: 'red' }}> *</span>
                  </label>
                  <OtpInput
                    value={changeNewPin}
                    onChange={setChangeNewPin}
                    numInputs={4}
                    inputStyle="pin-style"
                    containerStyle="pin-container-style"
                    inputType={pinToggle ? 'tel' : 'password'}
                    shouldAutoFocus={true}
                    renderInput={(props) => (
                      <input onKeyPress={validateNumber} {...props} />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-12 input-box">
                <div>
                  <label className="mb-2">
                    Confirm New Pin
                    <span style={{ color: 'red' }}> *</span>
                  </label>
                  <OtpInput
                    value={confirmChangeNewPin}
                    onChange={setConfirmChangeNewPin}
                    numInputs={4}
                    inputStyle="pin-style"
                    containerStyle="pin-container-style"
                    inputType={pinToggle ? 'tel' : 'password'}
                    shouldAutoFocus={false}
                    renderInput={(props) => (
                      <input onKeyPress={validateNumber} {...props} />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center align-items-center mb-3">
                <input
                  type="checkbox"
                  onChange={handlePinToggle}
                  name="showPin"
                  id="show-pin"
                />
                <label htmlFor="show-pin">Show pin</label>
              </div>
            </div>
          ) : (
            <div className="col-lg-5 col-md-10">
              <div className="col-md-12 input-box mb-3">
                <div>
                  <label className="mb-2">
                    Enter Pin
                    <span style={{ color: 'red' }}> *</span>
                  </label>
                  <OtpInput
                    value={newPin}
                    onChange={setNewPin}
                    numInputs={4}
                    inputStyle="pin-style"
                    containerStyle="pin-container-style"
                    inputType={pinToggle ? 'tel' : 'password'}
                    shouldAutoFocus={true}
                    renderInput={(props) => (
                      <input onKeyPress={validateNumber} {...props} />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-12 input-box">
                <div>
                  <label className="mb-2">
                    Confirm Pin
                    <span style={{ color: 'red' }}> *</span>
                  </label>
                  <OtpInput
                    value={confirmPin}
                    onChange={setConfirmPin}
                    numInputs={4}
                    inputStyle="pin-style"
                    containerStyle="pin-container-style"
                    inputType={pinToggle ? 'tel' : 'password'}
                    shouldAutoFocus={false}
                    renderInput={(props) => (
                      <input onKeyPress={validateNumber} {...props} />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center align-items-center mb-3">
                <input
                  type="checkbox"
                  onChange={handlePinToggle}
                  name="showPin"
                  id="show-pin"
                />
                <label htmlFor="show-pin">Show pin</label>
              </div>
            </div>
          )}
          <div className="d-flex flex-wrap justify-content-center text-center mt-2">
            <div className="profile-btn m-2">
              {!submitting && pinExist ? (
                <Button
                  type="button"
                  classes={`${
                    submitting
                      ? 'primary-btn-disabled btn-lg btn-block px-4'
                      : 'primary-btn btn-lg btn-block px-4'
                  }`}
                  content={`${submitting ? 'Changing...' : 'Change Pin'}`}
                  disabled={submitting}
                  onClick={() => {
                    handleChangePin();
                  }}
                />
              ) : (
                <Button
                  type="button"
                  classes={`${
                    submitting
                      ? 'primary-btn-disabled btn-lg btn-block px-4'
                      : 'primary-btn btn-lg btn-block px-4'
                  }`}
                  content={`${submitting ? 'Creating...' : 'Create Pin'}`}
                  disabled={submitting}
                  onClick={() => {
                    handleCreatePin();
                  }}
                />
              )}
            </div>
            {!submitting && isPinExist ? (
              <div className="profile-btn m-2">
                <Button
                  type="button"
                  classes={`${
                    submitting
                      ? 'primary-btn-disabled btn-lg btn-block px-4'
                      : 'primary-btn btn-lg btn-block px-4'
                  }`}
                  content={`${submitting ? 'Resetting...' : 'Reset Pin'}`}
                  disabled={submitting}
                  onClick={() => handleShowVerificationModal()}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Pin);
