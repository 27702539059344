import React, { FC, useState, useEffect, useRef } from 'react';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { Helmet } from 'react-helmet-async';
import Header from '../../pages/landingPage/sections/Header';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import locationsData from '../../data/states.json';
// import { handleFormSubmit } from '../../formHandler';

const validEmailProviders = [
  'gmail.com',
  'yahoo.com',
  'yahoo.co.uk',
  'outlook.com',
  'hotmail.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'zoho.com',
  'yandex.com',
  'protonmail.com',
  'gmx.com',
  'mail.ru',
  'inbox.com',
  'me.com',
  'live.com',
  'msn.com',
  'ymail.com',
  'rocketmail.com',
  'att.net',
  'verizon.net',
  'comcast.net',
  'sbcglobal.net',
  'bellsouth.net',
  'charter.net',
  'cox.net',
  'earthlink.net',
  'juno.com',
  'frontier.com',
  'windstream.net',
  'netzero.net',
  'tradelenda.com',
  'adewunmi.com',
];

interface UserDetails {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  state: string;
  city: string;
  nin: string;
  countryCode: string;
}

const HSignupForm: FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [ninError, setNinError] = useState('');
  const [userDetails, setUserDetails] = useState<UserDetails>({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    state: '',
    city: '',
    nin: '',
    countryCode: '',
  });

  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [selectedState, setSelectedState] = useState<string>('');

  useEffect(() => {
    // Load states from JSON data
    const loadedStates = locationsData.map((location: any) => location.name);
    setStates(loadedStates);
  }, []);

  useEffect(() => {
    // Load cities when state changes
    const stateData = locationsData.find(
      (location: any) => location.name === selectedState,
    );
    if (stateData) {
      setCities(stateData.cities);
    } else {
      setCities([]);
    }
  }, [selectedState]);

  const validateEmailDomain = (email: string) => {
    const domain = email.split('@')[1];
    return validEmailProviders.includes(domain);
  };

  const validateNIN = (nin: string) => {
    return /^\d{11}$/.test(nin);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    e.preventDefault();
    const element = e.target as HTMLInputElement | HTMLSelectElement;
    const value = element.value.trim();

    if (element.name === 'state') {
      setSelectedState(value);
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        state: value,
        city: '', // Reset city when state changes
      }));
    } else {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [element.name]: value,
      }));

      if (element.name === 'email') {
        const isValidDomain = validateEmailDomain(value);
        setEmailError(
          isValidDomain
            ? ''
            : 'Please enter a valid email address from a supported provider.',
        );
      }
      if (element.name === 'nin') {
        const isValidNIN = validateNIN(value);
        setNinError(isValidNIN ? '' : 'NIN must be exactly 11 digits.');
      }
    }
  };

  useEffect(() => {
    if (phoneNumber && !/^\+234\d{10}$/.test(phoneNumber)) {
      setPhoneNumberError('Please Enter a valid Nigerian phone number');
    } else {
      setPhoneNumberError('');
    }
  }, [phoneNumber]);

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const recaptchaValue = recaptchaRef.current?.getValue();
  const handleCaptchachange = () => {
    return;
  };

  // useEffect(() => {
  //   handleFormSubmit(
  //     'https://script.google.com/macros/s/AKfycbzcLVp3z42LZ-aECakHSu3jGhN3g0ww6bKwWUmTpHnufHPMdsnn9PpTr77WU1Yg5CQSfw/exec',
  //   );
  // }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current?.getValue();

    if (!recaptchaValue) {
      alert('Please complete the ReCAPTCHA.');
      return;
    }

    const formData = new FormData(e.currentTarget);
    formData.append('recaptcha', recaptchaValue);

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbzcLVp3z42LZ-aECakHSu3jGhN3g0ww6bKwWUmTpHnufHPMdsnn9PpTr77WU1Yg5CQSfw/exec',
        {
          method: 'POST',
          body: formData,
        },
      );
      if (response.ok) {
        alert('Thank you! Your form is submitted successfully.');
        recaptchaRef.current?.reset();
        window.location.reload();
      } else {
        throw new Error('Submission failed.');
      }
    } catch (error) {
      // console.error('Error submitting form:', error);
      alert('There was an error submitting the form. Please try again.');
    }
  };

  return (
    <div className="hackathon-page">
      <Helmet>
        <title>Why Trade Lenda | Trade Lenda Hackathon!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <div className="form-container">
        <div className="hsignUpForm">
          <h1 className="text-center">Submission Form</h1>
          <form
            name="contact-form"
            onSubmit={handleSubmit}
            action="https://script.google.com/macros/s/AKfycbzcLVp3z42LZ-aECakHSu3jGhN3g0ww6bKwWUmTpHnufHPMdsnn9PpTr77WU1Yg5CQSfw/exec"
          >
            <div className="col-12">
              <label htmlFor="firstName" className="col-5 mb-0.5 mt-1">
                Project URL
              </label>
              <Input
                name="firstName"
                classes="form-control-sm col-12 signUpForm--inputField"
                type="url"
                id="firstName"
                value={userDetails.firstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="col-12 mb-2 sign-up-form--inputGroup">
              <label
                htmlFor="lastName"
                className="col-12 mb-1 mt-1 signUpForm--label"
              >
                Project Github URL(Ensure the repo is Public)
              </label>
              <Input
                name="lastName"
                classes="form-control-sm col-12 signUpForm--inputField"
                type="url"
                id="lastName"
                value={userDetails.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="col-12 mb-2 sign-up-form--inputGroup">
              <label
                htmlFor="email"
                className="col-12 mb-0.5 mt-1 signUpForm--label"
              >
                Email Address
              </label>
              <Input
                name="email"
                classes="form-control-sm col-12 signUpForm--inputField"
                type="email"
                id="email"
                value={userDetails.email}
                onChange={handleInputChange}
                required
              />
              {emailError && <p className="error-message">{emailError}</p>}
            </div>
            <div className="col-12 mb-2">
              <label htmlFor="phoneNumber" className="col-12 mb-0.5 mt-1">
                Phone Number (Nigeria Number only):
              </label>
              <div className="phone-input-wrapper">
                <PhoneInput
                  defaultCountry="NG"
                  className="form-control-sm mb-2 col-12 signUpForm--inputField"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  required
                />
                <input
                  type="hidden"
                  name="phoneNumber"
                  value={phoneNumber || ''}
                />
              </div>
              {phoneNumberError && (
                <p className="error-message">{phoneNumberError}</p>
              )}
            </div>
            {/* <div className="col-12 row">
              <div className="col-6 input-box">
                <label htmlFor="state" className="mb-1">
                  State
                </label>
                <select
                  name="state"
                  id="state"
                  className="form-control"
                  onChange={handleInputChange}
                  value={userDetails.state}
                >
                  <option value="">Select State</option>
                  {states.map((state, i) => (
                    <option value={state} key={i}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6 input-box">
                <label htmlFor="city" className="mb-1">
                  LGA
                </label>
                <select
                  name="city"
                  id="city"
                  className="form-control"
                  onChange={handleInputChange}
                  value={userDetails.city}
                >
                  <option value="">Select LGA</option>
                  {cities.map((city, i) => (
                    <option value={city} key={i}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}
            <div className="col-12 mb-2">
              <label htmlFor="nin" className="col-12 mb-0.5 mt-1">
                NIN
              </label>
              <Input
                name="nin"
                classes="form-control-sm mb-2 col-12 signUpForm--inputField"
                type="tel"
                id="nin"
                value={userDetails.nin}
                onChange={handleInputChange}
                required
              />
              {ninError && <p className="error-message">{ninError}</p>}
            </div>
            <div className="row mb-2 capt">
              <ReCAPTCHA
                sitekey={`${process.env.REACT_APP_CAPTCHA_SITE_KEY}`}
                className="captcha ms-0 mb-2 mt-0"
                size="normal"
                ref={recaptchaRef}
                onClick={() => handleCaptchachange}
              />
            </div>
            <div className="col-12 text-center">
              <button id="submit" className="submit-btn">
                Submit Application
              </button>
            </div>
          </form>
          <div className="d-flex text-center mt-5 back-top">
            <Link to="/">
              <Icon path={mdiArrowLeft} size={1} style={{ color: '#054b99' }} />
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HSignupForm;
