/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { StoreContext } from '../../../mobx_stores/RootStore';
import Button from '../../../components/ui/Button';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import Spinner from '../../../components/ui/Spinner';
import { mdiBell } from '@mdi/js';
import Icon from '@mdi/react';

const NotificationSettings = () => {
  const [notificationSettings, setNotificationSettings] = useState({
    desktopNotification: false,
    emailNotification: false,
    loanApproval: false,
    transactions: false,
  });

  const { profileStore } = React.useContext(StoreContext);

  const { submitting, loadingData } = profileStore;

  const handleSaveChanges = () => {
    profileStore.updateNotificationSettings(notificationSettings);
  };

  useEffect(() => {
    profileStore.getNotificationSettings();
  }, [profileStore.userId]);

  useEffect(() => {
    setNotificationSettings({
      desktopNotification:
        profileStore.notificationStatus.desktopNotification &&
        profileStore.notificationStatus.desktopNotification,

      emailNotification:
        profileStore.notificationStatus.emailNotification &&
        profileStore.notificationStatus.emailNotification,

      loanApproval:
        profileStore.notificationStatus.loanApproval &&
        profileStore.notificationStatus.loanApproval,

      transactions:
        profileStore.notificationStatus.transactions &&
        profileStore.notificationStatus.transactions,
    });
  }, [profileStore.notificationStatus]);

  const handleNotificationChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;

    setNotificationSettings((prevDetails: any) => {
      return {
        ...prevDetails,
        [element.name]: !notificationSettings[element.name],
      };
    });
  };

  return (
    <div className="notification-settings">
      {(loadingData || submitting) && <Spinner />}
      <div className="blue-line"></div>

      <div className="form-box px-4">
        <div className="row justify-content-center mt-4">
          <div className="col-md-11">
            <div className="text-center">
              <Icon path={mdiBell} size={3} color="#054b99" className="" />
            </div>
            <h3>Notification settings</h3>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <p className="mb-0">Enable desktop notification</p>
              <ToggleSwitch
                handleChange={handleNotificationChange}
                name="desktopNotification"
                val={notificationSettings}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <p className="mb-0">Enable email notification</p>
              <ToggleSwitch
                handleChange={handleNotificationChange}
                name="emailNotification"
                val={notificationSettings}
              />
            </div>
            <div className="notify mb-2">
              <h6 className=" text-uppercase fw-bold">
                Notification Preferences
              </h6>
              <p className="mb-0 grey">Notify me when:</p>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-4 ms-3">
              <p className="mb-0">Loans are approved</p>
              <ToggleSwitch
                handleChange={handleNotificationChange}
                name="loanApproval"
                val={notificationSettings}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4 ms-3">
              <p className="mb-0">A transaction is made</p>
              <ToggleSwitch
                handleChange={handleNotificationChange}
                name="transactions"
                val={notificationSettings}
              />
            </div>
            <div className="d-flex justify-content-center">
              <Button
                content="Save changes"
                classes="primary-btn mt-4"
                onClick={handleSaveChanges}
                type="button"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(NotificationSettings);
