import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import Confetti from 'react-confetti';

const windowSize = {
  width: 1024,
  height: 700,
};

const VerificationSuccess = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    false || window.sessionStorage.getItem('userStatus') === 'true',
  );

  const [useWindowSize, setUseWindowsSize] = useState(windowSize);
  const { width, height } = useWindowSize;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setIsLoggedIn(JSON.parse(window.sessionStorage.getItem('userStatus')!));
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 4000);
  }, []);

  useEffect(() => {
    setInterval(() => {
      setUseWindowsSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 500);
  }, []);
  return (
    <>
      <div className="verification-success">
        <Confetti width={width} height={height} gravity={0.1} />
        {/* <div className="pattern-group blue">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/blueangle.png`}
            alt=""
            style={{ width: '10%', margin: '0', right: '0' }}
          />
        </div> */}
        <div className="logo ">
          <Link to="/">
            <img
              src={`${BaseDirectories.LOGOS_DIR}/Logo2.png`}
              alt=""
              style={{ width: '20%' }}
            />
          </Link>
        </div>
        <div className="container">
          <div className="verification-img mt-5">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/verification2.png`}
              alt=""
              style={{ width: '15%' }}
            />
          </div>
          <h2>Verification Successful</h2>
          <Button
            content="Login"
            route="/login"
            classes="primary-btn btn-lg  col-12 mb-5 mx-3"
          />
          {/* {isLoggedIn && (
            <Button
              content="Go To Dashboard"
              route="/dashboard/overview"
              classes="primary-btn btn-lg  col-12 mb-5 mx-3"
            />
          )} */}
        </div>

        {/* <div className="pattern-group green">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/greenangle.png`}
            alt=""
            style={{ width: '10%' }}
          />
        </div> */}
      </div>
    </>
  );
};

export default VerificationSuccess;
