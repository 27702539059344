import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BaseDirectories from '../../../base directories/BaseDirectories';
import BlueCard from './BlueCard';
import StatsSection from './StatsSection';
import MarqueeComponent from './MarqueeComponent';

const Testimonials = () => {
  return (
    <section className="testimonials">
      <div className="row mb-5 justify-content-center">
        <div className="col-10">
          <h2>What our users are saying</h2>
          {/* <p className="description">
            We are Working to Serve the Next 400Million Customers
          </p> */}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div
            id="carouselExampleIndicators2"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner p-0">
              <div className="carousel-item active">
                <div className="row justify-content-center">
                  <div className="col-md-5 mb-3">
                    <div className="card p-4">
                      <div className="d-flex gap-3 align-items-center">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/obinna.png`}
                          alt="Trade Lenda"
                        />
                        <div>
                          <h5 className="mb-1">Obinna Ozugbo</h5>
                          <p className="mb-0">Trader, Tejuosho Market</p>
                        </div>
                      </div>
                      <p className="text-muted mt-3">
                        <i className="fas fa-quote-left pe-2"></i>
                        I'm happy with trade lenda because I'm enjoying d money
                        in my business and also it as been a great joy for me.
                      </p>
                      <ul className="list-unstyled d-flex text-warning mb-0">
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star-half-alt fa-sm"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-5 mb-3">
                    <div className="card p-4">
                      <div className="d-flex gap-3 align-items-center">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/peace.png`}
                          alt="Trade Lenda"
                        />
                        <div>
                          <h5 className="mb-1">Barr. Peace Onashile</h5>
                          <p className="mb-0">Unipolar Education consults</p>
                        </div>
                      </div>
                      <p className="text-muted mt-3">
                        <i className="fas fa-quote-left pe-2"></i>
                        Tradelenda has been immensely beneficial to our
                        Institution, especially in making available funds
                        pending the time we can fully obtain all the funds that
                        are due to us. Not only has the process been super
                        efficient and stress-free, the rates have also been very
                        considerate.
                      </p>
                      <ul className="list-unstyled d-flex text-warning mb-0">
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star-half-alt fa-sm"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row justify-content-center">
                  <div className="col-md-5 mb-3">
                    <div className="card p-4">
                      <div className="d-flex gap-3 align-items-center">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/wealth.png`}
                          alt="Trade Lenda"
                        />
                        <div>
                          <h5 className="mb-1">Wealth Imoh</h5>
                          <p className="mb-0">Wealth Edidiong Enterprises</p>
                        </div>
                      </div>
                      <p className="text-muted mt-3">
                        <i className="fas fa-quote-left pe-2"></i>
                        We are constantly in need of cash to deliver various
                        purchase order contracts from companies we work with and
                        Trade Lenda has been a trusted partner in completing
                        this contracts
                      </p>
                      <ul className="list-unstyled d-flex text-warning mb-0">
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star-half-alt fa-sm"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-5 mb-3">
                    <div className="card p-4">
                      <div className="d-flex gap-3 align-items-center">
                        <img
                          src={`${BaseDirectories.IMAGES_DIR}/user1.png`}
                          alt="Trade Lenda"
                        />
                        <div>
                          <h5 className="mb-1">Ponfa Miner</h5>
                          <p className="mb-0">Dornan Enterprises</p>
                        </div>
                      </div>
                      <p className="text-muted mt-3">
                        <i className="fas fa-quote-left pe-2"></i>
                        Tradelenda has been of tremendous help to my business.
                        With seamless and fast disbursement of funds, I have
                        expanded my business.
                      </p>
                      <ul className="list-unstyled d-flex text-warning mb-0">
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star fa-sm"></i>
                        </li>
                        <li>
                          <i className="fas fa-star-half-alt fa-sm"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-6 text-center d-flex justify-content-center gap-3">
              <a
                className="btn primary-btn mb-3 mr-1"
                href="#carouselExampleIndicators2"
                role="button"
                data-slide="prev"
              >
                <i className="fa fa-chevron-left mt-2"></i>
              </a>
              <a
                className="btn primary-btn first mb-3 "
                href="#carouselExampleIndicators2"
                role="button"
                data-slide="next"
              >
                <i className="fa fa-chevron-right mt-2"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <BlueCard />
      <StatsSection />
      <MarqueeComponent />
      {/* FAQ */}
      {/* FAQ */}
      <div
        className=" ss-wous position-relative"
        style={{
          marginBottom: '5rem',
          marginTop: '5rem',
          paddingLeft: '60px',
          paddingRight: '60px',
        }}
      >
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="lp-faq mt-0">
              <h1>Common questions people ask</h1>
              <div className="lp-faq-abs">
                <p>Have more questions?</p>
                <p>
                  Chat with us
                  <FontAwesomeIcon
                    icon={faCircleChevronRight}
                    style={{ marginLeft: '.5rem', color: '#24348B' }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="lp-inwy-accordion">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      What is Trade Lenda?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Trade Lenda is a Digital Finance Plug to assist Micro,
                      Small and informal retailers to access finance for
                      business needs and business growth within the space of
                      six(6) hours. We also assist retailers with access to
                      distributors and manufacturers.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      What are the benefits of using Trade Lenda?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Trade Lenda has huge number of benefits that can’t be seen
                      in any other; <li>Opportunity to grow your business</li>
                      <li>Quick Finance</li> <li>Smart Savings</li>
                      <li>Fast Payments</li>
                      <li> Insurance (HMO, Credit Life Assurance etc)</li>
                      <li>Free Credit Reports</li>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      How do I qualify for a Tradelenda loan?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      To qualify for a loan on Trade Lenda is as simple as
                      following the steps below <br />
                      1. Register on our website using the Sign up button <br />
                      2. Complete your Profile on your Dashboard; <br />
                      <li>Personal Information</li>
                      <li>Business Information</li>
                      <li>Financial Information</li>
                      <li>Then add a Guarantor to your dashboard</li>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      What is the duration of the loan pay back?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Trade Lenda expect a return of loan with 30days to 90days
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      What happens when I cannot repay my loan on time?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Trade Lenda charges a default rate of 1% and an additional
                      rate of 1% is added weekly on the default amount.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      Is Trade Lenda a Bank?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      No, We are a digital lending platform for MSMEs aimed at
                      providing Credits to support our customers. We have
                      partners we work with in order to offer value-added
                      financial services which are simple, faster, and
                      affordable for our customers.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                    >
                      How does Insurance work on Trade Lenda?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSeven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      We have partnered with Custodian and Alliance Insurance
                      firms to make access to affordable insurance coverage
                      possible for our clients.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                    >
                      I just started my business, do I qualify for a loan ?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingEight"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, definitely you do. Just sign-up, complete your
                      profile and make your request.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lp-faq-block">
            <p>Have more questions?</p>
            <p>
              Chat with us
              <FontAwesomeIcon
                icon={faCircleChevronRight}
                style={{
                  marginLeft: '2rem',
                  color: '#24348B',
                  verticalAlign: 'middle',
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
