/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite';
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { StoreContext } from '../../mobx_stores/RootStore';
import Button from '../ui/Button';
import Input from '../ui/Input';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Spinner from '../ui/Spinner';
import { useNavigate } from 'react-router';
import GuarantorNotification from '../../pages/dashboard/guarantors/GuarantorNotification';
import BaseDirectories from '../../base directories/BaseDirectories';

type Props = {
  success?: boolean;
  setSuccess?: any;
  setCancel?: any;
};

const AddGuarantorForm: FC<Props> = ({ success, setSuccess, setCancel }) => {
  const { guarantorStore } = useContext(StoreContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [guarantorDetails, setGuarantorDetails] = useState({
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });

  const { submitting, message } = guarantorStore;
  const navigate = useNavigate();

  const handleInputChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;

    setGuarantorDetails((prevDetails: any) => {
      return { ...prevDetails, [element.name]: element.value };
    });
  };

  useEffect(() => {
    if (phoneNumber === undefined) {
      setPhoneNumber('');
    } else {
      setPhoneNumber(phoneNumber);
    }
    setGuarantorDetails((prevDetails: any) => {
      return { ...prevDetails, phoneNumber };
    });
  }, [phoneNumber]);

  const handleCreateGuarantor = (e: FormEvent) => {
    e.preventDefault();

    guarantorStore.createGuarantor(guarantorDetails);
  };

  if (message.type === 'success') {
    setTimeout(() => {
      navigate('/dashboard/guarantors/all_guarantors');
    }, 3000);
  }

  useEffect(() => {
    guarantorStore.getGuarantors();
  }, [guarantorStore]);

  useEffect(() => {
    if (message.type === 'success') {
      setSuccess(true);
    }
  }, [message.type]);

  return (
    <>
      {submitting && <Spinner />}
      {success && (
        <GuarantorNotification
          image={`${BaseDirectories.IMAGES_DIR}/checked.png`}
          heading="Guarantor saved!"
          body="We have sent an invite to your Guarantor"
          close={() => setSuccess(false)}
        />
      )}
      <form className="add-guarantor-form" onSubmit={handleCreateGuarantor}>
        <div className="row justify-content-center">
          <div className="col-md-6 left-side">
            <div className="col-md-12">
              <label htmlFor="title" className="mb-1 mt-3">
                Title
              </label>
              <select
                onChange={handleInputChange}
                name="title"
                id="title"
                className="form-control"
              >
                <option value="">Title</option>
                <option value="Mr">Mr</option>
                <option value="Miss">Miss</option>
                <option value="Mrs">Mrs</option>
                <option value="Dr">Dr</option>
                <option value="Chief">Chief</option>
              </select>
            </div>
            <div className="row">
              <div className="col-6">
                <label htmlFor="firstName" className="mb-1 mt-3">
                  First name
                </label>
                <Input
                  onChange={handleInputChange}
                  name="firstName"
                  classes="form-control"
                  type="text"
                  id="firstName"
                />
              </div>
              <div className="col-6">
                <label htmlFor="lastName" className="mb-1 mt-3">
                  Last name
                </label>
                <Input
                  onChange={handleInputChange}
                  name="lastName"
                  classes="form-control"
                  type="text"
                  id="lastName"
                />
              </div>
            </div>
            <div className="col-md-12">
              <label htmlFor="email" className="mb-1 mt-3">
                Email address
              </label>
              <Input
                onChange={handleInputChange}
                name="email"
                classes="form-control"
                type="text"
                id="email"
              />
            </div>
            <div className="col-md-12">
              <label htmlFor="phoneNumber" className="mb-1 mt-3">
                Mobile number
              </label>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="NG"
                value={phoneNumber}
                onChange={(e: string) => setPhoneNumber(e)}
                className="form-control mb-4"
                id="phoneNumber"
              />
            </div>
            {message.msg !== '' &&
              typeof message.msg === 'object' &&
              message.msg.map((m: string, i: number) => (
                <div
                  key={i}
                  className={
                    message.type === 'success'
                      ? ' alert alert-success'
                      : 'alert alert-danger'
                  }
                  role="alert"
                >
                  <span>{m}</span>
                </div>
              ))}

            {message.msg !== '' && typeof message.msg === 'string' && (
              <div
                className={
                  message.type === 'success'
                    ? ' alert alert-success'
                    : 'alert alert-danger'
                }
                role="alert"
              >
                {message.msg}
              </div>
            )}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="group-btn">
              <Button
                classes="primary-btn btn-lg"
                content="Save"
                type="submit"
                // onClick={() => setSuccess(true)}
              />
              <Button
                classes="secondary-btn btn-lg"
                content="Cancel"
                onClick={() => setCancel(true)}
                type="button"
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default observer(AddGuarantorForm);
