/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toJS } from 'mobx';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { observer } from 'mobx-react-lite';
import Skeleton from 'react-loading-skeleton';
import Spinner from '../../../components/ui/Spinner';
import Button from '../../../components/ui/Button';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Modal from '../../../components/ui/Modal';

interface LocationState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const LoanDetails = () => {
  const [allLoans, setAllLoans] = useState<any>([]);
  const [loanId, setLoanId] = useState<any>([]);
  const [loanPayment, setLoanPayment] = useState({
    loanId: loanId._id,
  });
  const [loanRepayments, setLoanRepayments] = useState<any>([]);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [loanPaymentId, setLoanPaymentId] = useState('');
  const location: LocationState = useLocation();

  const { loansStore } = useContext(StoreContext);
  const { sending, loanUserdetails, loanRepaymentPlan, success } = loansStore;

  useEffect(() => {
    loansStore.getLoanById(location?.state?.loanId);
  }, [loansStore, location]);

  useEffect(() => {
    setLoanPayment({
      loanId: loanId._id,
    });
  }, [loanId._id]);

  useEffect(() => {
    setLoanId(toJS(loansStore.loanId));
  }, [loansStore.loanId, location]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(loansStore.loading);
  }, [loansStore.loading]);

  useEffect(() => {
    setAllLoans(toJS(loansStore.loans));
  }, [loansStore.loans]);

  useEffect(() => {
    if (
      loanId?.repaymentScheduleDates &&
      loanId?.repaymentScheduleDates.length > 0
    ) {
      loansStore.getLoanRepayment(loanId._id);
    }
  }, [loanId._id]);

  useEffect(() => {
    setLoanRepayments(toJS(loanRepaymentPlan));
  }, [loanRepaymentPlan]);

  useEffect(() => {
    if (
      loanId?.repaymentScheduleDates &&
      loanId?.repaymentScheduleDates.length > 0 &&
      success === 'Loan repayment successful!'
    ) {
      loansStore.getLoanRepayment(loanId._id);
    }
  }, [success]);

  const handleApproval = (repaymentId: string) => {
    loansStore.repayLoan(repaymentId);
    setShowApprovalModal(false);
  };

  return (
    <div className="loans">
      {sending && <Spinner />}

      {showApprovalModal && (
        <Modal
          image={`${BaseDirectories.IMAGES_DIR}/checked.png`}
          body="Are you sure you want to process loan repayment?"
          button={true}
          btnText1="Approve"
          btnText2="Cancel"
          onClick={() => handleApproval(loanPaymentId)}
          close={() => setShowApprovalModal(false)}
        />
      )}
      <div className="section-head">
        {' '}
        {loanUserdetails?.organizationDetails?.shariaCom ? 'Facility' : 'Loans'}
      </div>

      <div className="loans-container">
        <Link
          to={
            loanUserdetails?.organizationDetails?.shariaCom
              ? '/dashboard/facility'
              : '/dashboard/loans'
          }
        >
          <h4 className="h3-back">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              style={{ marginRight: '1rem' }}
            />
            Back
          </h4>
        </Link>

        {loading ? (
          <div>
            <div className="rq-loan-details">
              <h5>
                <Skeleton baseColor="#eaeff5" width={200} height={45} />
              </h5>

              <div className="d-flex flex-column flex-md-row">
                <div className="box-1">
                  <div className="ld-box">
                    <Skeleton baseColor="#eaeff5" width={150} height={45} />
                    <Skeleton baseColor="#eaeff5" width={250} height={45} />
                  </div>

                  <div className="ld-box">
                    <Skeleton baseColor="#eaeff5" width={150} height={45} />
                    <Skeleton baseColor="#eaeff5" width={250} height={45} />
                  </div>

                  <div className="ld-box">
                    <Skeleton baseColor="#eaeff5" width={150} height={45} />
                    <Skeleton baseColor="#eaeff5" width={250} height={45} />
                  </div>

                  <div className="ld-box">
                    <Skeleton baseColor="#eaeff5" width={150} height={45} />
                    <Skeleton baseColor="#eaeff5" width={250} height={45} />
                  </div>
                </div>

                <div className="box-1">
                  <div className="ld-box">
                    <Skeleton baseColor="#eaeff5" width={150} height={45} />
                    <Skeleton baseColor="#eaeff5" width={250} height={45} />
                  </div>

                  <div className="ld-box">
                    <Skeleton baseColor="#eaeff5" width={150} height={45} />
                    <Skeleton baseColor="#eaeff5" width={250} height={45} />
                  </div>

                  <div className="ld-box">
                    <Skeleton baseColor="#eaeff5" width={150} height={45} />
                    <Skeleton baseColor="#eaeff5" width={250} height={45} />
                  </div>

                  <div className="ld-box">
                    <Skeleton baseColor="#eaeff5" width={150} height={45} />
                    <Skeleton baseColor="#eaeff5" width={250} height={45} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="rq-loan-details">
            <h5>
              {loanId.status === 'paid' ? loanId.status : loanId.approvalStatus}{' '}
              {loanUserdetails?.organizationDetails?.shariaCom
                ? 'Facility'
                : 'Loan'}
            </h5>

            <div className="d-flex flex-column flex-md-row">
              <div className="box-1">
                <div className="ld-box">
                  <h5>Reason</h5>
                  <p>{loanId.reason}</p>
                </div>

                <div className="ld-box">
                  <h5>
                    {' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'Loan'}{' '}
                    Terms
                  </h5>
                  <p>{loanId.loanTenor}</p>
                </div>

                <div className="ld-box">
                  <h5>Amount</h5>
                  <p>₦{loanId.amount?.toLocaleString()}</p>
                </div>
                {loanId?.loanType !== 'Musharakah' && (
                  <div className="ld-box">
                    <h5>
                      {loanUserdetails?.organizationDetails?.shariaCom &&
                      loanId?.loanType === 'Murabaha'
                        ? 'Mark Up'
                        : 'Monthly Interest'}
                    </h5>
                    <p>
                      {loanId?.loanType !== 'Musharakah' &&
                        Math.round(loanId?.interest * 100) / 100}
                      %
                    </p>
                  </div>
                )}

                {loanId.status === 'disbursed' && (
                  <div className="ld-box">
                    <h5>Date of Disbursement</h5>
                    <p
                      className={`${
                        loanId.status === 'paid'
                          ? loanId.status
                          : loanId.approvalStatus
                      }`}
                    >
                      {loanId.updatedAt?.substr(0, 10) === undefined
                        ? '--------------'
                        : loanId.updatedAt?.substr(0, 10)}
                    </p>
                  </div>
                )}
                {loanId.approvalStatus === 'approved' && (
                  <>
                    <div className="ld-box">
                      <h5>
                        Number of{' '}
                        {loanUserdetails?.organizationDetails?.shariaCom
                          ? 'Payments'
                          : 'Repayments'}
                      </h5>
                      <p>{loanId.numberOfRepayments || 1}</p>
                    </div>

                    {loanId.approvalStatus === 'pending' ||
                      (loanId.approvalStatus === 'approved' && (
                        <div className="ld-box">
                          <h5>Final Payback date</h5>
                          <p>
                            {loanId.status === 'disbursed'
                              ? loanId?.repaymentScheduleDates[
                                  Number(loanId?.numberOfRepayments) - 1
                                ]?.substr(0, 10) ||
                                loanId.paybackDate?.substr(0, 10)
                              : loanId.paybackDate?.substr(0, 10) === undefined
                              ? '--------------'
                              : loanId.paybackDate?.substr(0, 10)}
                          </p>
                        </div>
                      ))}

                    {loanId.numberOfRepayments > 1 && (
                      <div className="ld-box">
                        <h5>
                          Monthly{' '}
                          {loanUserdetails?.organizationDetails?.shariaCom
                            ? 'Payment'
                            : 'Repayment'}{' '}
                          Amount
                        </h5>
                        <p>
                          ₦
                          {loanId?.monthlyRepaymentAmount?.toLocaleString() ||
                            0}
                        </p>
                      </div>
                    )}

                    <div className="ld-box">
                      <h5>
                        Total{' '}
                        {loanUserdetails?.organizationDetails?.shariaCom
                          ? 'Payment'
                          : 'Repayment'}{' '}
                        Amount
                      </h5>
                      <p>
                        ₦{loanId?.totalrepaymentAmount?.toLocaleString() || 0}
                      </p>
                    </div>
                  </>
                )}
              </div>

              <div className="box-1">
                <div className="ld-box">
                  <h5>Approval Status</h5>
                  <p
                    className={loanId.approvalStatus}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {loanId.approvalStatus}
                  </p>
                </div>

                <div className="ld-box">
                  <h5>
                    {' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'Loan'}{' '}
                    Status
                  </h5>
                  <p
                    className={loanId.status}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {loanId.status === 'pending_disburse'
                      ? 'pending disbursement'
                      : loanId.status}
                  </p>
                </div>

                <div className="ld-box">
                  <h5>
                    {' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'Facility'
                      : 'Loan'}{' '}
                    Type
                  </h5>
                  <p style={{ textTransform: 'capitalize' }}>
                    {loanId.loanType}
                  </p>
                </div>

                {/* APPROVAL DATE */}
                {loanId.approvalStatus === 'approved' && (
                  <div className="ld-box">
                    <h5>Date of Approval</h5>
                    <p>
                      {loanId.dateOfApproval === undefined
                        ? '--------------'
                        : loanId.dateOfApproval?.substr(0, 10)}
                    </p>
                  </div>
                )}

                {/* REJECTION DATE */}
                {loanId.approvalStatus === 'rejected' && (
                  <div className="ld-box">
                    <h5>Date of Rejection</h5>
                    <p>
                      {loanId.dateOfApproval === undefined
                        ? '--------------'
                        : loanId.dateOfApproval?.substr(0, 10)}
                    </p>
                  </div>
                )}

                {loanId.status === 'disbursed' ? (
                  <div className="ld-box">
                    <h5>Payback Scheduled Dates</h5>
                    {loanId?.repaymentScheduleDates.map(
                      (dates: any, idx: number) => (
                        <div key={idx} className="row mb-2">
                          <div className="col-md-8">
                            <p>
                              <span>{idx + 1}. </span>
                              {dates.substr(0, 10)}
                              {' | '}
                              <span className="">
                                {loanRepayments?.length > 0 &&
                                loanRepayments[idx]?.status === 'new'
                                  ? `₦${Number(
                                      loanRepayments[idx]?.amount,
                                    )?.toLocaleString()}`
                                  : loanRepayments[idx]?.totalPaidBack
                                  ? `₦${Number(
                                      loanRepayments[idx]?.totalPaidBack,
                                    )?.toLocaleString()}`
                                  : `₦${Number(
                                      loanRepayments[idx]?.amount,
                                    )?.toLocaleString()}`}
                              </span>
                            </p>
                          </div>
                          <div className="col-md-4">
                            <Button
                              content={
                                loanRepayments[idx]?.status === 'new'
                                  ? `Pay Now`
                                  : loanRepayments[idx]?.status === 'partial'
                                  ? `Pay Balance`
                                  : loanRepayments[idx]?.status === 'paid'
                                  ? 'Paid'
                                  : 'Pay Back'
                              }
                              classes="primary-btn btn-xs ms-2 text-sm justify-content-end"
                              disabled={
                                loanRepayments[idx]?.status === 'paid'
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                setShowApprovalModal(true);
                                setLoanPaymentId(loanRepayments[idx]?._id);
                              }}
                            />
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                ) : loanId.approvalStatus === 'approved' ? (
                  <div className="ld-box">
                    <>
                      <h5>Payback Scheduled Dates</h5>
                      <p>Pending disbursement</p>
                    </>
                  </div>
                ) : null}

                {loanId.approvalStatus === 'rejected' && (
                  <div className="ld-box">
                    <h5>Underwriter Reason</h5>
                    <p>{loanId?.underwriterReason || '--------------'}</p>
                  </div>
                )}
                {(loanId.approvalStatus === 'rejected' ||
                  loanId.approvalStatus === 'approved') && (
                  <div className="ld-box">
                    <h5>Underwriter Comment</h5>
                    <p>{loanId?.underwriterNote || '--------------'}</p>
                  </div>
                )}
                {loanId.merchantBusinessName !== undefined &&
                  loanId.merchantBusinessName !== null && (
                    <div className="ld-box">
                      <h5>Supplier Business Name</h5>
                      <p>{loanId.merchantBusinessName.toUpperCase()}</p>
                    </div>
                  )}

                {loanId.merchantPhoneNumber !== undefined &&
                  loanId.merchantPhoneNumber !== null && (
                    <div className="ld-box">
                      <h5>Supplier Phone Number</h5>
                      <p>{loanId.merchantPhoneNumber.toUpperCase()}</p>
                    </div>
                  )}
              </div>
            </div>
            {loanId.approvalStatus !== 'pending' &&
              loanId.approvalStatus !== 'rejected' && (
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <Link
                    className="secondary-btn btn-lg bg-white w-50 d-flex align-items-center justify-content-center"
                    to={
                      loanUserdetails?.organizationDetails?.shariaCom
                        ? loanId?.loanType === 'Musharakah'
                          ? `/dashboard/facility/${loanId._id}/offer_letter_musharakah`
                          : loanId?.loanType === 'Murabaha'
                          ? `/dashboard/facility/${loanId._id}/offer_letter_murabaha`
                          : `/dashboard/loans/${loanId._id}/offer_letter`
                        : `/dashboard/loans/${loanId._id}/offer_letter`
                    }
                    state={{
                      loan: allLoans.filter(
                        (a: any) => a._id === loanId._id,
                      )[0],
                      loanState: loanId,
                    }}
                  >
                    View offer letter
                  </Link>
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(LoanDetails);
