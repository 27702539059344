/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa0 } from '@fortawesome/free-solid-svg-icons';
import BaseDirectories from '../../../base directories/BaseDirectories';
import Button from '../../../components/ui/Button';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { toJS } from 'mobx';
import Skeleton from 'react-loading-skeleton';

interface ILoansAmountCardProps {}

const LoansAmountCard: React.FunctionComponent<ILoansAmountCardProps> = () => {
  const [allLoanAmounts, setAllLoanAmounts] = useState<any>({});
  const [loanRoute, setLoanRoute] = useState<any>([]);

  const { loansStore } = useContext(StoreContext);
  const { loading, loanUserdetails } = loansStore;

  useEffect(() => {
    loansStore.getLoanUserDetails();
    loansStore.getAllLoanAmounts();
  }, [loansStore]);

  useEffect(() => {
    setLoanRoute(toJS(loansStore.loanUserdetails));
    setAllLoanAmounts(toJS(loansStore.allLoanAmounts));
  }, [loansStore.allLoanAmounts]);

  return (
    <div>
      {loading ? (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="fs-card">
              <div className="d-flex align-items-center justify-content-between border-bottom p-3">
                <Skeleton baseColor="#FAFAFA" width={40} height={35} />
                <Skeleton baseColor="#FAFAFA" width={150} height={35} />

                <Skeleton baseColor="#FAFAFA" width={40} height={35} />
              </div>
              <div className="d-flex align-items-center justify-content-center py-4">
                <h2 style={{ fontWeight: '500' }}>
                  {' '}
                  <Skeleton baseColor="#FAFAFA" width={200} height={35} />
                </h2>
              </div>
            </div>

            <div className="fs-card">
              <div className="d-flex align-items-center justify-content-between border-bottom p-3">
                <Skeleton baseColor="#FAFAFA" width={40} height={35} />
                <Skeleton baseColor="#FAFAFA" width={150} height={35} />

                <Skeleton baseColor="#FAFAFA" width={40} height={35} />
              </div>
              <div className="d-flex align-items-center justify-content-center py-4">
                <h2 style={{ fontWeight: '500' }}>
                  {' '}
                  <Skeleton baseColor="#FAFAFA" width={200} height={35} />
                </h2>
              </div>
            </div>

            <div className="fs-card">
              <div className="d-flex align-items-center justify-content-between border-bottom p-3">
                <Skeleton baseColor="#FAFAFA" width={40} height={35} />
                <Skeleton baseColor="#FAFAFA" width={150} height={35} />

                <Skeleton baseColor="#FAFAFA" width={40} height={35} />
              </div>
              <div className="d-flex align-items-center justify-content-center py-4">
                <h2 style={{ fontWeight: '500' }}>
                  {' '}
                  <Skeleton baseColor="#FAFAFA" width={200} height={35} />
                </h2>
              </div>
            </div>
          </div>

          <div className="search-group">
            <div className="search-box">
              <Skeleton baseColor="#FAFAFA" width={370} height={45} />
            </div>
            <Skeleton baseColor="#FAFAFA" width={160} height={45} />
          </div>
        </div>
      ) : (
        <div>
          <div className="loan-card d-flex align-items-center justify-content-between bg-white rounded p-2">
            <div className="approved-card fs-card">
              <div className="d-flex align-items-center justify-content-between border-bottom p-3">
                {/* <FontAwesomeIcon
                  icon={fa0}
                  style={{
                    color: '#054B99',
                    background: '#E6EDF5',
                    padding: '.7rem',
                    borderRadius: '50%',
                  }}
                /> */}
                <h6>
                  Approved{' '}
                  {loanUserdetails?.organizationDetails?.shariaCom
                    ? 'Facility'
                    : 'Loans'}
                </h6>
                <img
                  src={`${BaseDirectories.ICONS_DIR}/blue-cube.png`}
                  alt="Trade Lenda"
                />
              </div>
              {allLoanAmounts.totalApprovedLoanAmount === 0 ||
              allLoanAmounts.totalApprovedLoanAmount === undefined ? (
                <div className="d-flex align-items-center justify-content-center py-4">
                  <h2 style={{ fontWeight: '500' }}>&#x20A6;0.00</h2>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center py-4">
                  <h2 style={{ fontWeight: '500' }}>
                    &#x20A6;
                    {allLoanAmounts.totalApprovedLoanAmount?.toLocaleString()}
                  </h2>
                </div>
              )}
            </div>

            <div className="pending-card fs-card">
              <div className="d-flex align-items-center justify-content-between border-bottom p-3">
                {/* <FontAwesomeIcon
                  icon={fa0}
                  style={{
                    color: '#F4B740',
                    background: '#E6EDF5',
                    padding: '.7rem',
                    borderRadius: '50%',
                  }}
                /> */}
                <h6>
                  Pending{' '}
                  {loanUserdetails?.organizationDetails?.shariaCom
                    ? 'Facility'
                    : 'Loans'}
                </h6>
                <img
                  src={`${BaseDirectories.ICONS_DIR}/orange-cube.png`}
                  alt="Trade Lenda"
                />
              </div>
              {allLoanAmounts.totalPendingLoanAmount === 0 ||
              allLoanAmounts.totalPendingLoanAmount === undefined ? (
                <div className="d-flex align-items-center justify-content-center py-4">
                  <h2 style={{ fontWeight: '500' }}>&#x20A6;0.00</h2>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center py-4">
                  <h2 style={{ fontWeight: '500' }}>
                    &#x20A6;
                    {allLoanAmounts.totalPendingLoanAmount?.toLocaleString()}
                  </h2>
                </div>
              )}
            </div>

            <div className="paid-card fs-card">
              <div className="d-flex align-items-center justify-content-between border-bottom p-3">
                {/* <FontAwesomeIcon
                  icon={fa0}
                  style={{
                    color: '#44AB3B',
                    background: '#E6EDF5',
                    padding: '.7rem',
                    borderRadius: '50%',
                  }}
                /> */}
                <h6>
                  Paid{' '}
                  {loanUserdetails?.organizationDetails?.shariaCom
                    ? 'Facility'
                    : 'Loans'}
                </h6>
                <img
                  src={`${BaseDirectories.ICONS_DIR}/green-cube.png`}
                  alt="Trade Lenda"
                />
              </div>
              {allLoanAmounts.totalPaidLoanAmount === 0 ||
              allLoanAmounts.totalPaidLoanAmount === undefined ? (
                <div className="d-flex align-items-center justify-content-center py-4">
                  <h2 style={{ fontWeight: '500' }}>&#x20A6;0.00</h2>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center py-4">
                  <h2 style={{ fontWeight: '500' }}>
                    &#x20A6;
                    {allLoanAmounts.totalPaidLoanAmount?.toLocaleString()}
                  </h2>
                </div>
              )}
            </div>
          </div>

          <div className="search-group">
            <div className="search-box">
              <Icon path={mdiMagnify} size={1} className="search-icon" />
              <input type="text" placeholder="Search" />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h3>
                {' '}
                {loanUserdetails?.organizationDetails?.shariaCom
                  ? 'Facility'
                  : 'Loans'}{' '}
              </h3>
              <Button
                content={`Request For  ${
                  loanUserdetails?.organizationDetails?.shariaCom
                    ? 'Facility'
                    : 'Loans'
                }`}
                title="Request For Loan"
                classes="primary-btn btn-lg add-btn"
                route={
                  loanRoute.loanDocumentDetails === undefined
                    ? loanUserdetails?.organizationDetails?.shariaCom
                      ? '/dashboard/facility/user_personal_details'
                      : '/dashboard/loans/user_personal_details'
                    : loanUserdetails?.organizationDetails?.shariaCom
                    ? '/dashboard/facility/request_for_facility'
                    : '/dashboard/loans/request_for_loan'
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(LoansAmountCard);
