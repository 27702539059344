import { mdiCircleSmall } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import Button from '../../../components/ui/Button';
import BaseDirectories from '../../../base directories/BaseDirectories';

const HTC = () => {
  const points = [
    {
      title: 'Eligibility',
      description:
        'Participants must be at least 18 years old Participants must be resident in Nigeria Participants must register before the hackathon starts.',
    },
    {
      title: 'Intellectual Property ',
      description:
        'All ideas, concepts, and code created during the hackathon are owned by the participants. Participants grant the hackathon organizers a non-exclusive license to use, display, and  distribute their work for promotional purposes. The winning idea, concept, code and software  will be owned by Trade Lenda.',
    },
    {
      title: 'Rules',
      description:
        'Participants must work on their projects within the hackathon days. No pre-existing projects can be used Participants must follow the theme and guidelines provided.',
    },
    {
      title: 'Judging',
      description:
        "A panel of judges will select the winners based on criteria such as innovation, impact, and  presentation. Judges' decisions are final and binding.",
    },
    {
      title: 'Prizes',
      description:
        'The winners will receive prizes as listed on the hackathon details. Prizes are non-transferable and non-cash prizes cannot be exchanged for cash.',
    },
    {
      title: 'Conduct',
      description:
        'Participants must conduct themselves in a professional and respectful manner. Harassment, discrimination, or inappropriate behavior will not be tolerated.',
    },
    {
      title: 'Data Privacy',
      description:
        'Participants are responsible for ensuring the privacy and security of any data used during the  hackathon.',
    },
    {
      title: 'Liability',
      description:
        'Participants are responsible for any damages or losses caused by their actions during the  hackathon. The hackathon organizers are not liable for any losses or damages.',
    },
    {
      title: 'Changes',
      description:
        'The hackathon organizers reserve the right to make changes to the rules, schedule, or prizes at  any time.',
    },
    {
      title: 'Acceptance',
      description:
        'By participating in the hackathon, participants agree to these Terms and Conditions.',
    },
  ];
  return (
    <>
      <section className="details-container">
        <h6>OTHER DETAILS</h6>
        <h2 className="fw-bold">
          <span style={{ color: '#06A77D' }}>Winner</span>{' '}
          <Icon path={mdiCircleSmall} size={2} style={{ color: '#054b99' }} />
          <span style={{ color: '#054b99' }}>Prize</span>{' '}
          <Icon path={mdiCircleSmall} size={2} style={{ color: '#054b99' }} />
          <span style={{ color: '#f4b740' }}>Date</span>{' '}
        </h2>
        <p className="">
          The winning web software is expected to have the above-listed
          features. There will be only one winner who will receive a{' '}
          <strong>N1,000,000</strong> prize and an opportunity to work with us
          on the software. The duration of the Hackathon is{' '}
          <strong>8th Aug. – 21st Sept. 2024</strong>
        </p>
      </section>
      <section className="activities-container">
        <h6 className="mb-4">Key Hackathon Activities & Dates:</h6>
        <div className="d-flex mb-4">
          <img
            style={{
              marginRight: '15px',
            }}
            src={`${BaseDirectories.ICONS_DIR}/grectangle.png`}
            alt="Trade Lenda"
          />
          <h5>
            {' '}
            <strong>August 8</strong> – Hackathon Announcement
          </h5>
        </div>
        <div className="d-flex mb-4">
          <img
            style={{
              marginRight: '15px',
            }}
            src={`${BaseDirectories.ICONS_DIR}/grectangle.png`}
            alt="Trade Lenda"
          />
          <h5>
            {' '}
            <strong>August 8</strong> – Application Commencement date
          </h5>
        </div>
        <div className="d-flex mb-4">
          <img
            style={{
              marginRight: '15px',
            }}
            src={`${BaseDirectories.ICONS_DIR}/grectangle.png`}
            alt="Trade Lenda"
          />
          <h5>
            {' '}
            <strong>August 21</strong> – Application Closing date
          </h5>
        </div>
        <div className="d-flex mb-4">
          <img
            style={{
              marginRight: '15px',
            }}
            src={`${BaseDirectories.ICONS_DIR}/grectangle.png`}
            alt="Trade Lenda"
          />
          <h5>
            {' '}
            <strong>September 21</strong> – Project Submission Deadline
          </h5>
        </div>
        <div className="d-flex mb-4">
          <img
            style={{
              marginRight: '15px',
            }}
            src={`${BaseDirectories.ICONS_DIR}/grectangle.png`}
            alt="Trade Lenda"
          />
          <h5>
            {' '}
            <strong>September 25</strong> – Shortlist Announcement
          </h5>
        </div>
      </section>
      <section className="tc-container">
        <div className="points-container">
          <h3 className="mb-4">Terms and Conditions</h3>
          {points.map((point, index) => (
            <div className="numbered-list-item mb-4" key={index}>
              <h4 className="list-title">{`${index + 1}. ${point.title}`}</h4>
              <p className="list-description">{point.description}</p>
            </div>
          ))}
        </div>
        <div className="col-12 text-center my-5">
          <Button
            classes="white-btn"
            content="Submit now"
            route="/hackathon/hsign_up"
            style={{ fontWeight: 'bold' }}
          />
        </div>
      </section>
    </>
  );
};

export default HTC;
