import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import {
  Autoplay,
  EffectCoverflow,
  FreeMode,
  Navigation,
  Pagination,
} from 'swiper';
import BaseDirectories from '../../../base directories/BaseDirectories';

const LoanGuide = () => {
  return (
    <section className="landing-page-hero">
      <div className="d-flex mb-5 justify-content-center">
        <div className="text-center">
          <h2 className="fw-bold fs-1">Loan Guide</h2>
          <p className="description">Follow the guide to get instant Loan</p>
        </div>
      </div>

      <div className="marque-container-lg">
        <div className="hero-img">
          <Swiper
            className="swiper-container-lg"
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            freeMode={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0,
              stretch: -50,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: 'swiper-pagination', clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[
              //   EffectCoverflow,
              Autoplay,
              FreeMode,
              Pagination,
              Navigation,
            ]}
          >
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/Tl_step1.png`}
                alt="slide_image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/Tl_step2.png`}
                alt="slide_image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/Tl_step3.jpg`}
                alt="slide_image"
                style={{ height: '577px', width: '577px' }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/Tl_step4.png`}
                alt="slide_image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={`${BaseDirectories.IMAGES_DIR}/Tl_step5.jpg`}
                alt="slide_image"
                style={{ height: '577px', width: '577px' }}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default LoanGuide;
