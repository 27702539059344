/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { StoreContext } from '../../ mobx stores/RootStore';
import Button from '../ui/Button';
import Input from '../ui/Input';
import Spinner from '../ui/Spinner';
import Skeleton from 'react-loading-skeleton';
import PhoneInput from 'react-phone-number-input';

type Props = {
  next: () => void;
  setX: Function;
};

const EducationLevel = {
  Primary: 'Primary School',
  Secondary: 'Secondary School',
  OND: 'Ordinary National Diploma (OND)',
  HND: 'Higher National Diploma (HND)',
  Bachelors: 'Bachelors',
  Masters: 'Masters',
  PHD: 'PHD',
  PGD: 'Post Graduate Diploma',
};

const LoansPersonalDetailsForm: FC<Props> = ({ next, setX }) => {
  const { loansStore, profileStore } = React.useContext(StoreContext);

  const { sending, success, message } = loansStore;

  const { profile, loadingData } = profileStore;

  const [updateProfile, setUpdateProfile] = React.useState({
    title: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    gender: '',
    bvn: '',
    nin: '',
    dob: '',
    address: '',
    country: 'Nigeria',
    state: '',
    city: '',
    maritalStatus: '',
    NoOfDependents: 0,
    wdymtta: '',
    eduLevel: '',
  });

  React.useEffect(() => {
    loansStore.getLoanUserDetails();
  }, [loansStore]);

  useEffect(() => {
    setUpdateProfile({
      title: profile && profile.title === undefined ? '' : profile.title,
      email: profile && profile.email === undefined ? '' : profile.email,
      firstName:
        profile && profile.firstName === undefined ? '' : profile.firstName,
      lastName:
        profile && profile.lastName === undefined ? '' : profile.lastName,
      phoneNumber:
        profile && profile.phoneNumber === undefined ? '' : profile.phoneNumber,
      gender: profile && profile.gender === undefined ? '' : profile.gender,
      bvn:
        (profile && profile.bvn === undefined) || profile.bvn === 'NULL'
          ? ''
          : profile.bvn,
      nin:
        (profile && profile.nin === undefined) || profile.nin === 'NULL'
          ? ''
          : profile.nin,
      dob:
        profile && profile.dob === undefined ? '' : profile.dob?.substr(0, 10),
      address: profile && profile.address === undefined ? '' : profile.address,
      country:
        profile && profile.country === undefined ? 'Nigeria' : profile.country,
      state: profile && profile.state === undefined ? '' : profile.state,
      city: profile && profile.city === undefined ? '' : profile.city,
      maritalStatus:
        profile && profile.maritalStatus === undefined
          ? ''
          : profile.maritalStatus,
      NoOfDependents:
        profile && profile.NoOfDependents === undefined
          ? 0
          : profile.NoOfDependents,
      wdymtta:
        profile && (profile.wdymtta === undefined || profile.wdymtta === 'NULL')
          ? ''
          : profile.wdymtta,
      eduLevel:
        profile && profile.eduLevel === undefined ? '' : profile.eduLevel,
    });
  }, [profile]);

  const [state, setState] = useState([]);
  const [cityByState, setCitybyState] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    setState(toJS(loansStore.state));
    setCity(toJS(loansStore.city));
  }, [loansStore.state, loansStore.city]);

  React.useEffect(() => {
    if (updateProfile.state !== '') {
      setCitybyState(
        state.filter((state: string) => state === updateProfile.state),
      );
    }
  }, [updateProfile.state, state]);

  const stateCity = cityByState[0];

  useEffect(() => {
    loansStore.getState();
    loansStore.getCity(stateCity);
  }, [loansStore, stateCity]);

  // useEffect(() => {
  //   profileStore.getProfile();
  // }, [loansStore]);

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setUpdateProfile((prevDetails: any) => {
      if (element.id === 'numbersOnly') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });

    if (
      element.value !== '' &&
      element.style.border === '2px solid rgb(255, 137, 137)'
    ) {
      element.style.border = '1px solid #ced4da';
    }
  };

  // const [phoneNumber, setPhoneNumber] = React.useState('');
  // React.useEffect(() => {
  //   if (updateProfile.phoneNumber === '') {
  //     setUpdateProfile((prevDetails: any) => {
  //       return { ...prevDetails, phoneNumber };
  //     });
  //   }
  // }, [phoneNumber]);

  function validate(evt: any) {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const handleUpdatePersonalDetails = (e: React.FormEvent) => {
    e.preventDefault();

    loansStore.updatePersonalDetails(updateProfile);

    const inputs = document.querySelectorAll('.form-control');

    if (message.msg === 'Some fields are empty!') {
      inputs.forEach((input: any) => {
        if (
          input.value === '' ||
          input.value === 'NULL' ||
          updateProfile[input.name] === '' ||
          updateProfile[input.name] === 'NULL'
        ) {
          input.style.border = '2px solid #ff8989';
          input.focus();
          setTimeout(() => {
            input.style.border = '1px solid #ced4da';
          }, 50000);
        }
      });
    }
  };

  React.useEffect(() => {
    if (success === 'Personal details updated successfully') {
      next();
      setX(1000);
    }
  }, [next, setX, success]);

  return (
    <div>
      {/* <Toaster
        position="top-right"
        reverseOrder={true}
        containerStyle={{
          top: 120,
        }}
        toastOptions={{
          success: {
            duration: 5000,
          },
          error: {
            duration: 5000,
          },
          style: {
            gap: '10px',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== 'loading' && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{ width: '50px', background: 'white' }}
                  >
                    {icon}
                  </button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster> */}

      <form action="" onSubmit={handleUpdatePersonalDetails}>
        <div className="py-5 px-4 bg-white rounded mt-4">
          {sending && <Spinner />}
          {loadingData ? (
            <div>
              <div className="row">
                <div className="col-md-6 left-side pe-md-3 pe-lg-4">
                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>
                </div>

                <div className="col-md-6 right-side ps-md-3 ps-lg-4">
                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>

                  <div className="col-md-12 mb-4">
                    <Skeleton baseColor="#FAFAFA" width={150} height={35} />
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="profile-btn">
                    <Skeleton baseColor="#FAFAFA" width={350} height={45} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6 left-side pe-md-3 pe-lg-4">
                  <div className="col-md-12">
                    <label htmlFor="title" className="mb-1 mt-3">
                      Title
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="title"
                      id="title"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={profile.title}
                      required
                    >
                      <option value="">Select title</option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Dr">Dr</option>
                    </select>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="firstName" className="mb-1 mt-3">
                        First name
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <Input
                        name="firstName"
                        classes="form-control"
                        type="text"
                        id="firstName"
                        defaultValue={profile.firstName}
                        onChange={handleInputChange}
                        readonly={true}
                        required
                      />
                    </div>

                    <div className="col-6">
                      <label htmlFor="lastName" className="mb-1 mt-3">
                        Last name
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <Input
                        name="lastName"
                        classes="form-control"
                        type="text"
                        id="lastName"
                        defaultValue={profile.lastName}
                        onChange={handleInputChange}
                        readonly={true}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="email" className="mb-1 mt-3">
                      Email
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="email"
                      classes="form-control"
                      type="text"
                      id="email"
                      defaultValue={profile.email}
                      onChange={handleInputChange}
                      readonly={true}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="phoneNumber" className="mb-1 mt-3">
                      Phone Number
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="NG"
                      onChange={(e: string) =>
                        setUpdateProfile((prevDetails: any) => {
                          return { ...prevDetails, phoneNumber: e };
                        })
                      }
                      className="form-control"
                      id="phoneNumber"
                      value={profile.phoneNumber}
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="dob" className="mb-1 mt-3">
                      Date of birth
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="dob"
                      classes="form-control"
                      type="date"
                      id="dob"
                      onChange={handleInputChange}
                      defaultValue={profile.dob?.substr(0, 10)}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="gender" className="mb-1 mt-3">
                      Gender
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={profile.gender}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Prefer Not To Say">
                        Prefer Not To Say
                      </option>
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="maritalStatus" className="mb-1 mt-3">
                      Marital status
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="maritalStatus"
                      id="maritalStatus"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={profile.maritalStatus}
                      required
                    >
                      <option value="">Select marital status</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                      <option value="Separated">Separated</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6 right-side ps-md-3 ps-lg-4 ">
                  <div className="col-md-12">
                    <label htmlFor="bvn" className="mb-1 mt-3">
                      BVN (Bank Verification Number)
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="bvn"
                      classes="form-control"
                      type="text"
                      id="bvn"
                      onChange={handleInputChange}
                      defaultValue={profile.bvn}
                      readonly={true}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="nin" className="mb-1 mt-3">
                      NIN (National Identification Number)
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="nin"
                      classes="form-control"
                      type="text"
                      id="nin"
                      onChange={handleInputChange}
                      defaultValue={profile.nin}
                      readonly={true}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="country" className="mb-1 mt-3">
                      Country
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="country"
                      id="country"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={profile.country}
                      required
                    >
                      <option value="Nigeria">Nigeria</option>
                    </select>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="state" className="mb-1 mt-3">
                        State
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <select
                        name="state"
                        id="state"
                        className="form-control"
                        onChange={handleInputChange}
                        required
                        // defaultValue={profile.state}
                      >
                        {profile.state ? (
                          <option value={profile.state}>{profile.state}</option>
                        ) : null}
                        <option value="">Select State</option>
                        {state.map((state: string, i: number) => (
                          <option value={state} key={i}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-6">
                      <label htmlFor="cityId" className="mb-1 mt-3">
                        LGA
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <select
                        name="city"
                        id="city"
                        className="form-control"
                        onChange={handleInputChange}
                        required
                        // defaultValue={profile.city}
                      >
                        {profile.city ? (
                          <option value={profile.city}>{profile.city}</option>
                        ) : null}
                        <option value="">Select LGA</option>
                        {city.map((city: string, i: number) => (
                          <option value={city} key={i}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="address" className="mb-1 mt-3">
                      Address
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="address"
                      classes="form-control"
                      type="text"
                      id="address"
                      onChange={handleInputChange}
                      defaultValue={profile.address}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="wdymtta" className="mb-1 mt-3">
                      When did you move to this address?
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="wdymtta"
                      id="wdymtta"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={profile.wdymtta}
                      required
                    >
                      <option value="">Select option</option>
                      <option value="0-1 years">0-1 years</option>
                      <option value="1-3 years">1-3 years</option>
                      <option value="3-5 years">3-5 years</option>
                      <option value="5-10 years">5-10 years</option>
                      <option value="10+ years">10+ years</option>
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="NoOfDependents" className="mb-1 mt-3">
                      Number of dependents
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Input
                      name="NoOfDependents"
                      classes="form-control"
                      type="text"
                      id="numbersOnly"
                      onChange={handleInputChange}
                      defaultValue={profile.NoOfDependents}
                      onKeyPress={validate}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="eduLevel" className="mb-1 mt-3">
                      Educational level
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      name="eduLevel"
                      id="eduLevel"
                      className="form-control"
                      onChange={handleInputChange}
                      defaultValue={profile.eduLevel}
                      required
                    >
                      <option value="">Select educational level</option>
                      {Object.keys(EducationLevel).map(
                        (edu: any, i: number) => (
                          <option key={i} value={EducationLevel[edu]}>
                            {edu}
                          </option>
                        ),
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="profile-btn">
                    <Button
                      classes="primary-btn btn-lg"
                      content="Save and Continue"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default observer(LoansPersonalDetailsForm);
