import { mdiCheck, mdiCircleSmall, mdiPlayCircle } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import BlueCard from './sections/BlueCard';
import Footer from './sections/Footer';
import GetStarted from './sections/GetStarted';
import Header from './sections/Header';
import Media from './sections/Media';
import StatsSection from './sections/StatsSection';
import { Helmet } from 'react-helmet-async';
import Newsletter from './sections/Newsletter';

const ProductPage = () => {
  const [watchVideo, setWatchVideo] = useState(false);
  return (
    <div className="bg-white product-page">
      <Helmet>
        <title>Loan For Business | Trade Lenda Loan For Business!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      {watchVideo && <Media close={() => setWatchVideo(false)} />}
      <section className="landing-page-hero">
        <div className="container">
          <div className="hs-container2">
            <div className="row align-items-start justify-content-between">
              <div className="col-xl-5 col-lg-6">
                <h6 className="heading">
                  <span>PRODUCT</span>
                  <Icon path={mdiCircleSmall} size={2} />
                  <span>FINANCE</span>
                </h6>
                <div className="hero-text">
                  <h1>
                    Get <span className="color-text">instant loan</span> for
                    your SME
                  </h1>
                  <p className="text-lg-start text-center ">
                    No long wait, no need for collateral, get up to 10 million
                    naira loan within six hours
                  </p>
                  {/* <div className="d-flex">
                    <Button
                      classes="primary-btn btn-lg"
                      content="Get Loan"
                      route="/sign_up"
                    />
                  </div> */}
                  {/* <a
                    href={undefined}
                    role="button"
                    onClick={() => setWatchVideo(true)}
                    className="docu-link"
                  >
                    <Icon path={mdiPlayCircle} size={1} />
                    Watch Our Documentary
                  </a> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero-img">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/prod-hero-img3.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="container">
          <div className="heading-text d-flex flex-column align-items-center text-center">
            <h2>Check out finance option best for your business</h2>
            <Button
              classes="white-btn btn-lg mt-2"
              content="Learn More"
              route="/sign_up"
            />
          </div>
          <div>
            <div className="mb-5 group border border-3 rounded p-3">
              <div className="row align-items-center">
                <div className="col-lg-6 order-last order-lg-first">
                  <div className="hero-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/wc2.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hero-text">
                    <div className="">
                      <div>
                        <h3 className="mb-3">
                          Unlock the power of working capital.
                        </h3>
                        <p>
                          Are you struggling to keep up with day-to-day expenses
                          or looking to expand your business? Our working
                          capital solutions provide the funds you need to do the
                          following
                        </p>
                      </div>

                      <div className="mt-3">
                        <div className="d-flex align-items-start gap-3 mb-3">
                          <span>
                            <Icon path={mdiCheck} size={1.2} />
                          </span>
                          <p>Cover operating expenses</p>
                        </div>
                        <div className="d-flex align-items-start gap-3 mb-3">
                          <span>
                            <Icon path={mdiCheck} size={1.2} />
                          </span>
                          <p>Purchase inventory</p>
                        </div>
                        <div className="d-flex align-items-start gap-3 mb-3">
                          <span>
                            <Icon path={mdiCheck} size={1.2} />
                          </span>
                          <p>Invest in growth opportunities.</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <Button
                          classes="white-btn btn-lg mt-3"
                          content="Get Funding Now"
                          route="/sign_up"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mb-5 group border border-3 rounded p-3">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="hero-text">
                    <div className="">
                      <div>
                        <h3 className="mb-3">
                          Streamline your supply chain with our local purchase
                          order financing
                        </h3>
                        <p>
                          Are you a small or medium-sized business looking to
                          expand your operations and take on large orders? with
                          our purchase order financing you can do the following:
                        </p>
                      </div>

                      <div className="mt-3">
                        <div className="d-flex align-items-start gap-3 mb-3">
                          <span>
                            <Icon path={mdiCheck} size={1.2} />
                          </span>
                          <p>
                            Secure funding to purchase inventory and materials
                          </p>
                        </div>
                        <div className="d-flex align-items-start gap-3 mb-3">
                          <span>
                            <Icon path={mdiCheck} size={1.2} />
                          </span>
                          <p>Secure funding to fulfill large orders</p>
                        </div>
                        <div className="d-flex align-items-start gap-3 mb-3">
                          <span>
                            <Icon path={mdiCheck} size={1.2} />
                          </span>
                          <p>Grow your business</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <Button
                          classes="white-btn btn-lg mt-3"
                          content="Get Funding Now"
                          route="/sign_up"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hero-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/sc2.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="group border border-3 rounded p-3">
              <div className="row align-items-center">
                <div className="col-lg-6 order-last order-lg-first">
                  <div className="hero-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/cf2.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hero-text">
                    <div className="">
                      <div>
                        <h3 className="mb-3">
                          Improve your cash flow with invoice discounting
                          finance.
                        </h3>
                        <p>
                          Are you tired of waiting for your customers to pay
                          their invoices? We provide the funding you need to
                          improve your cash flow and keep your business running
                          smoothly. How?
                        </p>
                      </div>

                      <div className="mt-3">
                        <div className="d-flex align-items-start gap-3 mb-3">
                          <span>
                            <Icon path={mdiCheck} size={1.2} />
                          </span>
                          <p>
                            We'll advance you a portion of the invoice amount
                            and you will receive the remaining balance once your
                            customer pays.
                          </p>
                        </div>
                        <div className="d-flex align-istart gap-3 mb-3">
                          <span>
                            <Icon path={mdiCheck} size={1.2} />
                          </span>
                          <p>
                            You can get paid faster for the goods or services
                            you've already provided.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <Button
                          classes="white-btn btn-lg mt-3"
                          content="Get Funding Now"
                          route="/sign_up"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <StatsSection />
      <div className="started-section">
        <div className="row align-items-center text-lg-start text-center">
          <div className="col-lg-9">
            <div>
              <p>Try Trade Lenda now</p>
              <h2 className="my-4">Ready to get started?</h2>
              <p>Take your business to the next level with Trade Lenda</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div>
              <Button
                classes="green-btn btn-lg"
                content="Create An Account"
                route="/sign_up"
              />
            </div>
          </div>
        </div>
      </div>

      <BlueCard />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default ProductPage;
