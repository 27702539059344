/* eslint-disable @typescript-eslint/no-explicit-any */
import Icon from '@mdi/react';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  links: any;
};

const DropDownMobile: FC<Props> = ({ links }) => {
  return (
    <div className="header-dropdown">
      <div
        className="dropdown-links"
        style={{
          position: 'relative',
          left: '15%',
        }}
      >
        {links &&
          links.map(
            (link: any, i: number) => (
              // link.url.includes('http') ? (
              <NavLink to={link.url} key={i} onClick={close}>
                <div className="d-flex align-items-center gap-3">
                  <span className="link-icon mt-2 mb-2">
                    <Icon path={link.icon} size={0.8} />
                  </span>
                  <span className="link-text mt-2 mb-2">{link.text}</span>
                </div>
                {link.comingSoon && (
                  <span className="badge rounded-pill text-bg-light">
                    Coming Soon
                  </span>
                )}
              </NavLink>
            ),
            // ) : (
            // ),
          )}
      </div>
    </div>
  );
};

export default DropDownMobile;
