/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { StoreContext } from '../../ mobx stores/RootStore';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import { toast } from 'react-toastify';
import Spinner from '../../components/ui/Spinner';
import { observer } from 'mobx-react-lite';
import Icon from '@mdi/react';
import { mdiEye, mdiEyeOff } from '@mdi/js';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const location: any = useLocation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordd, setShowPasswordd] = useState(false);

  const { authStore } = useContext(StoreContext);
  const { loading, message } = authStore;

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const queryString = require('query-string');
  const parsed = queryString.parse(location?.state?.continueUrl?.split('?')[1]);

  const handleResetPassword = () => {
    if (newPassword !== '' || confirmPassword !== '') {
      if (newPassword === confirmPassword) {
        authStore.ResetPassword(newPassword, location?.state?.oobCode);
      } else {
        toast.error('Passwords not the same.');
        return;
      }
    } else {
      toast.error('One or more inputs empty.');
      return;
    }
  };

  useEffect(() => {
    if (
      message?.type === 'success' &&
      parsed?.email &&
      parsed?.email?.includes('@tradelenda.com') &&
      parsed?.email !== 'test@tradelenda.com'
    ) {
      window.location.replace('https://legend.tradelenda.com');
    } else if (
      message?.type === 'success' &&
      !parsed.email.includes('@tradelenda.com')
    ) {
      navigate('/login');
    }
  }, [message.type]);

  return (
    <>
      <div className="forgot-password">
        {loading && <Spinner />}

        {/* <div className="pattern-group blue">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/blueangle.png`}
            alt=""
            style={{ width: '10%', margin: '0', right: '0' }}
          />
        </div> */}
        <div className="container">
          <div className="logo ">
            <Link to="/">
              <img
                src={`${BaseDirectories.LOGOS_DIR}/Logo2.png`}
                alt=""
                // style={{ width: '15%' }}
                className="w-15"
              />
            </Link>
          </div>
          <div className="card h-100">
            {/* <img
              src={`${BaseDirectories.IMAGES_DIR}/unlocked.png`}
              alt=""
              style={{ width: '10%', margin: '10px auto 20px auto' }}
            /> */}
            <h2>Reset Password</h2>
            <p>Please input your new password.</p>
            <hr />
            <div className="col-12 mb-2 email-input position-relative">
              <label htmlFor="password" className="col-12  mb-1 mt-1">
                New Password
              </label>
              <Input
                name="password"
                classes="form-control-sm col-12"
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={newPassword}
                onChange={(e: any) => setNewPassword(e.target.value)}
              />
              <span
                className="toggle-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                <Icon
                  path={showPassword ? mdiEyeOff : mdiEye}
                  vertical
                  size={1}
                />
              </span>
            </div>
            <div className="col-12 mb-2 email-input position-relative">
              <label htmlFor="confirm_password" className="col-12  mb-1 mt-1">
                Confirm Password
              </label>
              <Input
                name="confirm_password"
                classes="form-control-sm col-12"
                type={showPasswordd ? 'text' : 'password'}
                id="confrim_password"
                value={confirmPassword}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
              />
              <span
                className="toggle-password"
                onClick={() => setShowPasswordd(!showPasswordd)}
              >
                <Icon
                  path={showPasswordd ? mdiEyeOff : mdiEye}
                  vertical
                  size={1}
                />
              </span>
            </div>
            <Button
              content="Reset password"
              classes="primary-btn btn-md col-12 mb-5 mt-3"
              type="button"
              onClick={handleResetPassword}
            />
          </div>
        </div>
        {/* <div className="pattern-group green">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/greenangle.png`}
            alt=""
            style={{ width: '10%' }}
          />
        </div> */}
      </div>
    </>
  );
};

export default observer(ResetPassword);
