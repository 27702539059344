/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { observer } from 'mobx-react-lite';
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import Button from '../ui/Button';
import { toast } from 'react-toastify';
import { StoreContext } from '../../mobx_stores/RootStore';
import OtpInput from 'react-otp-input';
type Props = {
  next: () => void;
  previous: () => void;
  setX: (val: number) => void;
};

const CreatePinForm: FC<Props> = () => {
  const { profileStore } = useContext(StoreContext);
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [pinToggle, setPinToggle] = useState(false);
  const [pin, setPin] = useState({
    newPin: 'string',
    confirmPin: 'string',
  });

  const { submitting, message } = profileStore;

  useEffect(() => {
    setPin({
      newPin,
      confirmPin,
    });
  }, [newPin, confirmPin]);

  const handleCreatePin = (e: FormEvent) => {
    e.preventDefault();

    if (
      pin.newPin !== pin.confirmPin ||
      pin.newPin === '' ||
      pin.confirmPin === ''
    ) {
      toast.error('Pins do not match');
      return;
    }
    profileStore.createPin(pin);
    localStorage.removeItem('accountTypeSession');
  };

  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  return (
    <div className="row bg-grey justify-content-center mt-0">
      <div className="col-lg-7 bg-grey col-xl-5 col-md-8 col-sm-10">
        <div className="add-card-box bg-grey p-sm-2 p-2">
          <form onSubmit={handleCreatePin}>
            <div className="py-0 px-4 rounded mt-2">
              <div className="row">
                <div className="col-md-12 input-box text-center">
                  <label className="mb-1">
                    Enter Pin
                    <span style={{ color: 'red' }}> *</span>
                  </label>
                  <OtpInput
                    value={newPin}
                    onChange={setNewPin}
                    numInputs={4}
                    inputStyle="pin-style"
                    containerStyle="pin-container-style"
                    inputType={pinToggle ? 'tel' : 'password'}
                    shouldAutoFocus={true}
                    renderInput={(props) => (
                      <input
                        onKeyPress={validateNumber}
                        {...props}
                        placeholder="*"
                      />
                    )}
                  />
                </div>
                <div className="col-md-12 input-box">
                  <div className="text-center">
                    <label className="mb-1">
                      Confirm Pin
                      <span style={{ color: 'red' }}> *</span>
                    </label>
                    <OtpInput
                      value={confirmPin}
                      onChange={setConfirmPin}
                      numInputs={4}
                      inputStyle="pin-style"
                      containerStyle="pin-container-style"
                      inputType={pinToggle ? 'tel' : 'password'}
                      shouldAutoFocus={false}
                      renderInput={(props) => (
                        <input
                          onKeyPress={validateNumber}
                          {...props}
                          placeholder="*"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex gap-2 justify-content-center align-items-center mb-3">
                  <input
                    type="checkbox"
                    onChange={handlePinToggle}
                    name="showPin"
                    id="show-pin"
                  />
                  <label htmlFor="show-pin">Show pin</label>
                </div>
              </div>
              {message.msg !== '' && (
                <div
                  className={
                    message.type === 'success'
                      ? ' alert alert-success'
                      : 'alert alert-danger'
                  }
                  role="alert"
                >
                  {message.msg}
                </div>
              )}
              <Button
                classes={`${
                  submitting
                    ? 'primary-btn-disabled btn-lg btn-block w-100 px-4'
                    : 'primary-btn btn-lg btn-block w-100 px-4 w-100'
                }`}
                content={`${submitting ? 'Creating...' : 'Create Pin'}`}
                disabled={submitting}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default observer(CreatePinForm);
