/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import { runInAction } from 'mobx';
import Button from '../../../../components/ui/Button';
import { auth } from '../../../../firebase/Firebase';
import BaseDirectories from '../../../../base directories/BaseDirectories';

const LimitData = [
  {
    key: 'Select Limit',
    value: '',
  },
  {
    key: '10,000',
    value: 10000,
  },
  {
    key: '50,000',
    value: 50000,
  },
  {
    key: '100,000',
    value: 100000,
  },
  {
    key: '200,000',
    value: 200000,
  },
  {
    key: '500,000',
    value: 500000,
  },
  {
    key: '1,000,000',
    value: 1000000,
  },
  {
    key: '5,000,000',
    value: 5000000,
  },
  {
    key: '10,000,000',
    value: 10000000,
  },
];
const ChangeLimitModal = ({
  setShowChangeLimitModal,
  transactionLimit,
  dailyTransactionLimit,
}) => {
  const { walletStore } = useContext(StoreContext);
  const { message, setMessage } = walletStore;
  const { submitting } = walletStore;
  const [data, setData] = useState({
    transactionLimit: 0,
    dailyTransactionLimit: 0,
  });

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();
    const element = e.target as HTMLInputElement;
    setData((prevDetails: any) => {
      return { ...prevDetails, [element.name]: parseInt(element.value) };
    });
  };
  const handleHideVerificationModal = () => {
    setShowChangeLimitModal(false);
  };

  const showRequestSent = async () => {
    const user: any = auth.currentUser;
    if (user) {
      walletStore.changeLimit(data, handleHideVerificationModal);
    }
  };

  useEffect(() => {
    if (
      (message.type === 'success!',
      message.msg === 'limit increase successful!')
    ) {
      setShowChangeLimitModal(false);
    }
  }, [message.type, message.msg]);

  return (
    <div className="notification-modal">
      <div className="bg-white notification-box">
        <span
          className="close"
          role="button"
          onClick={() => {
            setShowChangeLimitModal(false);
            runInAction(() => {
              setMessage(' ', ' ');
            });
          }}
        >
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="close"
          />
        </span>
        <img
          src={`${BaseDirectories.IMAGES_DIR}/greenarrowforward.svg`}
          alt=""
          style={{ width: '20%' }}
          className="mb-4"
        />
        <h3 className="mb-2 mt-2">Increase Transfer Limit</h3>
        <div
          className="col-12"
          style={{
            textAlign: 'left',
          }}
        >
          <label htmlFor="transactionLimit" className="mb-1 mt-3 ">
            Single Transaction Limit
          </label>
          <select
            name="transactionLimit"
            id="transactionLimit"
            className="form-control p-3"
            onChange={handleInputChange}
            required
          >
            {LimitData.map((limit: any, index: number) => (
              <option key={index} value={limit.value}>
                {limit.key}
              </option>
            ))}
          </select>
          <small className=" m-1">
            Current Limit:{' '}
            {transactionLimit && transactionLimit?.toLocaleString()}
          </small>
        </div>

        <div
          className="col-12"
          style={{
            textAlign: 'left',
          }}
        >
          <label htmlFor="dailyTransactionLimit" className="mb-1 mt-3">
            Daily Transaction Limit
          </label>
          <select
            name="dailyTransactionLimit"
            id="dailyTransactionLimit"
            className="form-control p-3"
            required
            onChange={handleInputChange}
          >
            {LimitData.map((limit: any, index: number) => (
              <option key={index} value={limit.value}>
                {limit.key}
              </option>
            ))}
          </select>
          <small className="m-1 mb-2">
            Current Limit:{' '}
            {dailyTransactionLimit && dailyTransactionLimit?.toLocaleString()}
          </small>
        </div>
        <div className="view-group mt-4">
          <Button
            type="button"
            classes={`${
              submitting
                ? 'primary-btn btn-sm text-sm  btn-block px-4'
                : 'primary-btn btn-sm text-sm btn-block px-4'
            }`}
            content={`${submitting ? 'changing...' : 'Set Limit'}`}
            disabled={submitting}
            onClick={showRequestSent}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(ChangeLimitModal);
