import { mdiCircleSmall, mdiPlayCircle } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import Footer from './sections/Footer';
import GetStarted from './sections/GetStarted';
import Header from './sections/Header';
import Media from './sections/Media';
import Newsletter from './sections/Newsletter';
import Team from './sections/Team';
import Values from './sections/Values';
import { Helmet } from 'react-helmet-async';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-creative';

import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
  FreeMode,
} from 'swiper';

const AboutUs = () => {
  const [watchVideo, setWatchVideo] = useState(false);
  return (
    <div className="about bg-white">
      <Helmet>
        <title>About Us | Trade Lenda About Us!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      {watchVideo && <Media close={() => setWatchVideo(false)} />}
      <section className="landing-page-hero">
        <div className="container">
          <div className="hs-container2 row">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="hero-text">
                  {/* <img
                    src={`${BaseDirectories.IMAGES_DIR}/world-map.png`}
                    alt="Trade Lenda"
                    className="au-hero-img"
                  /> */}
                  <div className="au-hi-text">
                    <h6>
                      COMPANY
                      <Icon path={mdiCircleSmall} size={2} />
                      <span>ABOUT US</span>
                    </h6>
                    <h1>Offering financing to African SMEs.</h1>
                    <a
                      href={undefined}
                      role="button"
                      onClick={() => setWatchVideo(true)}
                      className="docu-link"
                    >
                      <Icon path={mdiPlayCircle} size={1} />
                      Watch Our Documentary
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero-img">
                  <Swiper
                    className="about_swiper_container"
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    freeMode={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                      rotate: 0,
                      stretch: 0,
                      depth: 100,
                      modifier: 2.5,
                    }}
                    pagination={{ el: 'swiper-pagination', clickable: true }}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    modules={[
                      EffectCoverflow,
                      Autoplay,
                      FreeMode,
                      Pagination,
                      Navigation,
                    ]}
                  >
                    <SwiperSlide>
                      <img
                        src={`${BaseDirectories.IMAGES_DIR}/about1.png`}
                        alt="slide_image"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={`${BaseDirectories.IMAGES_DIR}/about2.png`}
                        alt="slide_image"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={`${BaseDirectories.IMAGES_DIR}/about3.png`}
                        alt="slide_image"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={`${BaseDirectories.IMAGES_DIR}/about4.png`}
                        alt="slide_image"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={`${BaseDirectories.IMAGES_DIR}/about5.png`}
                        alt="slide_image"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="container">
          <div>
            <div className="mb-5 group">
              <div className="row align-items-center">
                <div className="col-lg-6 order-last order-lg-first">
                  <div className="hero-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/firstAbout1.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hero-text">
                    <div className="">
                      <h6 className="mission-text">OUR MISSION</h6>
                      <div>
                        <h3 className="mb-3 mission-text">
                          SMEs
                          <Icon path={mdiCircleSmall} size={2} />
                          Finance <Icon path={mdiCircleSmall} size={2} />
                          Growth
                        </h3>
                        <p>
                          We believe that every business has a higher potential
                          to grow bigger when provided financial support. And we
                          are on a mission to help SMEs accross emerging markets
                          access financing for business growth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mb-5 group">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="hero-text">
                    <div className="">
                      <h6 className="mission-text">WHO WE ARE</h6>
                      <div>
                        <h3 className="mb-3 mission-text">
                          Digital bank for SMEs
                        </h3>
                        <p>
                          Trade Lenda is a digital bank for Small and Medium
                          Scale Enterprises (SMEs), empowering them through
                          strategic financing to sustain business growth and
                          contribute towards economic prosperity in line with
                          the United Nations Sustainable Development Goal 1, 2,
                          5,8 and 9. Our vision is to develop financial
                          solutions for SMEs capable of delivering the next 1
                          million jobs across Africa by 2027.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hero-img">
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/aboutLast.png`}
                      alt="Trade Lenda"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Values />
      <Team />
      {/* <GetStarted /> */}
      {/* Let's grow together */}
      <div className="sdg-container">
        <h1>Why we exist</h1>
        <div className="sdg-sub-container">
          <div className="sdg-sub1">
            <div className="sdg-sub">
              <div>
                <span className="sdg-sub1-span">SDG 1</span>
              </div>
              <div>
                <span>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/sdg-icon.png`}
                    alt="Trade Lenda"
                    className="au-hero-img"
                  />
                </span>
              </div>
            </div>
            <h4 className="sdg-sub1-h4">No Poverty</h4>
            <p className="sdg-sub1-p">
              Eradicating poverty is not a task of charity, it’s an act of
              justice and the key to unlocking an enormous human potential.
            </p>
            <div className="horizontal-lines">
              <div className="line red"></div>
              <div className="line green"></div>
              <div className="line blue"></div>
            </div>
          </div>
          <div className="sdg-sub2">
            <div className="sdg-sub">
              <div>
                <span className="sdg-sub1-span">SDG 2</span>
              </div>
              <div>
                <span>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/sdg-icon.png`}
                    alt="Trade Lenda"
                    className="au-hero-img"
                  />
                </span>
              </div>
            </div>
            <h4 className="sdg-sub1-h4">Zero Hunger</h4>
            <p className="sdg-sub1-p">
              Hunger is the leading cause of death in the world. Our planet has
              provided us with tremendous resources, but unequal access and
              inefficient handling leaves millions of people malnourished.
            </p>
            <div className="horizontal-lines">
              <div className="line red"></div>
              <div className="line green"></div>
              <div className="line blue"></div>
            </div>
          </div>
          <div className="sdg-sub3">
            <div className="sdg-sub">
              <div>
                <span className="sdg-sub1-span">SDG 5</span>
              </div>
              <div>
                <span>
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/sdg-icon.png`}
                    alt="Trade Lenda"
                    className="au-hero-img"
                  />
                </span>
              </div>
            </div>
            <h4 className="sdg-sub1-h4">Gender Equality</h4>
            <p className="sdg-sub1-p">
              Gender bias is undermining our social fabric and devalues all of
              us. It is not just a human rights issue; it is a tremendous waste
              of the world’s human potential.
            </p>
            <div className="horizontal-lines">
              <div className="line red"></div>
              <div className="line green"></div>
              <div className="line blue"></div>
            </div>
          </div>
          <div className="row w-100 justify-content-center gap-4">
            <div className="sdg-sub4 col-lg-4">
              <div className="sdg-sub">
                <div>
                  <span className="sdg-sub1-span">SDG 8</span>
                </div>
                <div>
                  <span>
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/sdg-icon.png`}
                      alt="Trade Lenda"
                      className="au-hero-img"
                    />
                  </span>
                </div>
              </div>
              <h4 className="sdg-sub1-h4">Decent work and economic growth</h4>
              <p className="sdg-sub1-p">
                Economic growth should be a positive force for the whole
                planet.This is why we must make sure that financial progress
                creates decent and fulfilling jobs while not harming the
                environment.
              </p>
              <div className="horizontal-lines">
                <div className="line red"></div>
                <div className="line green"></div>
                <div className="line blue"></div>
              </div>
            </div>
            <div className="sdg-sub1 col-lg-4">
              <div className="sdg-sub">
                <div>
                  <span className="sdg-sub1-span">SDG 9</span>
                </div>
                <div>
                  <span>
                    <img
                      src={`${BaseDirectories.IMAGES_DIR}/sdg-icon.png`}
                      alt="Trade Lenda"
                      className="au-hero-img"
                    />
                  </span>
                </div>
              </div>
              <h4 className="sdg-sub1-h4">
                Industry, innovation and infrastructure
              </h4>
              <p className="sdg-sub1-p">
                A functioning and resilient infrastructure is the foundation of
                every successful community. To meet future challenges, our
                industries and infrastructure must be upgraded. 
              </p>
              <div className="horizontal-lines">
                <div className="line red"></div>
                <div className="line green"></div>
                <div className="line blue"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lets-grow">
        {/* <div className="container">
          <div className="lp-team">
            <h2>Let’s grow together</h2>
            <p>
              Join the coolest team who are passionate about their work and
              exhibit both personal and professional pride in the products and
              services we provide to customers.
            </p>
            <Button
              classes="primary-btn btn-lg mb-5 mt-4"
              content="See Open Roles"
              route="/careers"
            />
          </div>
        </div> */}
      </div>
      <GetStarted />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default AboutUs;
