/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { StoreContext } from '../../../ mobx stores/RootStore';
import Button from '../../../components/ui/Button';
import ReactToPrint from 'react-to-print';
import Spinner from '../../../components/ui/Spinner';
import { observer } from 'mobx-react-lite';
import BaseDirectories from '../../../base directories/BaseDirectories';
import { toJS } from 'mobx';

// import { ComponentToPrint } from './ComponentToPrint';

interface LocationState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const OfferLetter = () => {
  const [allLoans, setAllLoans] = useState<any>([]);
  const [loanDetails, setLoanDetails] = useState<any>({});
  const [loanState, setLoanState] = useState<any>({});
  const [userGuarantor, setUserGuarantor] = useState<any>({});
  const location: LocationState = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const converter = require('number-to-words');
  const componentRef: MutableRefObject<any> = useRef();

  const { loansStore, guarantorStore, walletStore } = useContext(StoreContext);
  const { loading, message, loanUserdetails } = loansStore;
  const { guarantors } = guarantorStore;
  const { wallet } = walletStore;

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  useEffect(() => {
    // allLoans.filter((a: any) => a._id === location?.state?.loan?._id)[0];
    setLoanDetails(
      allLoans.filter((a: any) => a._id === location?.state?.loan?._id)[0],
    );
    setLoanState(location?.state?.loanState);
  }, [location, loansStore, allLoans]);

  useEffect(() => {
    const unsub = () => {
      guarantorStore.getGuarantors();
      guarantorStore.setGuarantors;
      setUserGuarantor(guarantors[0]);
    };
    return () => unsub();
  }, [location]);

  useEffect(() => {
    loansStore.getLoanById(location?.state?.loan?._id);
    loansStore.getAllLoans();
    // loansStore.checkRecurrentDebit(location?.state?.loan?._id);
  }, [loansStore, location]);

  useEffect(() => {
    setAllLoans(toJS(loansStore.loans));
  }, [loansStore.loans]);

  const handleAcceptLoan = () => {
    if (loanDetails._id.length > 0) {
      loansStore.acceptLoan(loanDetails._id);
    }
  };

  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const getCurrentDate = () => {
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();

    return `${date} ${monthList[month]}, ${year}`;
  };
  // console.log(location?.state);

  useEffect(() => {
    if (message.msg === 'You have successfully accepted loan.') {
      // window.location.reload();
      loansStore.getAllLoans();
      loansStore.getLoanById(loanDetails._id);
    }
  }, [message.type]);

  return (
    <div className="">
      {loading && <Spinner />}
      <div className="loans-container bg-white offer-letter pb-4">
        <div className="bg-white p-md-5 p-sm-4 p-3" ref={componentRef}>
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h6>{getCurrentDate()}</h6>
              <img
                style={{ width: '120px' }}
                src={`${BaseDirectories.LOGOS_DIR}/logo1.png`}
                alt=""
              />
            </div>
            <h6 className="mb-1">THE MANAGING DIRECTOR</h6>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.businessName},
            </p>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.businessAddress},
            </p>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.city},
            </p>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.state}.
            </p>
          </div>
          <h6 className="my-4">
            Attention: {`${loanDetails?.firstName} ${loanDetails?.lastName}`}
          </h6>
          <p>Dear Sir/Ma,</p>
          <div>
            <h5 className="mb-3" style={{ textTransform: 'uppercase' }}>
              OFFER FOR {toJS(loansStore.loanId)?.loanType} OF{' '}
              {loanDetails?.amount && converter.toWords(loanDetails?.amount)}{' '}
              naira (₦
              {loanDetails?.amount?.toLocaleString()})
            </h5>
            <p>
              Sequel to your application for a{' '}
              <b>{toJS(loansStore.loanId)?.loanType}</b>, we are pleased to
              inform you that the Management of Trade Lenda has approved the
              request under the following terms and conditions:
            </p>
          </div>

          <div>
            <h5 className="my-3">SUMMARY OF TERMS AND CONDITIONS:</h5>
            <div className="d-flex justify-content-between term-group align-items-center mb-3">
              <h6 className="term">LENDER:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                Trade Lenda Com Limited
              </p>
            </div>
            <div className="d-flex justify-content-between term-group align-items-center mb-3">
              <h6 className="term">BORROWER:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                {loanDetails?.loanDetails?.organizationDetails?.businessName}
              </p>
            </div>
            <div className="d-flex justify-content-between term-group align-items-center mb-3">
              <h6 className="term">AMOUNT:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                Total amount of{' '}
                <b>
                  {loanDetails?.amount &&
                    converter.toWords(loanDetails?.amount)}{' '}
                  naira (₦
                  {loanDetails?.amount?.toLocaleString()})
                </b>
              </p>
            </div>
            <div className="d-flex justify-content-between term-group align-items-center mb-3">
              <h6 className="term">PURPOSE:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                {loanDetails?.reason}
              </p>
            </div>
            <div className="d-flex justify-content-between term-group align-items-center mb-3">
              <h6 className="term">TENOR:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                {loanDetails?.loanTenor}
              </p>
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">DISBURSEMENT:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                {loanUserdetails?.organizationDetails?.shariaCom
                  ? `Disbursement will be made at once to TradeLenda's verified merchant who will provide the goods or services.`
                  : `Disbursement will be made at once to customers account provided
                  on our platform (
                  ${wallet?.walletIdAccountNumber}${', '}
                    TradeLenda Wallet
                  )`}
                {/* Disbursement will be made at once to customers account provided
                on our platform (
                {loanDetails?.loanDetails?.bankDetails?.bankAccountNumber}{' '}
                {loanDetails?.loanDetails?.bankDetails?.bankName}) */}
              </p>
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">REPAYMENT:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                {loanUserdetails?.organizationDetails?.shariaCom
                  ? `Monthly Principal + Mark-up repayment based on repayment schedule to be paid on demand and/or expiry of the facility
                            whichever is earlier to an account provided by Trade Lenda. In
                            case of LPOs, payment shall be in full with mark-up`
                  : `Monthly interest repayment while Principal+ Interest on the
                            final month to be paid on demand and/or expiry of the facility
                            whichever is earlier to an account provided by Trade Lenda. In
                            case of LPOs, payment shall be in full with interest`}
                {/* Monthly interest repayment while Principal+ Interest on the
                final month to be paid on demand and/or expiry of the facility
                whichever is earlier to an account provided by Trade Lenda. In
                case of LPOs, payment shall be in full with interest */}
              </p>
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">REPAYMENT SOURCE:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                Cash flow from operations and/ or any source acceptable to the
                Lender (as approved).
              </p>
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">SECURITY/SUPPORT:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                Personal guarantee of the MD/CEO. Charge over{' '}
                <strong>
                  {loanDetails?.loanDetails?.organizationDetails?.businessName}
                </strong>{' '}
                inventory and purchase order inflows.
              </p>
            </div>
            <div className="d-flex justify-content-between term-group align-items-center mb-3">
              <h6 className="term">PRICING:</h6>

              {loanDetails?.loanDetails?.organizationDetails?.shariaCom ==
              true ? (
                <p
                  className="mb-0 term-value"
                  style={{
                    // maxWidth: '560px',
                    width: '65%',
                    textAlign: 'justify',
                  }}
                >
                  The facility will bear a 5% markup all inclusive.
                </p>
              ) : (
                <p
                  className="mb-0 term-value"
                  style={{
                    // maxWidth: '560px',
                    width: '65%',
                    textAlign: 'justify',
                  }}
                >
                  The facility will bear a 5% monthly all inclusive.
                </p>
              )}
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">DOCUMENTATION:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                The{' '}
                {loanUserdetails?.organizationDetails?.shariaCom
                  ? 'facility'
                  : 'loan'}{' '}
                facility described above will be subject to this document
                execution and other documentation which will contain terms and
                conditions, and covenants satisfactory in form and substance to
                the lender, including but not limited to those described in this
                document.
              </p>
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">
                METHOD OF{' '}
                {loanUserdetails?.organizationDetails?.shariaCom
                  ? 'FACILITY'
                  : 'LOAN'}{' '}
                PAYMENT:
              </h6>
              <div
                className="term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                <p className="mb-2">
                  The Borrower shall fund account to accommodate monthly
                  interest and on the maturity date to allow for auto debit.
                  Should the customer choose early repayment, same shall be done
                  to the below accounts with customer’s ID as reference
                </p>
                <h6 className="my-2">EARLY REPAYMENT ACCOUNT DETAILS</h6>
                <p>Zenith: Trade Lenda Com Ltd – 1215738966(NGN)</p>
                <p>FSDH: Trade Lenda Com Ltd - 1000128813 (NGN)</p>
              </div>
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">DEFAULT:</h6>
              <span
                className="mb-0 term-value spanP"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                <span>
                  The occurrence of any of the following events shall constitute
                  a Default by the BORROWER of the terms of this{' '}
                  {loanUserdetails?.organizationDetails?.shariaCom
                    ? 'facility'
                    : 'loan'}{' '}
                  agreement and promissory note:
                </span>
                <ol>
                  <li>
                    BORROWER’S failure to pay any amount due as principal or
                    interest on the date required under this{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loan'}{' '}
                    agreement.
                  </li>
                  <li>
                    BORROWER seeks an order of relief under the Federal
                    Bankruptcy laws.{' '}
                  </li>
                  <li>
                    A federal tax lien is filed against the assets of BORROWER.{' '}
                  </li>
                </ol>
              </span>
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">OTHER DEFAULT PROVISIONS:</h6>
              <span
                className="mb-0 term-value spanP"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                <ol>
                  <li>
                    Addressee and Address to which LENDER is to give BORROWER
                    written notice of default: If BORROWER gives written notice
                    to LENDER that a different address shall be used, LENDER
                    shall use that address for giving notice of default (or any
                    other notice called for herein) to BORROWER.
                  </li>
                  <li>
                    Cure of Default. Upon default, LENDER shall give BORROWER
                    written notice of default. Mailing of written notice by
                    LENDER to BORROWER via email shall constitute prima facie
                    evidence of delivery. BORROWER shall have 15 days after
                    receipt of written notice of default from LENDER to cure
                    said default. In the case of default due solely to
                    BORROWER’S failure to make timely payment as called for in
                    this{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loan'}{' '}
                    agreement, BORROWER may cure the default by either: (i)
                    making full payment of any principal and accrued interest
                    (including interest on these amounts) whose payment to
                    LENDER is overdue under the{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loan'}{' '}
                    agreement and, also, the late-payment penalty described
                    below; or (ii) release collateral to LENDER as described in
                    “Collateral”, above.
                  </li>
                  <li>
                    Penalty for Late Payment. There shall also be imposed upon
                    BORROWER a monthly 1% penalty for any late payment computed
                    upon the amount of any principal and accrued interest whose
                    payment to LENDER is overdue under this{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loan'}{' '}
                    agreement and for which LENDER has delivered a notice of
                    default to BORROWER.
                  </li>
                  <li>
                    Indemnification of Attorney’s Fees and Out-of-Pocket Costs:
                    Should any party materially breach this agreement, the
                    non-breaching party shall be indemnified by the breaching
                    party for its reasonable attorney’s fees and out-of-pocket
                    costs which in any way relate to, or were precipitated by,
                    the breach of this agreement. The term “out-of-pocket
                    costs”, as used herein, shall not include lost profits. A
                    default by BORROWER which is not cured within 15 days after
                    receiving a written notice of default from LENDER
                    constitutes a material breach of this agreement by BORROWER
                  </li>
                </ol>
              </span>
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">SEVERABILITY:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                In the event any provision of this Agreement is deemed to be
                void, invalid, or unenforceable, that provision shall be severed
                from the remainder of this Agreement so as not to cause the
                invalidity or unenforceability of the remainder of this
                Agreement. All remaining provisions of this Agreement shall then
                continue in full force and effect. If any provision shall be
                deemed invalid due to its scope or breadth, such provision shall
                be deemed valid to the extent of the scope and breadth permitted
                by law.
              </p>
            </div>
            <div className="d-flex justify-content-between term-group mb-3">
              <h6 className="term">MODIFICATION:</h6>
              <p
                className="mb-0 term-value"
                style={{
                  // maxWidth: '560px',
                  width: '65%',
                  textAlign: 'justify',
                }}
              >
                Except as otherwise provided in this document, this agreement
                may be modified, superseded, or voided only upon the written and
                signed agreement of the Parties. Further, the physical
                destruction or loss of this document shall not be construed as a
                modification or termination of the agreement contained herein.
              </p>
            </div>
            <div className="mb-3">
              <h6 className="term">OTHER CONDITIONS:</h6>
              <span className="mb-0 spanP" style={{ textAlign: 'justify' }}>
                <ol>
                  <li>
                    Borrower to allow Lender’s Representative unhindered access
                    to their locations for the purpose of validating efficient
                    use of funds at every time.
                  </li>
                  <li>
                    This offer is subject to the condition that there shall be
                    no material adverse change in the financial condition of the
                    Borrower prior to and in any event at any time before the
                    disbursement of this facility.
                  </li>
                  <li>
                    The Lender reserves the right to refuse or withhold
                    disbursements under the facility without notice.
                  </li>
                  <li>
                    The Lender reserves the right to convert the facility into
                    any money market instrument and/ or sell the entire debt or
                    portions of it at its discretion and without recourse and/or
                    consent from the Borrower.
                  </li>
                  <li>
                    The Borrower is at liberty to repay all outstanding
                    obligations at any time during the validity of this
                    facility.
                  </li>
                  <li>
                    The Borrower covenant and warrant that the CBN shall have
                    power to set-off the Borrower’s indebtedness under this{' '}
                    {loanUserdetails?.organizationDetails?.shariaCom
                      ? 'facility'
                      : 'loan'}
                    agreement from all such monies and funds standing to the
                    Borrower’s credit/benefit in any and all such accounts or
                    from any other financial assets belonging to the Borrower
                    and in the custody of any such bank.
                  </li>
                  <li>
                    The Borrower hereby waive any right of confidentiality
                    whether arising under common law or statute or in any other
                    manner whatsoever and irrevocably agree that the Borrower
                    shall not argue to the contrary before any court of law,
                    tribunal, administrative authority or any other body acting
                    in any judicial or quasi-judicial capacity. All other rights
                    of the Borrower are reserved.
                  </li>
                  <li>
                    The Lender has the rights to confirm all contracts relating
                    to the use of funds from counterparties involved.
                  </li>
                </ol>
              </span>
            </div>
          </div>
          <div className="mb-3 text-justify" style={{ textAlign: 'justify' }}>
            <h5 className="mb-2">ASSIGNMENT:</h5>
            <p className="mb-0">
              This Offer Letter shall be binding and valid for the benefit of
              the Lender and the Borrower as well as their respective successors
              and assigns such that the Borrower shall not assign or transfer
              its rights or obligations hereunder without the prior consent of
              the Lender in writing. The Borrower shall immediately notify the
              lender in the event that it is involved in or intends to be
              engaged in any scheme of merger, acquisition, divestiture or other
              business plan that may alter its ownership structure or business
              outlook however that the Trade Lenda may recall the{' '}
              {loanUserdetails?.organizationDetails?.shariaCom
                ? 'facility'
                : 'loan'}{' '}
              or facility if in its opinion the merger, acquisition or business
              plan will impair the ability of the Borrower to repay the{' '}
              {loanUserdetails?.organizationDetails?.shariaCom
                ? 'facility'
                : 'loan'}{' '}
              or facility.
            </p>
            <p>
              Notwithstanding the terms and conditions of this offer, the Lender
              reserves the right to demand immediate repayment and/or alter the
              terms at any time at its sole discretion.
            </p>
            <p>
              Kindly signify your acceptance of this offer by
              acknowledging/signing and returning the counterpart copy of this
              letter to the Lender.
            </p>
            <p>
              This offer is open for acceptance within 14 days of the date of
              the offer after which it will lapse.
            </p>
          </div>
          <div
            className="mb-3 ceo"
            style={{
              position: 'relative',
              display: 'inline-block',
              background: `url(${BaseDirectories.IMAGES_DIR}/Adeshina Signature-01.png) no-repeat`,
              backgroundPosition: 'center -18px',
              backgroundSize: 'cover',
            }}
          >
            <p className="mb-1">Yours faithfully,</p>
            <p className="mb-5">
              For: <strong>Trade Lenda</strong>
            </p>
            <h4>______________________</h4>
            <h6>Adeshina Adewunmi</h6>
          </div>
          <div className="my-5">
            <h5 className="text-center mb-3">MEMORANDUM OF ACCEPTANCE</h5>
            <p>
              The common seal of ……………………………………………… Is hereunto affixed in
              acceptance of the terms and conditions of this offer in the
              presence of:
            </p>
          </div>
          <div className="d-flex justify-content-between text-center signature">
            <div
              className="dir"
              style={{
                // background: `url(${BaseDirectories.IMAGES_DIR}/Adeshina Signature-01.png) no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center -100px',
                height: '70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                lineHeight: '0px',
              }}
            >
              <p
                className=""
                style={{
                  lineHeight: '0px',
                }}
              >
                <span className="pt-4 pb-0">{`${loanDetails?.firstName} ${loanDetails?.lastName}`}</span>

                <h4>______________________</h4>
              </p>
              <h5>DIRECTOR</h5>
            </div>
            <div
              className="sec"
              style={{
                // background: `url(${BaseDirectories.IMAGES_DIR}/Tomi\ Signature-01.png) no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center -104px',
                height: '70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <p
                className=""
                style={{
                  lineHeight: '0px',
                }}
              >
                <span className="pt-4 pb-0">{`${
                  userGuarantor?.firstName
                    ? userGuarantor?.firstName
                    : loanDetails?.firstName
                } ${
                  userGuarantor?.lastName
                    ? userGuarantor?.lastName
                    : loanDetails?.lastName
                }`}</span>
                <h4>______________________</h4>
                <h5>DIRECTOR/GUARANTOR</h5>
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center text-center mt-4">
          <div className="col-lg-8 col-md-8">
            <div className="profile-btn d-flex gap-3 align-items-center justify-content-center">
              {loanDetails?.approvalStatus === 'approved' &&
                // !toJS(loansStore.loanId).acceptLoanOffer &&
                // authorized &&
                !toJS(loansStore.loanId).acceptLoanOffer && (
                  <Button
                    disabled={toJS(loansStore.loanId).acceptLoanOffer}
                    classes="primary-btn btn-lg px-4"
                    content={
                      loanUserdetails?.organizationDetails?.shariaCom
                        ? 'Accept Facility'
                        : 'Accept Loan'
                    }
                    onClick={handleAcceptLoan}
                  />
                )}
              <ReactToPrint
                trigger={() => (
                  <Button
                    classes="secondary-btn btn-lg px-4 cancel"
                    content="Print"
                  />
                )}
                content={() => componentRef.current}
              />
              {(loanState?.acceptLoanOffer ||
                loanState?.acceptLoanOfferDate?.length > 0 ||
                toJS(loansStore.loanId).acceptLoanOffer) && (
                <Button
                  classes="btn-success btn-lg px-4"
                  disabled={true}
                  content="Accepted"
                />
              )}
              {/* {!authorized && (
                <>
                  <Button
                    disabled={!loanDetails?._id?.length}
                    classes="primary-btn btn-lg px-4"
                    content="Connect Bank"
                    onClick={handleConnectBank}
                  />
                  <div className="info">
                    <span>i</span>
                    <p>
                      You will be charged NGN1 to connect your bank. This is to
                      confirm the validity of the account information provided.
                      You will be refunded as soon as this is confirmed.
                    </p>
                  </div>
                </>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(OfferLetter);
