/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../components/ui/Button';
import { Link, useNavigate } from 'react-router-dom';
import BaseDirectories from '../../../base directories/BaseDirectories';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { observer } from 'mobx-react-lite';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import Spinner from '../../../components/ui/Spinner';
import { toJS } from 'mobx';
import moment from 'moment';

const loanReason = {
  purchaseInventory: 'Purchase Inventory',
  paySuppliers: 'Pay Suppliers',
  generalWorkingCapital: 'General Working Capital',
  makeASupply: 'Make a Supply',
  discountAnInvoice: 'Discount an Invoice',
  other: 'Other',
};

const loanType = [
  { key: 'Local Purchase Order', value: 'Local Purchase Order' },
  { key: 'Working Capital', value: 'Working Capital' },
  { key: 'Asset Financing', value: 'Asset Financing' },
];

const facilityType = [
  { key: 'MURABAHA ( Cost-Plus)', value: 'Murabaha' },
  { key: 'MUSHARAKA ( Partnership)', value: 'Musharakah' },
];

const murabahaType = [
  { key: 'Inventory Financing', value: 'Inventory Financing' },
  { key: 'Asset Financing', value: 'Asset Financing' },
];

const musharakaType = [{ key: 'Purchase Order', value: 'Purchase Order' }];

let loanTenorType = [
  { key: 'days30', value: '30 Days' },
  { key: 'days45', value: '45 Days' },
  { key: 'days60', value: '60 Days' },
  { key: 'days90', value: '90 Days' },
  { key: 'days180', value: '180 Days' },
];

let textValue: any = '';
const RequestForLoan = () => {
  const { loansStore, guarantorStore } = useContext(StoreContext);
  const {
    sending,
    success,
    loanUserdetails,
    loanTenorDetails,
    loading,
    loanTenor,
  } = loansStore;
  const { guarantors } = guarantorStore;
  const navigate = useNavigate();
  const [loanDetails, setLoanDetails] = useState({
    email: '',
    reason: '',
    loanType: '',
    amount: 0,
    loanTenor: '',
    note: '',
  });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    loansStore.getLoanTenor();
  }, []);

  useEffect(() => {
    if (toJS(loanTenor) && toJS(loanTenor).length > 0) {
      const restructuredLoanTenor = toJS(loanTenor).map((item: any) => {
        return { key: item.name, value: item.name };
      });
      loanTenorType = restructuredLoanTenor;
    }
  }, [loanTenor]);

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setIsVisible(false);
    setLoanDetails((prevDetails) => {
      if (element.id === 'numbersOnly') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });
  };

  const handleLoanSummary = () => {
    const param = {
      amount: loanDetails.amount,
      tenor: loanDetails.loanTenor,
      type: loanDetails.loanType,
    };
    loansStore.getLoanDetails(param);
    setIsVisible(true);
  };

  function formatDateArray(dates: any) {
    let formattedDates: any;
    if (dates.length > 1) {
      formattedDates = dates?.map((dateString: any) => {
        const date = new Date(dateString);

        return ' ' + moment(date).format('YYYY-MM-DD');
      });
    } else {
      formattedDates = moment(dates).format('YYYY-MM-DD');
    }

    return formattedDates;
  }

  function formatDate(dates: any) {
    const date = new Date(dates);
    return moment(date).format('YYYY-MM-DD');
  }

  const formatObjectToString = (obj: any) => {
    return Object.entries(obj)
      .map(
        ([key, value]) =>
          `${key}: ${
            key == 'Amount' ||
            key == 'Total Payback Amount' ||
            key == 'Monthly Payback Amount'
              ? `₦ ${Number(value).toLocaleString()}`
              : key == 'Payback Schedule'
              ? formatDateArray(value)
              : key == 'Final Payback Date'
              ? formatDate(value)
              : value
          }`,
      )
      .join('\n');
  };

  textValue = formatObjectToString(toJS(loanTenorDetails));
  useEffect(() => {
    textValue = formatObjectToString(toJS(loanTenorDetails));
  }, [loading === false]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function validate(evt: any) {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const handleCreateLoan = () => {
    if (toJS(guarantors).length > 0) {
      loansStore.createLoan(loanDetails);
    } else {
      return toast.error(
        (t) => (
          <span className="d-flex">
            <span>
              You need to add guarantors to request for a{' '}
              {loanUserdetails?.organizationDetails?.shariaCom
                ? 'facility'
                : 'Loan'}
              . Click{' '}
              <Link
                to="/dashboard/guarantors/add_guarantor"
                style={{ color: '#054b99', fontWeight: 'bold' }}
              >
                here
              </Link>{' '}
              to add guarantors.
            </span>
            <button
              style={{ width: '50px' }}
              onClick={() => toast.dismiss(t.id)}
            >
              x
            </button>
          </span>
        ),
        {
          duration: 6000,
        },
      );
    }
  };

  React.useEffect(() => {
    if (success === "Request successful. We'll get back to you soon ") {
      setTimeout(() => {
        loanUserdetails?.organizationDetails?.shariaCom
          ? navigate('/dashboard/facility')
          : navigate('/dashboard/loans');
      }, 4000);
    }
  }, [navigate, success]);

  React.useEffect(() => {
    guarantorStore.getGuarantors();
  }, []);

  return (
    <div className="loans">
      <div className="section-head">
        {loanUserdetails?.organizationDetails?.shariaCom ? 'Facility' : 'Loans'}
      </div>
      <Toaster
        position="top-center"
        reverseOrder={true}
        containerStyle={{
          top: 50,
        }}
        toastOptions={{
          duration: 4000,
          style: {
            gap: '10px',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== 'loading' && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{ width: '50px', background: 'white' }}
                  >
                    {icon}
                  </button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <div className="loans-container">
        <Link
          to={
            loanUserdetails?.organizationDetails?.shariaCom
              ? '/dashboard/facility'
              : '/dashboard/loans'
          }
        >
          <h4 className="h3-back">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              style={{ marginRight: '1rem' }}
            />
            Back
          </h4>
        </Link>

        <div className="rq-loan-container">
          <div className="box-1">
            <h4>
              Request new{' '}
              {loanUserdetails?.organizationDetails?.shariaCom
                ? 'facility'
                : 'loan'}
            </h4>
            <p>
              Fill the form below to request for a{' '}
              {loanUserdetails?.organizationDetails?.shariaCom
                ? 'facility'
                : 'loan'}
              . Read on our{' '}
              {loanUserdetails?.organizationDetails?.shariaCom
                ? 'facility'
                : 'loan'}{' '}
              policy
              <span>
                <a
                  href="https://docs.google.com/document/d/15S7fqJRcn1RZSxICx83DHuNxHtblAO2R-E1vTwVl59w/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp;here.
                </a>
              </span>
            </p>
            {toJS(loanUserdetails)?.organizationDetails?.shariaCom && (
              <p>
                {loanUserdetails?.organizationDetails?.shariaCom
                  ? 'Facility'
                  : 'Loan'}{' '}
                amount will be paid to our verified merchant, to provide your
                goods / services needed.
              </p>
            )}

            {sending && <Spinner />}
            <form action="" onSubmit={handleCreateLoan}>
              <label htmlFor="email">
                Email Address
                <span style={{ color: 'red' }}> *</span>
              </label>
              <input
                type="email"
                placeholder="Email Address"
                value={loanDetails.email}
                name="email"
                id="email"
                onChange={handleInputChange}
              />

              <label htmlFor="amount">
                How much do you need?
                <span style={{ color: 'red' }}> *</span>
              </label>
              <input
                type="text"
                name="amount"
                id="numbersOnly"
                onChange={handleInputChange}
                onKeyPress={validate}
              />

              <label htmlFor="Loan type">
                {loanUserdetails?.organizationDetails?.shariaCom
                  ? 'Facility'
                  : 'Loan'}{' '}
                type
                <span style={{ color: 'red' }}> *</span>
              </label>
              <select
                name="loanType"
                id="loanType"
                onChange={handleInputChange}
              >
                <option value="">
                  Select{' '}
                  {loanUserdetails?.organizationDetails?.shariaCom
                    ? 'Facility'
                    : 'Loan'}{' '}
                  Type
                </option>
                {toJS(loanUserdetails)?.organizationDetails?.shariaCom
                  ? facilityType.map((item: any, index: number) => (
                      <option key={index} value={item.value}>
                        {item.key}
                      </option>
                    ))
                  : loanType.map((item: any, index: number) => (
                      <option key={index} value={item.value}>
                        {item.key}
                      </option>
                    ))}
              </select>

              <label htmlFor="Loan Duration">
                {loanUserdetails?.organizationDetails?.shariaCom
                  ? 'Facility'
                  : 'Loan'}{' '}
                Duration
                <span style={{ color: 'red' }}> *</span>
              </label>
              <select
                name="loanTenor"
                id="loanTenor"
                onChange={handleInputChange}
              >
                <option value="">Select Duration</option>
                {loanTenorType.map((tenor: any, index: number) => (
                  <option key={index} value={tenor.value}>
                    {tenor.value}
                  </option>
                ))}
              </select>

              <label htmlFor="reason">
                What do you need it for?
                <span style={{ color: 'red' }}> *</span>
              </label>
              <select
                name="reason"
                id="reason"
                onChange={(text: any) => {
                  handleInputChange(text);
                }}
              >
                <option value="">Select Reason</option>
                {toJS(loanUserdetails)?.organizationDetails?.shariaCom ? (
                  <>
                    {loanDetails?.loanType === 'Murabaha' && (
                      <>
                        {murabahaType.map((item: any, index: number) => (
                          <option key={index} value={item.value}>
                            {item.key}
                          </option>
                        ))}
                      </>
                    )}
                    {loanDetails.loanType === 'Musharakah' && (
                      <>
                        {musharakaType.map((item: any, index: number) => (
                          <option key={index} value={item.value}>
                            {item.key}
                          </option>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <option value="">Select Reason</option>
                    <option value={loanReason.discountAnInvoice}>
                      {loanReason.discountAnInvoice}
                    </option>
                    <option value={loanReason.generalWorkingCapital}>
                      {loanReason.generalWorkingCapital}
                    </option>
                    <option value={loanReason.makeASupply}>
                      {loanReason.makeASupply}
                    </option>
                    <option value={loanReason.paySuppliers}>
                      {loanReason.paySuppliers}
                    </option>
                    <option value={loanReason.purchaseInventory}>
                      {loanReason.purchaseInventory}
                    </option>
                    <option value={loanReason.other}>{loanReason.other}</option>
                  </>
                )}
              </select>

              <label htmlFor="purpose">Reason </label>
              <textarea
                name="note"
                value={loanDetails.note}
                style={{ minHeight: '125px' }}
                onChange={(text: any) => {
                  handleInputChange(text);
                  // handleLoanSummary();
                }}
              ></textarea>

              {toJS(loanTenorDetails) &&
                loanDetails.amount !== 0 &&
                loanDetails.loanTenor !== '' &&
                loanDetails.loanType !== '' &&
                loanDetails.reason !== '' &&
                loanDetails.note !== '' &&
                toJS(loanTenorDetails).length !== 0 &&
                isVisible &&
                loading === false && (
                  <>
                    <label htmlFor="summary">Summary </label>
                    <textarea
                      name="summary"
                      readOnly
                      value={textValue}
                      style={{ minHeight: '250px' }}
                    ></textarea>
                  </>
                )}
            </form>

            <div className="d-flex gap-2">
              <Button
                content="Summary"
                title="Summary"
                classes="primary-btn btn-lg w-100"
                disabled={isVisible}
                onClick={() => {
                  if (loanDetails.note !== '') {
                    handleLoanSummary();
                  } else {
                    setIsVisible(false);
                    toast.error('Reason for loan is required.');
                  }
                }}
              />
              <Button
                content="Submit"
                title="Submit"
                classes="primary-btn btn-lg w-100"
                type="submit"
                disabled={!isVisible || loanDetails.note == ''}
                onClick={handleCreateLoan}
              />
            </div>
          </div>

          <div className="box-2">
            {loanUserdetails?.organizationDetails ? (
              loanUserdetails?.organizationDetails?.shariaCom ? (
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/Business_Man_01.png`}
                  alt="Trade Lenda"
                  style={{
                    width: '70%',
                  }}
                  className="box2-img"
                />
              ) : (
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/okyy.png`}
                  alt="Trade Lenda"
                  className="box2-img"
                />
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(RequestForLoan);
