/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Outlet, useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase/Firebase';

const ProtectedRoute = () => {
  const location = useLocation();
  const isAuthenticated = auth.currentUser;

  return isAuthenticated !== null ? (
    <Navigate to="/" state={{ from: location }} />
  ) : (
    <Outlet />
  );
  //   );
};

export default ProtectedRoute;
