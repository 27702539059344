/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Button from '../../../../components/ui/Button';
import data from '../data.json';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { StoreContext } from '../../../../ mobx stores/RootStore';
import { selectCurrentLoanDetails } from '../../../../redux/loanDetail/loan.details.selector';
import { observer } from 'mobx-react-lite';

type Props = {
  next: () => void;
  x: number;
  setX: any;
  previous: any;
};

const PersonalDetails: FC<Props> = ({ next, x, setX, previous }) => {
  const { loansStore } = React.useContext(StoreContext);
  const { profileStore } = useContext(StoreContext);
  const { submitting } = profileStore;
  const currentLoanDetails = useSelector(selectCurrentLoanDetails);
  const { loanDocumentDetails } = currentLoanDetails;
  const { sending, success, loanUserdetails } = loansStore;

  const [armDetails, setArmDetails] = useState<any>({
    annualExpectedAnnualIncomeRange:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.annualExpectedAnnualIncomeRange
        ? loanUserdetails?.armUserBankDetails?.annualExpectedAnnualIncomeRange
        : '',
    politicallyExposedPersons:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.politicallyExposedPersons
        ? loanUserdetails?.armUserBankDetails?.politicallyExposedPersons
        : '',
    politicallyExposedPersonsCategory: '',
    employmentStatus:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.employmentStatus
        ? loanUserdetails?.armUserBankDetails?.employmentStatus
        : '',
    reInvestDividends:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.reInvestDividends
        ? loanUserdetails?.armUserBankDetails?.reInvestDividends
        : 'Yes',
    kycLevel:
      loanUserdetails?.armUserBankDetails &&
      loanUserdetails?.armUserBankDetails?.kycLevel
        ? loanUserdetails?.armUserBankDetails?.kycLevel
        : '',
  });

  const handleInputChange = (e: React.FormEvent) => {
    e.preventDefault();

    const element = e.target as HTMLInputElement;
    setArmDetails((prevDetails: any) => {
      if (element.type === 'number') {
        return { ...prevDetails, [element.name]: parseInt(element.value) };
      } else {
        return { ...prevDetails, [element.name]: element.value };
      }
    });
  };

  const handleUpdateArmdetails = (e: React.FormEvent) => {
    e.preventDefault();
    if (loanDocumentDetails == undefined || loanDocumentDetails == null) {
      loansStore.createArmDocumentsDetails(armDetails);
    } else {
      // loansStore.updateArmDetails(armDetails);
      loansStore.createArmDocumentsDetails(armDetails);
    }
  };

  useEffect(() => {
    if (success === 'ARM details created successfully') {
      next();
      setX(1000);
    }
    if (success === 'Profile updated successfully') {
      next();
      setX(1000);
    }
  }, [next, setX, success]);

  return (
    <motion.div
      initial={{ x: x }}
      transition={{ duration: 0.5 }}
      animate={{ x: 0 }}
      className=""
    >
      {/* {submitting ? <Spinner /> : null} */}
      <div className="update-arm-form">
        <h4>PERSONAL DETAILS</h4>
        <p>
          Industry regulation requires us to collect this information to begin
          investment.
        </p>
        {armDetails.kycLevel !== '' ? (
          <div className="col-md-12">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                background: 'aliceblue',
                padding: '8px 10px',
                borderRadius: '10px',
              }}
            >
              <div>
                <p className="mb-0" style={{ fontSize: '12px' }}>
                  <Icon path={mdiInformationOutline} size={1} color="#676767" />
                  <strong>{armDetails.kycLevel} Investment Conditions</strong>
                </p>
                <p className="mb-0" style={{ fontSize: '12px' }}>
                  maximumSingleInvestmentAmount:{' '}
                  {data.kycLevel.map((item, key) => {
                    if (item.level === armDetails.kycLevel) {
                      return (
                        <strong key={key}>
                          {item.maximumSingleInvestmentAmount}
                        </strong>
                      );
                    }
                  })}{' '}
                  | maximumSingleRedemptionAmount:{' '}
                  {data.kycLevel.map((item, key) => {
                    if (item.level === armDetails.kycLevel) {
                      return (
                        <strong key={key}>
                          {item.maximumSingleRedemptionAmount}
                        </strong>
                      );
                    }
                  })}{' '}
                  | maximumCumulativeInvestmentAmount:{' '}
                  {data.kycLevel.map((item, key) => {
                    if (item.level === armDetails.kycLevel) {
                      return (
                        <strong key={key}>
                          {item.maximumCumulativeInvestmentAmount}
                        </strong>
                      );
                    }
                  })}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <form action="" onSubmit={handleUpdateArmdetails}>
          <div className="row">
            <div className="col-md-6 left-side pe-md-3 pe-lg-4">
              <div className="col-md-12 input-box">
                <label htmlFor="kycLevel" className="mb-1 mt-3">
                  KYC Level
                </label>
                <span style={{ color: 'red' }}> *</span>

                <select
                  name="kycLevel"
                  className="form-control"
                  id="kycLevel"
                  onChange={handleInputChange}
                  required={true}
                  disabled={submitting || sending ? true : false}
                  defaultValue={armDetails.kycLevel}
                >
                  <option value="">Select option</option>
                  {data.kycLevel.map((item, key) => (
                    <option value={item.level} key={key}>
                      {item.level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12 input-box">
                <label htmlFor="employmentStatus" className="mb-1 mt-3">
                  Employment Status
                </label>
                <span style={{ color: 'red' }}> *</span>
                <select
                  name="employmentStatus"
                  className="form-control"
                  id="employmentStatus"
                  onChange={handleInputChange}
                  required
                  disabled={submitting || sending ? true : false}
                  defaultValue={armDetails.employmentStatus}
                >
                  <option value="">Select employment status</option>
                  {data.employmentStatuses.map((item, key) => (
                    <option value={item} key={key}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12 input-box">
                <label
                  htmlFor="annualExpectedAnnualIncomeRange"
                  className="mb-1 mt-3"
                >
                  Annual Expected Income Range
                </label>
                <span style={{ color: 'red' }}> *</span>
                <select
                  name="annualExpectedAnnualIncomeRange"
                  className="form-control"
                  id="annualExpectedAnnualIncomeRange"
                  onChange={handleInputChange}
                  required
                  disabled={submitting || sending ? true : false}
                  defaultValue={armDetails.annualExpectedAnnualIncomeRange}
                >
                  <option value="">Select option</option>
                  {data.incomeRanges.map((item, key) => (
                    <option value={item} key={key}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6 left-side pe-md-3 pe-lg-4">
              <div className="col-md-12 input-box">
                <label
                  htmlFor="politicallyExposedPersons"
                  className="mb-1 mt-3"
                >
                  Politically Exposed Person?
                </label>
                <span style={{ color: 'red' }}> *</span>
                <select
                  name="politicallyExposedPersons"
                  className="form-control"
                  id="politicallyExposedPersons"
                  onChange={handleInputChange}
                  required
                  disabled={submitting || sending ? true : false}
                  defaultValue={armDetails.politicallyExposedPersons}
                >
                  <option value="">Select option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              {armDetails.politicallyExposedPersons !== 'no' && (
                <div className="col-md-12 input-box">
                  <label
                    htmlFor="politicallyExposedPersonsCategory"
                    className="mb-1 mt-3"
                  >
                    Politically Exposed Persons Category
                  </label>
                  <span style={{ color: 'red' }}> *</span>
                  <select
                    name="politicallyExposedPersonsCategory"
                    className="form-control"
                    id="politicallyExposedPersonsCategory"
                    onChange={handleInputChange}
                    required
                    disabled={submitting || sending ? true : false}
                    defaultValue={armDetails.politicallyExposedPersonsCategory}
                  >
                    <option value="">Select option</option>
                    {data.politicallyExposedPersonsCategory.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                    <option value="Diplomats">Others</option>
                  </select>
                </div>
              )}
            </div>
          </div>

          <div className="profile-btn" style={{ width: '100%' }}>
            <div
              className="profile-btn d-md-flex flex-wrap flex-md-nowrap"
              style={{ width: '100%' }}
            >
              <Button
                classes="primary-btn"
                content={'Previous'}
                type="submit"
                onClick={() => {
                  previous(1000);
                }}
                disabled={submitting || sending ? true : false}
              />
              <Button
                classes="primary-btn"
                content={
                  submitting || sending ? 'Submitting...' : 'Save and Continue'
                }
                type="submit"
                onClick={() => {
                  setX(1000);
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default observer(PersonalDetails);
