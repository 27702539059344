/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { toJS } from 'mobx';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import InputPinModal from '../InputPinModal';
import Button from '../../../../components/ui/Button';
import Input from '../../../../components/ui/Input';
import { toast } from 'react-toastify';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { StoreContext } from '../../../../mobx_stores/RootStore';
import { observer } from 'mobx-react-lite';

const defaultData = [
  {
    servieType: '9mobile',
    shortName: 'nine-mobile-airtime',
    name: '9Mobile Airtime',
    billerId: '1',
    productId: '1',
    plans: ['prepaid'],
  },
  {
    servieType: 'mtn',
    shortName: 'mtn-airtime',
    name: 'MTN Nigeria Airtime',
    billerId: '2',
    productId: '2',
    plans: ['prepaid'],
  },
  {
    servieType: 'airtel',
    shortName: 'airtel-airtime',
    name: 'Airtel Nigeria Airtime',
    billerId: '3',
    productId: '3',
    plans: ['prepaid'],
  },
  {
    servieType: 'glo',
    shortName: 'glo-airtime',
    name: 'GLO Nigeria Airtime',
    billerId: '4',
    productId: '4',
    plans: ['prepaid'],
  },
  {
    servieType: 'internatinal-airtime',
    shortName: 'international-airtime',
    name: 'Internatinal Airtime',
    billerId: '5',
    productId: '5',
    plans: ['prepaid'],
  },
  {
    servieType: 'smile',
    shortName: 'smile-airtime',
    name: 'Smile Telecommunication Airtime',
    billerId: '6',
    productId: '6',
    plans: ['prepaid'],
  },
];

const AirtimePurchase = ({ setOpenAirtimePurchaseModal }) => {
  const [showBankSelection, setShowBankSelection] = useState(true);
  const [showAmountInput, setShowAmountInput] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({
    fromWalletIdAccountNumber: '',
    serviceType: '',
    amount: '',
    msisdn: '',
    transactionPin: '',
    operator: '',
    id: '',
  });
  const { billStore, walletStore } = useContext(StoreContext);
  const { submitting, message } = billStore;
  const { multipleWallet } = walletStore;

  const [showTransactionStatus, setShowTransactionStatus] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [showPinModal, setShowPinModal] = useState(false);
  const [pin, setPin] = useState('');
  const [pinToggle, setPinToggle] = useState(false);
  const [serviceTypes, setServiceTypes] = useState<any>();

  useEffect(() => {
    billStore.getNetworkProvider();
  }, [billStore]);

  useEffect(() => {
    if (billStore.networkProviders) {
      const providerNames = Object.values(billStore.networkProviders)
        .filter((provider) => typeof provider === 'object' && provider !== null)
        // .map((provider: any) => provider.name);
        .map((provider: any) => ({
          id: provider.id,
          name: provider.name,
        }));
      setServiceTypes(providerNames);
    }
  }, [billStore.networkProviders]);

  const handleInputChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    const val = element.value;

    setTransactionDetails((prevDetails: any) => {
      const updatedDetails = { ...prevDetails, [element.name]: val };

      if (element.name === 'serviceType') {
        // Find the corresponding operator ID based on selected serviceType
        const selectedService = serviceTypes.find(
          (service: any) => service.name === val,
        );
        if (selectedService) {
          updatedDetails.id = selectedService.id;
          updatedDetails.operator = Number(selectedService.id);
          // Fetch data plans for the selected operator
          billStore.getAirtimePlansByProvider(selectedService.id);
        }
      }

      return updatedDetails;
    });
  };

  useEffect(() => {
    setTransactionDetails((prevDetails: any) => {
      return { ...prevDetails, transactionPin: pin };
    });
  }, [pin]);

  const handlePinToggle = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    setPinToggle(element.checked);
  };

  const validateAmount = (evt: any) => {
    const theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    // Allow numbers, decimal point, and backspace
    const regex = /[0-9\.]|Backspace/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const handleTransaction = (e: any) => {
    e.preventDefault();
    if (transactionDetails?.msisdn !== undefined) {
      if (isValidPhoneNumber(transactionDetails?.msisdn)) {
        const { serviceType, amount, msisdn, transactionPin } =
          transactionDetails;
        if (
          serviceType === '' ||
          amount === '' ||
          msisdn === '' ||
          transactionPin === ''
        ) {
          toast.error('All input fields are required!');
          return;
        }
        if (toJS(multipleWallet) && toJS(multipleWallet).length > 0) {
          toJS(multipleWallet).forEach((walletData: any) => {
            if (
              walletData.walletIdAccountNumber ===
              transactionDetails?.fromWalletIdAccountNumber
            ) {
              if (
                Number(walletData.availableBalance) <
                Number(transactionDetails.amount)
                // Number(walletData.availableBalance) ===
                // Number(transactionDetails.amount)
              ) {
                toast.error(
                  'You don’t have enough balance to process this transaction!',
                );
                return;
              } else {
                billStore.purchaseAirtime(transactionDetails);
              }
            }
          });
        }
      } else {
        toast.error('Invalid phone number, check and try again.');
        return;
      }
    }
  };

  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  useEffect(() => {
    if (message.type === 'success') {
      setTransactionStatus(message.msg);
      setShowTransactionStatus(true);
      setTimeout(() => {
        setOpenAirtimePurchaseModal(false);
        walletStore.getAllTransactions();
      }, 6000);
    } else {
      setShowTransactionStatus(false);
    }
  }, [message.msg, message.type]);

  return (
    <div className="bill-modal">
      <div className="bg-white modal-box">
        <span
          className="close"
          onClick={() => {
            setOpenAirtimePurchaseModal(false);
          }}
        >
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="Close"
          />
        </span>

        <h4 className="mb-2">Buy Airtime</h4>
        <hr
          className="bg-secondary"
          style={{ borderWidth: '2px', borderColor: 'gray' }}
        />
        {!showAmountInput && !showBankSelection && (
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -200, opacity: 0 }}
            className="sliding-input"
          >
            <div className="mb-3 amount text-center">
              <h6>₦{transactionDetails.amount.toLocaleString()}.00</h6>
            </div>
          </motion.div>
        )}
        {!showTransactionStatus ? (
          <form className="bill-payment-form" onSubmit={handleTransaction}>
            <div className="row justify-content-center">
              {showPinModal ? (
                <InputPinModal
                  setDetails={setTransactionDetails}
                  setShowPinModal={setShowPinModal}
                />
              ) : !showAmountInput && !showBankSelection ? (
                <>
                  <div className="row justify-content-center account-box">
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input"
                    >
                      <div className="col-12 styled-phone-input">
                        <label
                          htmlFor="phone"
                          className="mb-1 mt-2 text-start d-block"
                        >
                          Mobile Number
                        </label>
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="NG"
                          onChange={(e: string) =>
                            setTransactionDetails((prevDetails: any) => {
                              return { ...prevDetails, msisdn: e };
                            })
                          }
                          className="form-control p-3"
                          id="phone"
                          disabled={submitting ? true : false}
                          value={transactionDetails.msisdn}
                          rules={{ required: true }}
                          autoComplete="off"
                        />
                      </div>
                    </motion.div>
                    {transactionDetails.msisdn !== undefined &&
                      isValidPhoneNumber(transactionDetails?.msisdn) && (
                        <motion.div
                          initial={{ x: -200, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          exit={{ x: -200, opacity: 0 }}
                          className="sliding-input"
                        >
                          <div className="col-12">
                            <label htmlFor="serviceType" className="mb-1 mt-3">
                              Service Provider
                            </label>
                            <select
                              name="serviceType"
                              id="serviceType"
                              className="form-control p-3"
                              onChange={handleInputChange}
                              defaultValue={transactionDetails.serviceType}
                              required
                              disabled={submitting ? true : false}
                            >
                              <option value="">Select network</option>
                              {serviceTypes && serviceTypes.length > 0
                                ? serviceTypes.map(
                                    (serviceType: any, index: number) => (
                                      <option
                                        key={index}
                                        value={serviceType.name}
                                      >
                                        {serviceType.name}
                                      </option>
                                    ),
                                  )
                                : defaultData.map(
                                    (serviceType: any, index: number) => (
                                      <option
                                        key={index}
                                        value={serviceType.serviceType}
                                      >
                                        {serviceType.serviceType}
                                      </option>
                                    ),
                                  )}
                            </select>
                          </div>
                        </motion.div>
                      )}

                    {transactionDetails.serviceType !== '' && (
                      <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -200, opacity: 0 }}
                        className="sliding-input"
                      >
                        <div className="col-12 input-box">
                          <label htmlFor="showPin" className="mb-1 mt-3">
                            Transaction Pin
                          </label>
                          <div className="">
                            <OtpInput
                              value={pin}
                              onChange={setPin}
                              numInputs={4}
                              inputStyle="pin-style"
                              containerStyle="pin-container-style"
                              inputType={pinToggle ? 'tel' : 'password'}
                              shouldAutoFocus={true}
                              renderInput={(props) => (
                                <input
                                  onKeyPress={validateNumber}
                                  {...props}
                                  disabled={submitting ? true : false}
                                />
                              )}
                            />
                          </div>
                          <div className="d-flex gap-2 justify-content-start align-items-center mb-3">
                            <input
                              type="checkbox"
                              onChange={handlePinToggle}
                              name="showPin"
                              id="show-pin"
                            />
                            <label htmlFor="show-pin">Show pin</label>
                          </div>
                          <p style={{ fontSize: '14px', textAlign: 'left' }}>
                            Don't have a pin yet? Create one{' '}
                            <Link
                              style={{ color: 'blue' }}
                              to="/dashboard/settings/create-pin"
                            >
                              here
                            </Link>{' '}
                          </p>
                        </div>
                      </motion.div>
                    )}
                  </div>

                  <div className="row justify-content-start mt-2">
                    <div className="col-11">
                      <div className="group-btn justify-content-start">
                        <Button
                          classes="secondary-btn btn-md"
                          content="Back"
                          onClick={() => setShowAmountInput(true)}
                          type="button"
                          disabled={submitting}
                        />
                        {transactionDetails.transactionPin !== '' &&
                          transactionDetails.transactionPin.length === 4 && (
                            <motion.div
                              initial={{ x: -200, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              exit={{ x: -200, opacity: 0 }}
                              className="sliding-input"
                            >
                              <Button
                                classes="primary-btn btn-md"
                                content={`${
                                  submitting ? 'Processing...' : 'Proceed'
                                }`}
                                type="submit"
                                disabled={submitting}
                              />
                            </motion.div>
                          )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="row justify-content-center amount-box">
                  {/* // BANK SELECTION */}
                  {showBankSelection && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input"
                    >
                      <div className="row justify-content-center banks-box">
                        <p className="mb-4 color-heading">Select a wallet</p>
                        <div className="col-12">
                          {toJS(multipleWallet) &&
                          toJS(multipleWallet)?.length > 0
                            ? toJS(multipleWallet)?.map(
                                (walletData: any, index: number) => (
                                  <div className="w-100 gap-2 mt-2" key={index}>
                                    <h6 className="wallet-deets d-flex align-items-start my-3 color-heading">
                                      Wallet {index + 1}
                                    </h6>
                                    <div
                                      className="d-flex flex-wrap gap-20 align-items-center justify-content-between mb-3 wallet-deets bank-selection"
                                      onClick={() => {
                                        if (
                                          walletData?.availableBalance < 100
                                          // walletData?.availableBalance === 100
                                        ) {
                                          toast.error(
                                            'Amount must be greater than ₦100',
                                          );
                                        } else {
                                          setTransactionDetails(
                                            (prevDetails) => {
                                              return {
                                                ...prevDetails,
                                                fromWalletIdAccountNumber:
                                                  walletData?.walletIdAccountNumber,
                                              };
                                            },
                                          );
                                          setShowBankSelection(false);
                                          setShowAmountInput(true);
                                        }
                                      }}
                                    >
                                      <h6>{walletData?.banker}</h6>
                                      <p>
                                        ₦{' '}
                                        {walletData?.availableBalance !=
                                          'NaN' ||
                                        walletData?.availableBalance != null ||
                                        walletData?.availableBalance !=
                                          undefined
                                          ? walletData?.availableBalance
                                            ? new Intl.NumberFormat('en-US', {
                                                style: 'decimal',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }).format(
                                                walletData?.availableBalance,
                                              )
                                            : '0.00'
                                          : '0.00'}
                                      </p>
                                    </div>
                                  </div>
                                ),
                              )
                            : null}
                        </div>
                      </div>
                    </motion.div>
                  )}
                  {!showBankSelection && (
                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -200, opacity: 0 }}
                      className="sliding-input"
                    >
                      <div className="col-12">
                        <label
                          htmlFor="amount"
                          className="mb-1 text-start d-block"
                        >
                          Amount
                        </label>
                        <Input
                          onKeyPress={validateAmount}
                          onChange={handleInputChange}
                          name="amount"
                          classes="form-control mb-3 p-3"
                          type="number"
                          id="amount"
                          value={transactionDetails.amount}
                        />
                      </div>
                      <div className="col-10">
                        <Button
                          classes="primary-btn btn-md px-4 mx-2"
                          content="Back"
                          type="button"
                          onClick={() => {
                            setShowBankSelection(true);
                            setShowAmountInput(false);
                          }}
                        />
                        <Button
                          classes="primary-btn btn-md px-4 mx-2"
                          content="Next"
                          type="button"
                          onClick={() => {
                            // if (Number(transactionDetails.amount) < 50) {
                            if (!Number(transactionDetails.amount)) {
                              toast.error('Amount is below limit of ₦50');
                            } else {
                              transactionDetails.amount &&
                                setShowAmountInput(false);
                            }
                          }}
                        />
                      </div>
                    </motion.div>
                  )}
                </div>
              )}
            </div>
          </form>
        ) : (
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -200, opacity: 0 }}
            className="sliding-input"
          >
            <div>
              <h6
                className={
                  message.type === 'success'
                    ? ' alert alert-success mb-0 text-center m-3'
                    : 'alert alert-danger mb-0 text-center m-3'
                }
                role="alert"
              >
                {transactionStatus}
              </h6>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default observer(AirtimePurchase);
