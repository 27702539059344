/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { StoreContext } from '../../mobx_stores/RootStore';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import { auth } from '../../firebase/Firebase';
import Spinner from '../../components/ui/Spinner';
import { observer } from 'mobx-react-lite';

const VerifyAccount = () => {
  const navigate = useNavigate();
  const { authStore } = useContext(StoreContext);
  const { loading, message } = authStore;
  const location: any = useLocation();

  const handleVerifyEmail = () => {
    authStore.VerifyEmail(location?.state?.oobCode);
  };

  const handleSendVerificationEmail = () => {
    authStore.ResendVerificationEmail();
  };

  useEffect(() => {
    if (message.msg === 'Email verified successfully.') {
      navigate('/email_verification_successful');
    }
  }, [message.msg]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (auth.currentUser?.emailVerified) {
        navigate('/email_verification_successful');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        clearInterval(interval);
        return () => clearInterval(interval);
      }
      // if (!auth.currentUser) {
      //   navigate('/login');
      // }
      await auth.currentUser?.reload();
    }, 2000);
    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div>
      {loading && <Spinner />}

      <div className="verify ">
        {/* <div className="pattern-group blue">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/blueangle.png`}
            alt=""
            style={{ width: '10%', margin: '0', right: '0' }}
          />
        </div> */}
        <div className="logo ">
          <img
            src={`${BaseDirectories.LOGOS_DIR}/Logo2.png`}
            alt=""
            style={{ width: '15%' }}
          />
        </div>

        <div className="container d-flex justify-content-center align-items-center">
          <div className="py-3 px-2 w-100">
            <div className="verification-img">
              <img
                src={`${BaseDirectories.IMAGES_DIR}/verification2.png`}
                alt=""
                style={{ width: '20%' }}
              />
            </div>

            <h3 className="mt-3">Signup successful</h3>
            <p className="mobile-text">
              We've sent an email to complete <br />
              your registration
            </p>
            <hr />
            {/* {!auth.currentUser?.emailVerified && !location?.state?.oobCode && (
              <p className="p-4">
                Please go to your mail box to verify your account. If you do not
                see a verification mail in your inbox, kindly check your spam
                mail.
              </p>
            )} */}
            {!auth.currentUser?.emailVerified && location?.state?.oobCode && (
              <Button
                content="Verify and continue"
                classes="primary-btn btn-lg col-12 mb-5 mx-3"
                onClick={handleVerifyEmail}
              />
            )}
            <Button
              content="Resend verification email"
              classes="secondary-btn btn-lg col-12 mb-5 mx-3"
              onClick={handleSendVerificationEmail}
            />
          </div>
        </div>
        {/* <div className="pattern-group green">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/greenangle.png`}
            alt=""
            style={{ width: '10%' }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default observer(VerifyAccount);
