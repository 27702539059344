/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  confirmPasswordReset,
  applyActionCode,
} from 'firebase/auth';
import { configure, makeAutoObservable, runInAction } from 'mobx';
import { auth } from '../firebase/Firebase';
// import toast from 'react-hot-toast';
import { toast } from 'react-toastify';
import BaseDirectories from '../base directories/BaseDirectories';
import { BrowserLogger } from '../common/logger/Logger';

configure({ enforceActions: 'always' });

export class AuthStore {
  authenticated = false;
  loading = false;
  submitting = false;
  error = '';
  success = '';
  verified = false;
  token: any = sessionStorage.getItem('accessToken') || '';
  userId = '';
  user: any = auth.currentUser;
  message = {
    type: '',
    msg: '',
  };

  private logger!: BrowserLogger;
  // private email!: Profile;

  constructor() {
    makeAutoObservable(this);
    runInAction(() => {
      this.logger = new BrowserLogger(this.constructor.name);
    });
  }

  isAuthenticated() {
    return this.authenticated;
  }

  isVerified() {
    return this.isVerified;
  }

  async CreateUser(details: any) {
    this.setLoading(true);
    try {
      const res: any = await createUserWithEmailAndPassword(
        auth,
        details.email,
        details.password,
      );
      if (res.user) {
        this.logger.info(`User | Sign Up | ${details.email}`, res);
        updateProfile(res.user, {
          displayName: details.firstName + ' ' + details.lastName,
        });
      }
      const actionCodeSettings = {
        url: `${BaseDirectories.BASE_URL}/dashboard/overview/?email=${auth.currentUser?.email}`,
        handleCodeInApp: true,
      };
      await sendEmailVerification(res.user, actionCodeSettings)
        .then((res: any) => {
          this.logger.info(
            `User | Send verification email | ${details.email}`,
            res,
          );
          return;
        })
        .catch((error: any) => {
          this.logger.error(
            `User | Send verification email | ${details.email}`,
            error,
          );
          return;
        });
      this.setSuccess(res);
      runInAction(() => {
        this.loading = false;
      });
    } catch (error: any) {
      this.logger.error(`User | Sign Up | ${details.email}`, error);
      this.setError(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async ForgotPassword(details: any) {
    this.setLoading(true);
    try {
      const actionCodeSettings: any = {
        url: `${BaseDirectories.BASE_URL}/login/?email=${details?.email}`,
        handleCodeInApp: true,
      };
      const res: any = await sendPasswordResetEmail(
        auth,
        details.email,
        actionCodeSettings,
      );

      this.setSuccess(res);

      runInAction(() => {
        this.loading = false;
      });
    } catch (error: any) {
      this.setError(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async ResetPassword(newPassword: string, actionCode: any) {
    this.setLoading(true);
    try {
      await confirmPasswordReset(auth, actionCode, newPassword)
        .then(() => {
          toast.success(
            'Password set successfully. You can now login with your new password.',
          );
          this.setMessage(
            'success',
            'Password updated successfully. You can now login with your new password.',
          );
          return;
        })
        .catch((error) => {
          toast.error(this.mapAuthCodeToMessage(error.code));
          return;
        });

      runInAction(() => {
        this.loading = false;
      });
    } catch (error: any) {
      this.setError(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async VerifyEmail(actionCode: any) {
    this.setLoading(true);
    try {
      await applyActionCode(auth, actionCode)
        .then(() => {
          toast.success('Email verified successfully.');
          this.setMessage('success', 'Email verified successfully.');
          return;
        })

        .catch((error) => {
          toast.error(this.mapAuthCodeToMessage(error.code));
          this.setMessage('error', this.mapAuthCodeToMessage(error.code));
          return;
        });

      runInAction(() => {
        this.loading = false;
      });
    } catch (error: any) {
      this.setError(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async ResendVerificationEmail() {
    this.setLoading(true);
    try {
      const actionCodeSettings = {
        url: `${BaseDirectories.BASE_URL}/dashboard/overview/?email=${auth.currentUser?.email}`,
        handleCodeInApp: true,
      };
      await sendEmailVerification(this.user, actionCodeSettings)
        .then(() => {
          toast.success('Email sent.');
          this.setMessage('success', 'Email sent.');
          return;
        })
        .catch((error) => {
          toast.error(this.mapAuthCodeToMessage(error.code));
          this.setMessage('error', this.mapAuthCodeToMessage(error.code));
          return;
        });

      runInAction(() => {
        this.loading = false;
      });
    } catch (error: any) {
      this.setError(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async changePassword(
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
  ) {
    const credential = EmailAuthProvider.credential(
      this.user?.email,
      currentPassword,
    );

    if (
      newPassword.length === 0 ||
      currentPassword.length === 0 ||
      confirmPassword.length === 0
    ) {
      toast.error('One or more inputs are empty!');
      this.setMessage('error', 'One or more inputs are empty!');
    } else if (newPassword !== confirmPassword) {
      toast.error('New password and confirm password do not match!');
      this.setMessage(
        'error',
        'New password and confirm password do not match!',
      );
    } else {
      this.setSubmitting(true);
      // Re-authenticate user
      reauthenticateWithCredential(this.user, credential)
        .then(() => {
          updatePassword(this.user, newPassword)
            .then(() => {
              this.setSubmitting(false);
              toast.success('Password updated successfully.');
              this.setMessage('success', 'Password updated successfully.');
            })
            .catch((error) => {
              this.setSubmitting(false);
              toast.error(this.mapAuthCodeToMessage(error.code));
              this.setMessage('error', this.mapAuthCodeToMessage(error.code));
            });
        })
        .catch((error) => {
          this.setSubmitting(false);
          toast.error(this.mapAuthCodeToMessage(error.code));
          this.setMessage('error', this.mapAuthCodeToMessage(error.code));
        });
    }
  }

  mapAuthCodeToMessage = (authCode: string) => {
    switch (authCode) {
      case 'auth/invalid-password':
        return 'Current password provided is not correct.';

      case 'auth/wrong-password':
        return 'Current password provided is not correct.';

      case 'auth/too-many-requests':
        return 'Too many tries, please try again later.';

      default:
        return 'Something went wrong, please try again later.';
    }
  };

  setUser = (res: any) => {
    this.user = res;
  };

  setLoading = (val: boolean) => {
    this.loading = val;
  };

  setError = (err: string) => {
    this.error = err;
  };
  setSuccess = (res: string) => {
    this.success = res;
  };

  setMessage = (type: string, msg: string) => {
    this.message.type = type;
    this.message.msg = msg;
  };

  setSubmitting = (val: boolean) => {
    this.submitting = val;
  };

  SetAccessToken = (token: string) => {
    this.token = token || sessionStorage.getItem('accessToken');
  };

  setToLocalStorage = (key: string, value: any) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  };

  clearFromLocalStorage = () => {
    window.sessionStorage.clear();
  };

  setUserAuthenticated(res: any) {
    this.authenticated = true;
    this.token = res.access_token;
    this.success = 'Sign up successful';
    this.setLoading(false);
    this.setToLocalStorage('accessToken', this.token);
    this.setToLocalStorage('uid', this.userId);
  }

  setUserNotAuthenticated() {
    this.authenticated = false;
    this.token = '';
    this.error = 'Sign up failed';
    this.setLoading(false);
    this.clearFromLocalStorage();
  }
}
