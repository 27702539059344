import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, FreeMode, Pagination } from 'swiper';
import BaseDirectories from '../../base directories/BaseDirectories';
import Header from './sections/Header';
import {
  mdiAccessPointNetwork,
  mdiArrowRight,
  mdiPlayCircleOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import GetStarted from './sections/GetStarted';
import Blank from './sections/Blank';
import Newsletter from './sections/Newsletter';
import Footer from './sections/Footer';
import { Helmet } from 'react-helmet-async';

const MediaPage = () => {
  return (
    <div className="bg-white media-page">
      <Helmet>
        <title>Media Page | Trade Lenda Media Page!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <section className="media-hero">
        {/* <div className="container"> */}
        <div className="">
          <div className="lp-team">
            <h2>A sneak peek into Trade Lenda</h2>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={0}
              grabCursor={true}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination]}
              className="lp-career-container mySwiper"
            >
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic8.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic1.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic2.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic3.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic5.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic6.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic9.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic10.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lp-career-card">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/team-pic7.png`}
                    alt="Trade Lenda"
                    className="pe-0"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        {/* </div> */}
      </section>

      <section className="section2">
        <div className="container pb-4">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col">
              <div className="card pb-4 h-100">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/docu.png`}
                  className="card-img-top"
                  alt="working capital financing "
                />
                <div className="card-body p-3 pt-4 pb-3">
                  <h6>
                    <Icon path={mdiPlayCircleOutline} size={1} />
                    DOCUMENTARY
                  </h6>
                  <div className="d-flex align-items-start gap-3">
                    <h5 className="card-title">Lagos Market Documentary </h5>
                  </div>
                  <Link to="/loan_for_business">
                    Watch video <Icon path={mdiArrowRight} size={1} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card pb-4 h-100">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/biz.png`}
                  className="card-img-top"
                  alt="local  purchase order financing"
                />
                <div className="card-body p-3 pt-4 pb-3">
                  <h6>
                    <Icon path={mdiAccessPointNetwork} size={1} />
                    BLOG
                  </h6>
                  <div className="d-flex align-items-start gap-3">
                    <h5 className="card-title">MSMEs and loan policy</h5>
                  </div>
                  <Link to="/loan_for_business">
                    Read Article <Icon path={mdiArrowRight} size={1} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card pb-4 h-100">
                <img
                  src={`${BaseDirectories.IMAGES_DIR}/agro-allied.png`}
                  className="card-img-top"
                  alt="invoice discount finance"
                />
                <div className="card-body p-3 pt-4 pb-3">
                  <h6>
                    <Icon path={mdiAccessPointNetwork} size={1} />
                    NEWS
                  </h6>
                  <div className="d-flex align-items-start gap-3">
                    <h5 className="card-title">Agro-allied mass production</h5>
                  </div>
                  <Link to="/loan_for_business">
                    Read News <Icon path={mdiArrowRight} size={1} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GetStarted />
      <Blank height="60px" />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default MediaPage;
