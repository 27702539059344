/* eslint-disable @typescript-eslint/no-explicit-any */
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React, { FC } from 'react';
import Button from './Button';

type Props = {
  image: string;
  heading?: string;
  body: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  onClick?: () => void;
};

const Modal: FC<Props> = ({
  image,
  heading,
  body,
  close,
  button,
  btnText1,
  btnText2,
  onClick,
}) => {
  return (
    <div className="notification-modal">
      <div className="bg-white notification-box">
        <span className="close" onClick={close}>
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="Close"
          />
        </span>
        <img src={image} alt="" />
        {heading && <h5>{heading}</h5>}
        <p>{body}</p>
        {button && (
          <div className="group-btn">
            {btnText1 && (
              <Button
                classes="primary-btn btn-lg waves-effect"
                content={btnText1}
                onClick={onClick}
              />
            )}
            {btnText2 && (
              <Button
                classes="secondary-btn btn-lg waves-effect"
                content={btnText2}
                onClick={close}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
