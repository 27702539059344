/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import AccountTypeForm from '../../../../components/forms/AccountTypeForm';
import { motion } from 'framer-motion';

type Props = {
  next: () => any;
  x: number;
  setX: Function;
};

const AccountType: FC<Props> = ({ next, x, setX }) => {
  return (
    <motion.div
      initial={{ x: x }}
      transition={{ duration: 0.5 }}
      animate={{ x: 0 }}
      className=""
    >
      <div className="row justify-content-center text-center">
        <div className="col-lg-7 col-md-9 col-sm-10 bg-primary-blue mt-4 rounded">
          <h4>Account Type</h4>
          <p>
            Industry regulation requires us to collect this information to
            determine information needed.
          </p>
        </div>
      </div>
      <AccountTypeForm next={next} setX={setX} />
    </motion.div>
  );
};

export default AccountType;
