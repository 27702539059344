import { mdiCircleSmall, mdiPlayCircle } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import BaseDirectories from '../../base directories/BaseDirectories';
import Button from '../../components/ui/Button';
import BlueCard from './sections/BlueCard';
import Footer from './sections/Footer';
import Header from './sections/Header';
import HowItWorks from './sections/HowItWorks';
import Media from './sections/Media';
import StatsSection from './sections/StatsSection';
import { Helmet } from 'react-helmet-async';
import FinanceOption from './sections/FinanceOption';
import Newsletter from './sections/Newsletter';

const Business = () => {
  const [watchVideo, setWatchVideo] = useState(false);
  return (
    <div className="bg-white business">
      <Helmet>
        <title>Why Trade Lenda | Trade Lenda Business!</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      {watchVideo && <Media close={() => setWatchVideo(false)} />}
      <section className="landing-page-hero">
        <div className="container hero-section">
          <div className="hs-container2">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-6 hero-text">
                <h6>
                  <Icon path={mdiCircleSmall} size={2} />
                  <span>WHY TRADE LENDA?</span>
                </h6>
                <div className="hero-text">
                  <h1>Grow bigger with Trade Lenda</h1>
                  <p className="text-lg-start text-center ">
                    No matter what industry you're in, Trade Lenda has a
                    financing solution that can help you grow and succeed.
                  </p>
                  <div className="d-flex">
                    <Button
                      classes="yellow-btn btn-lg"
                      content="Get Started"
                      route="/sign_up"
                    />
                  </div>
                  {/* <a
                    href={undefined}
                    role="button"
                    onClick={() => setWatchVideo(true)}
                    className="docu-link"
                  >
                    <Icon path={mdiPlayCircle} size={1} />
                    Watch Our Documentary
                  </a> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero-img">
                  <img
                    src={`${BaseDirectories.IMAGES_DIR}/biz-hero-img.png`}
                    alt="Trade Lenda"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Qualities /> */}
      <FinanceOption />
      {/* <HowItWorks /> */}
      <BlueCard />
      <StatsSection />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Business;
