/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { FaTimes } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { toJS } from 'mobx';
import { motion } from 'framer-motion';

type Props = {
  route?: string;
  close?: () => void;
  button?: boolean;
  btnText1?: string;
  btnText2?: string;
  onClick?: () => void;
  dataObj?: any;
  investmentData?: any;
  setOpenPaymentModal: (state: any) => void;
};

const TopUpModal: FC<Props> = ({
  onClick = () => {
    setOpenPaymentModal(false);
  },
  investmentData,
  setOpenPaymentModal,
}) => {
  const [investAmount, setInvestAmount] = useState<any>('');
  const { investStore, walletStore } = useContext(StoreContext);
  const [fromWalletIdAccountNumber, setFromWalletIdAccountNumber] =
    useState('');
  const { message, submittingInvestment } = investStore;
  const { multipleWallet } = walletStore;
  const [errorMessage, setErrorMessage] = useState('');
  const [showBankSelection, setShowBankSelection] = useState(true);
  const navigate = useNavigate();
  const updateData = (e: any) => {
    const rawValue = e.target.value.replace(/,/g, '');
    if (/^\d*\.?\d*$/.test(rawValue)) {
      // Validate if input is a number
      const formattedValue = Number(rawValue).toLocaleString(); // Format with commas
      setErrorMessage('');
      setInvestAmount(formattedValue);
    } else {
      setErrorMessage('Enter a valid amount');
    }
  };

  const handleSubmit = () => {
    if (Number(investAmount) <= 0) {
      toast.error('Investment amount is below minimum investment');
    } else if (
      fromWalletIdAccountNumber == '' ||
      fromWalletIdAccountNumber == null ||
      fromWalletIdAccountNumber == undefined ||
      fromWalletIdAccountNumber.length < 10
    ) {
      toast.error('Wallet not selected!');
    } else {
      const removeCommaInAmount = investAmount.replace(/,/g, '');
      const investmentParams = {
        membershipId: investmentData.membershipId,
        productCode: investmentData.productCode,
        investmentAmount: Number(removeCommaInAmount),
        leadId: investmentData.leadId,
        potentialClientId: investmentData.potentialClientId,
        fromWalletIdAccountNumber: fromWalletIdAccountNumber,
      };

      investStore.topUpInvestment(investmentParams);
    }
  };

  useEffect(() => {
    if (
      message.type === 'success' &&
      message.msg === 'Investment Top-Up successful.'
    ) {
      setTimeout(() => {
        onClick();
        navigate('/dashboard/invest');
      }, 2000);
    }
  }, [submittingInvestment, message]);

  // const walletDetails: any = toJS(wallet);
  return (
    <div className="notification-modal">
      {/* {submitting && <Spinner />} */}
      <div className="bg-white notification-box">
        <span
          onClick={() => {
            setOpenPaymentModal(false);
          }}
          className="close"
          role="button"
        >
          <FaTimes />
        </span>

        <div className="row justify-content-center amount-box">
          {/* // BANK SELECTION */}
          {showBankSelection && (
            <motion.div
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -200, opacity: 0 }}
              className="sliding-input"
            >
              <div className="row justify-content-center invest-box">
                <h5
                  className="my-3"
                  style={{ fontSize: '20px', textTransform: 'uppercase' }}
                >
                  Select a wallet
                </h5>
                <div className="col-12">
                  {toJS(multipleWallet) && toJS(multipleWallet)?.length > 0
                    ? toJS(multipleWallet)?.map(
                        (walletData: any, index: number) => (
                          <div className="w-100 gap-2 mt-2" key={index}>
                            <h6
                              className="wallet-deets d-flex align-items-start my-3 color-heading"
                              style={{
                                fontSize: '14px',
                                textTransform: 'uppercase',
                              }}
                            >
                              Wallet {index + 1}
                            </h6>
                            <div
                              className="d-flex flex-wrap gap-20 align-items-center justify-content-between mb-3 wallet-deets invest-selection"
                              onClick={() => {
                                if (walletData?.availableBalance < 100) {
                                  toast.error(
                                    'Amount must be greater than ₦100',
                                  );
                                } else {
                                  setFromWalletIdAccountNumber(
                                    walletData?.walletIdAccountNumber,
                                  );
                                  setErrorMessage('');
                                  setInvestAmount('');
                                  setShowBankSelection(false);
                                }
                              }}
                            >
                              <h6>{walletData?.banker}</h6>
                              <p>
                                ₦{' '}
                                {walletData?.availableBalance != 'NaN' ||
                                walletData?.availableBalance != null ||
                                walletData?.availableBalance != undefined
                                  ? walletData?.availableBalance
                                    ? new Intl.NumberFormat('en-US', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(walletData?.availableBalance)
                                    : '0.00'
                                  : '0.00'}
                              </p>
                            </div>
                          </div>
                        ),
                      )
                    : null}
                </div>
              </div>
            </motion.div>
          )}
          {!showBankSelection && (
            <motion.div
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -200, opacity: 0 }}
              className="sliding-input"
            >
              <div className="row justify-content-center invest-box">
                <div className="col-12">
                  <h5
                    className="my-3"
                    style={{ fontSize: '20px', textTransform: 'uppercase' }}
                  >
                    Enter Top-up Amount
                  </h5>
                  <small
                    className=""
                    style={{
                      color: '#054b99',
                      alignSelf: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Wallet Balance: ₦{' '}
                    {toJS(multipleWallet) &&
                      toJS(multipleWallet).length > 0 &&
                      toJS(multipleWallet).map(
                        (walletData: any) =>
                          walletData.walletIdAccountNumber ===
                            fromWalletIdAccountNumber &&
                          new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(walletData?.availableBalance),
                      )}
                  </small>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-white"
                        style={{
                          borderRight: '0',
                          borderTopRightRadius: '0',
                          borderBottomRightRadius: '0',
                        }}
                      >
                        ₦
                      </span>
                    </div>
                    <Input
                      style={{ borderLeft: '0' }}
                      name="amount"
                      classes="form-control text-center"
                      type="text"
                      id="amount"
                      value={investAmount}
                      onChange={updateData}
                    />
                  </div>
                  {errorMessage !== '' ? (
                    <small className="text-danger">{errorMessage}</small>
                  ) : null}
                  <div className="group-btn justify-content-center">
                    <Button
                      classes="primary-btn w-auto btn-lg waves-effect"
                      content="Back"
                      onClick={() => setShowBankSelection(true)}
                      type="button"
                    />

                    <Button
                      classes="primary-btn w-auto btn-lg waves-effect"
                      content="Top-Up Investment"
                      type="submit"
                      disabled={
                        message.msg === 'Investment Top-Up successful.'
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </div>
        {/* <h5 className="my-3">Enter Amount</h5>
        <small
          className=""
          style={{
            color: '#054b99',
            alignSelf: 'center',
            fontWeight: 'bold',
          }}
        >
          Wallet Balance: ₦{' '}
          {walletDetails?.availableBalance
            ? new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(walletDetails?.availableBalance)
            : '0.00'}
        </small>
        <div className="input-group">
          <div className="input-group-prepend">
            <span
              className="input-group-text bg-white"
              style={{
                borderRight: '0',
                borderTopRightRadius: '0',
                borderBottomRightRadius: '0',
              }}
            >
              ₦
            </span>
          </div>
          <Input
            style={{ borderLeft: '0' }}
            name="amount"
            classes="form-control text-center"
            type="text"
            id="amount"
            value={investAmount}
            onChange={updateData}
          />
        </div>
        {errorMessage !== '' ? (
          <small className="text-danger">{errorMessage}</small>
        ) : null}
        <div className="group-btn">
          <Button
            classes="primary-btn w-auto btn-lg waves-effect"
            content="Top-Up Investment"
            type="submit"
            disabled={
              message.msg === 'Investment Top-Up successful.' ? true : false
            }
            onClick={() => {
              handleSubmit();
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default observer(TopUpModal);
